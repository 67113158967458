import React from 'react'
import AgenceForm from '../AgenceForm.partial';

import AgenceToolbar from '../AgenceToolbar/AgenceToolbar';
import { useNavigate } from 'react-router-dom';
import { useForm } from 'react-hook-form';
import { Swal, useUpload } from '@/components/UI';
import { usePut } from '@/components/Utils';

const AgenceUpdate = (props) => {
    const { agenceId, agenceDetail } = props;
    
    const navigate = useNavigate();
    const { register, handleSubmit, reset, getValues, setValue, watch, formState: { dirtyFields, isDirty, errors } } = useForm();

    React.useEffect(() => {
        if (agenceDetail) {
            reset(agenceDetail);
        }
        return () => {}
        // eslint-disable-next-line
    }, [agenceDetail])

    const [updateAgence, { fusion }] = usePut(`/agences/${agenceId}`);

    const onSubmit = ({ ...rest }) => {
        updateAgence({
            ...rest
        }).then(response => {
            if (response.status === 200) {
                Swal.fire({
                    title: 'Agence mise à jour avec succès',
                    icon: 'success',
                    showConfirmButton: false,
                    timer: 1500
                });
                navigate(`/agences/liste`);
            } 
        })
    }

    const { showUploader, uploading } = useUpload({
        folder: `/images/agences`,
        config: {
            width: 225,
            height: 225
        },
        useIcon: true,
        label: `Photo de l'agence`,
        labelAllowed: ``,
        defaultValue: agenceDetail?.photo ?? null,
        onUploaded: ({ filePath }) => setValue(`photo`, filePath, { shouldDirty: true })
    })

    const status = getValues('status');
    watch('status');
    

    const params = {
        register,
        errors,
        showUploader,
        setValue,
        status
    }

    const hasUpdates = Object.keys(dirtyFields).length === 0;

    return (
        <form onSubmit={handleSubmit(onSubmit)}>
            <AgenceToolbar            
                title={`Modification de l'agence`}
                actionDisabled={hasUpdates || uploading || !isDirty}
                subtitle={agenceDetail ? agenceDetail.nom + ` #${agenceDetail.id}` : ``}
                fusion={fusion}/>
            <AgenceForm {...params}/>
        </form>
    )
}

export default AgenceUpdate
