import PrivilegeForm from "./PrivilegeForm";
import { RiUserStarFill } from 'react-icons/ri'

const AgenceNegociateur = ({ agenceDetail }) => {
    return <PrivilegeForm
                label={`négociateur`}
                title={`Les négociateurs`}
                titleColor={`#218c74`}
                titleIcon={<RiUserStarFill />}
                roleId={4}
                agenceDetail={agenceDetail}/>
}

export default AgenceNegociateur