import React from 'react';
import { toAbsoluteUrl, publicAssets } from '@/_metronic/helpers/AssetHelpers';
import { useMutation } from '@/components/Utils';
import './UploadPhoto.styles.scss';

const UploadPhoto = ({
        defaultValue = null,
        folder = `/images`,
        label = `Avatar`,
        labelAllowed = ``,
        onUploaded,
        useIcon = false,
        config = {
            width: 125,
            height: 125
        }
    }) => {
    const [uploading, setUploading] = React.useState(false);
    const [value, setValue] = React.useState({});
    const inputRef = React.useRef(null);

    const [saveUpload, { fusion }] = useMutation(`/upload/save`, {
        'Content-Type': 'multipart/form-data'
    });

    const selectImage = (event) => {
        
        const file = event.target.files[0];
        setUploading(true);
        if (file) {
            const reader = new FileReader();
            reader.addEventListener("load", (e) => {
                setValue({
                    file,
                    url: e.target.result
                });

                const formData = new FormData();

                formData.append("file", file);
                formData.append("folder", folder);

                saveUpload(formData).then(response => {
                    if (response.status === 201 && typeof onUploaded === 'function') {
                        onUploaded(response.data);
                        setUploading(false);
                    }
                })
            });

            reader.readAsDataURL(file);

            
        } else {
            setUploading(false);
        }
    }

    const removeImage = () => {
        if (typeof onUploaded === 'function') onUploaded({});

        setValue({})
        if (inputRef.current) inputRef.current.value = "";
    }

    let showUrl = `url(${toAbsoluteUrl('/media/avatars/blank.png')})`;

    if (useIcon === true) {
        showUrl = `url(${toAbsoluteUrl('/media/upload-icon.jpg')})`;
    }

    if (value?.url) {
        showUrl = `url(${value.url})`;
    } else if (defaultValue) {
        showUrl = `url(${publicAssets(defaultValue)})`;
    }

    const { width, height } = config;

    const showUploader = () => {
        return  <div>
                    <div className="form-label">{ label }</div>
                    <div className="image-input image-input-outline" data-kt-image-input="true">
                        <div className={`image-input-wrapper w-${width}px h-${height}px`} style={{backgroundImage: showUrl}}></div>
                        <label className="btn btn-icon btn-circle btn-active-color-primary w-25px h-25px bg-body shadow" data-kt-image-input-action="change" data-bs-toggle="tooltip" title="" data-bs-original-title="Change avatar">
                            <i className="bi bi-pencil-fill fs-7"></i>
                            <input
                                ref={inputRef}
                                type="file"
                                onChange={selectImage}
                                name="avatar"
                                accept=".png, .jpg, .jpeg"/>
                            <input type="hidden" name="avatar_remove"/>
                        </label>

                        <span className="btn btn-icon btn-circle btn-active-color-primary w-25px h-25px bg-body shadow" data-kt-image-input-action="cancel" data-bs-toggle="tooltip" title="" data-bs-original-title="Cancel avatar">
                            <i className="bi bi-x fs-2"></i>
                        </span>

                        { value && value.url ? (
                            <span
                                className="btn btn-icon btn-circle btn-active-color-primary w-25px h-25px bg-body shadow"
                                data-kt-image-input-action="remove"
                                data-bs-toggle="tooltip"
                                title=""
                                onClick={removeImage}
                                data-bs-original-title="Remove avatar">
                                <i className="bi bi-x fs-2"></i>
                            </span>
                        ) : null }

                        
                    </div>
                    <div {...fusion()} className="uploader-loading"></div>
                    <div className="form-text">{ labelAllowed }</div>
                </div>
    }

    return { showUploader, uploading }
}

export default UploadPhoto
