import {
    useParams
} from 'react-router-dom';
import AgenceList from './partials/AgenceList/AgenceList.partial';
import AgenceDetail from './AgenceDetail.page';
import AgenceCreate from './AgenceCreate.page';

const AgencePage = () => {
    const params = useParams();

    const handleSubpage = () => {
        switch(params.action) {
            case 'liste':
                return <AgenceList />;
            
            case 'create':
                return <AgenceCreate {...params}/>

            case 'update':
                return <AgenceDetail {...params}/>

            default:
                return null;
        }
    }

    return (
        <>
            { handleSubpage() }
        </>
    )
}

export default AgencePage
