import './FraisDetail.styles.scss';
import { Currency } from '@/components/UI';
import { FaCircleNotch } from 'react-icons/fa';
import { Link } from 'react-router-dom';

const FraisDetail = ({ bienId, frais, totalFrais, loading }) => {

    const initMontantTtc = (montantHt) => {
        const ht = parseFloat(montantHt ?? 0);
        const tva = ht * (20/100);
        return ht + tva;
    }

    return (
    <div className="frais-detail">
        <div className="frais-title">
            <span>Liste des Frais</span> :
        </div>
        <div className="row">
            { !loading && frais.length <= 0 ? (
                <div className="frais-item">
                    Aucun frais n'a été ajouté pour le moment.&nbsp;<Link to={`/biens/update/${bienId}/frais`}>Cliquez ici pour en rajouter</Link>
                </div>
            ) : null }

            { loading === true ? (
                <div className="frais-loading">
                    Chargement en cours <FaCircleNotch className="spin-quickly ms-2"/>
                </div>
            ) : null }
            
            { frais && !loading && frais.map((fraisItem, key) => <div className="frais-item" key={key}>
                <div className="frais-name">{ fraisItem.nom }</div>
                <div className="frais-amount">
                    <Currency value={fraisItem.montantHt}/>
                </div>
            </div>) }

            <div className="frais-total">
                <div className="frais-name">Total des frais offerts en HT :</div>
                <div className="frais-amount">
                    <Currency value={totalFrais }/>
                </div>
            </div>
            <div className="frais-total">
                <div className="frais-name">Total des frais offerts en TTC :</div>
                <div className="frais-amount">
                    <Currency value={initMontantTtc(totalFrais) }/>
                </div>
            </div>
        </div>
    </div>
    )
}

export default FraisDetail
