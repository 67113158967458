/* eslint-disable react/jsx-no-target-blank */
// import React from 'react'
// import {useIntl} from 'react-intl'
// import {KTSVG} from '../../../helpers'
import {AsideMenuItemWithSub} from './AsideMenuItemWithSub'
import {AsideMenuItem} from './AsideMenuItem'
import { connect } from 'react-redux';
import { useGet, useHydra } from '../../../../components/Utils';
import { useEffect, useMemo } from 'react';
import BordereauReducer from '../../../../redux/Bordereau/bordereau.reducer';

function AsideMenuMain({user, roleSelected, privilegeSelected, bordereauValidate}:any) {

  const { count, refetch } = useGet(`/bien_immobiliers?requestValidation=true`);

  
  useEffect(() => {
    
    refetch();
    
  }, [roleSelected, privilegeSelected, bordereauValidate])

  return (
    <>
      <AsideMenuItem
        to='/'
        icon='/media/icons/duotune/art/art002.svg'
        title={`Tableau de bord`}
        fontIcon='bi-app-indicator'
      />
      {user?.roleSelected !== 4 &&
        <AsideMenuItem
          to='/performance'
          icon='/media/icons/duotune/graphs/gra001.svg'
          title={`Performance`}
          fontIcon='bi-app-indicator'
        />
      }
      <div className='menu-item'>
        <div className='menu-content pt-8 pb-2'>
          <span className='menu-section text-muted text-uppercase fs-8 ls-1'>Outils</span>
        </div>
      </div>
      
      {/* Comptes utilisateurs */}
      {user?.roleSelected !== 4 &&
        <>
          <AsideMenuItemWithSub
            to='/users'
            title='Comptes utilisateurs'
            icon='/media/icons/duotune/communication/com006.svg'
            fontIcon='bi-person'
          >
            <AsideMenuItem to='/users/liste' title='Liste des utilisateurs' hasBullet={true} />
            <AsideMenuItem to='/users/create' title='Nouvel utilisateur' hasBullet={true} />
          </AsideMenuItemWithSub>


          {(user?.roleSelected === 1 ) &&
            <>
              {/* Agences */}
              <AsideMenuItemWithSub
                to='/crafted/pages'
                title='Agences'
                fontIcon='bi-archive'
                icon='/media/icons/duotune/ecommerce/ecm008.svg'
              >
                <AsideMenuItem to='/agences/liste' title='Liste des agences' hasBullet={true} />
                <AsideMenuItem to='/agences/create' title='Nouvelle Agence' hasBullet={true} />
              </AsideMenuItemWithSub>

              {/* Societes */}

              <AsideMenuItemWithSub
                to='/crafted/widgets'
                title='Sociétés'
                icon='/media/icons/duotune/general/gen025.svg'
                fontIcon='bi-layers'
              >
                <AsideMenuItem to='/societes/liste' title='Liste des sociétés' hasBullet={true} />
                <AsideMenuItem to='/societes/create' title='Nouvelle société' hasBullet={true} />
              </AsideMenuItemWithSub>
            </>}
        </>}

      {/* Biens */}
      <AsideMenuItemWithSub
        to='/crafted/widgets'
        title='Biens immmobiliers'
        icon='/media/icons/duotune/ecommerce/ecm004.svg'
        fontIcon='bi-layers'
      >
        <AsideMenuItem to='/biens/liste' title='Liste des biens' hasBullet={true} />
        <AsideMenuItem to='/biens/create' title='Nouveau bien' hasBullet={true} />
      </AsideMenuItemWithSub>


      {user?.roleSelected !== 4 &&
        <>
          <AsideMenuItem
            to='/biens/liste?requestValidation=true'
            icon='/media/icons/duotune/files/fil025.svg'
            title={`Bordereau à valider`}
            fontIcon='bi-app-indicator'
            isPellet={count ? true : false}
          />
        </>}

      {/* Frais */}
      <AsideMenuItem
        to='/frais/liste'
        icon='/media/icons/duotune/finance/fin003.svg'
        title={`Frais`}
        fontIcon='bi-app-indicator'
      />

      {/* Commissions */}
      {(user?.roleSelected === 1 || user?.roleSelected === 2) &&
        <>
          <AsideMenuItem
            to='/commission/liste'
            icon='/media/icons/duotune/art/art002.svg'
            title={`Commissions validées`}
            fontIcon='bi-app-indicator'
          />
        </>}


      {user?.roleSelected === 1 &&
      <>
        <div className='menu-item'>
          <div className='menu-content pt-8 pb-2'>
            <span className='menu-section text-muted text-uppercase fs-8 ls-1'>Paramètres</span>
          </div>
        </div>

        {/* Provenances */}
 
        <AsideMenuItemWithSub
          to='/apps/chat'
          title='Provenance leads & taux'
          fontIcon='bi-chat-left'
          icon='/media/icons/duotune/communication/com009.svg'
        >
          <AsideMenuItem to='/provenances/liste' title='Liste des provenances' hasBullet={true} />
          <AsideMenuItem to='/provenances/create' title='Nouvelle provenance' hasBullet={true} />
        </AsideMenuItemWithSub>

        <AsideMenuItemWithSub
          to='/apps/chat'
          title='Type de frais'
          fontIcon='bi-chat-left'
          icon='/media/icons/duotune/general/gen005.svg'
        >
          <AsideMenuItem to='/frais_type/liste' title='Type de frais' hasBullet={true} />
          <AsideMenuItem to='/frais_type/create' title='Créer type de frais' hasBullet={true} />
        </AsideMenuItemWithSub>
      </>}

      <div className='menu-item'>
        <div className='menu-content'>
          <div className='separator mx-1 my-4'></div>
        </div>
      </div>
      {/* <div className='menu-item'>
        <a
          target='_blank'
          className='menu-link'
          href={process.env.REACT_APP_PREVIEW_DOCS_URL + '/docs/changelog'}
        >
          <span className='menu-icon'>
            <KTSVG path='/media/icons/duotune/general/gen005.svg' className='svg-icon-2' />
          </span>
          <span className='menu-title'>Changelog {process.env.REACT_APP_VERSION}</span>
        </a>
      </div> */}
    </>
  )
}

const mapStateToProps = (state: any) => {
  const { user } = state.AuthReducer;
  if (user) {
    const { roleSelected, privilegeSelected } = user;
    const { bordereauValidate } = state.BordereauReducer;
    return {
        user,
        roleSelected,
        privilegeSelected,
        bordereauValidate,
    }
  }

  return {
    user
  }

}

export default connect(mapStateToProps)(AsideMenuMain)