import React from 'react';
import { information } from './FraisTypeField';
import { MenuToolbar } from '@/routes';
import { Link, useNavigate } from 'react-router-dom';
import { Card, Swal, useUpload } from '@/components/UI';
import { checkError } from '@/components/UI/FormHandler/functions';
import { useForm, useFieldArray } from 'react-hook-form';

import './FraisTypeForm.styles.scss';

// Icon imports
import { AiFillPlusSquare, AiOutlineUnorderedList } from 'react-icons/ai';
import { FaPlus, FaSpinner, FaTimes } from 'react-icons/fa';
import { RiListSettingsLine } from 'react-icons/ri';
import { FiAlertTriangle } from 'react-icons/fi';

import { usePatch, useMutation } from '@/components/Utils';

const FraisTypeForm = ({ data, loading, fraisId = null, action }) => {
    const [temporary, setTemporary] = React.useState(``)
    const navigate = useNavigate();
    const isUpdate = () => action === "update" && data;

    const { getValues, setValue, register, handleSubmit, watch, reset, control, formState: { errors } } = useForm();

    const [updateFrais, { fusion: updateFusion }] = usePatch(`/frais_types/${fraisId}`);
    const [saveFrais, { fusion: saveFusion }] = useMutation(`/frais_types`);

    const fusion = action === "update" ? updateFusion : saveFusion;

    const onSubmit = ({ ...rest }) => {
        const fraisType = {
            ...rest
    }

        if (action === 'update')
            return updateFrais(fraisType).then(() => navigate(`/frais_type/liste`));

        return saveFrais(fraisType).then(() => navigate(`/frais_type/liste`))
    }

    React.useEffect(() => {
        if (data) reset(data);
        return () => {}
    }, [data, reset])

    const { showUploader, uploading } = useUpload({
        folder: `/images/frais_types`,
        config: {
            width: 85,
            height: 85
        },
        useIcon: true,
        label: 'Icône du frais',
        labelAllowed: ``,
        defaultValue: data?.icone ?? null,
        onUploaded: ({ filePath }) => setValue(`icone`, filePath)
    })
    

    const { fields, append } = useFieldArray({
        control,
        name: "prestataires",
    });

    

    watch('forcePrestataire');
    watch('prestataires');
    const forcePrestataire = getValues('forcePrestataire');
    const prestataires = getValues('prestataires');

    const removeElement = (index) => {
        Swal.fire({
            title: `<span style="color: red">Supprimer le prestataire?</span>`,
            icon: 'warning',
            html: `Etes vous sur de vouloir supprimer ce prestataire ?`,
            showCloseButton: false,
            showCancelButton: true,
            focusConfirm: false,
            confirmButtonText: 'Oui',
            confirmButtonColor: 'red',
            cancelButtonText: 'Annuler'
        }).then(result => {
            if (result.isConfirmed) {
                setValue('prestataires', [
                    ...prestataires.slice(0, index),
                    ...prestataires.slice(index + 1)
                ])
            }
        })
    }

    const addElement = (value = null) => {
        value = value ?? temporary;
        if (String(value).length > 0) {
            const exist = fields.find(field => field.nom === value);

            if (!exist) {
                append({
                    nom: value
                })
    
                setTemporary(``);
            }
        }
    }

    return (
        <form className="frais-typeform" onSubmit={handleSubmit(onSubmit)}>
            <MenuToolbar
                title={isUpdate() ? `Edition d'un frais` : `Création d'un type de frais`}
                subtitle={ data ? data.nom : ``}
                action={() => 
                    <button
                        {...fusion({ disabled: uploading})}
                        type="submit" 
                        className="btn btn-success btn-sm font-weight-bold mr-2">
                        <AiFillPlusSquare /> Sauvegarder
                    </button>}>
                <ul className="breadcrumb breadcrumb-separatorless fw-bold fs-7 my-1">
                    <li className="breadcrumb-item text-muted">
                        <Link to="/frais_type/liste" className="text-muted text-hover-primary">Liste des types de frais</Link>
                    </li>
                    <li className="breadcrumb-item">
                        <span className="bullet bg-gray-200 w-5px h-2px"></span>
                    </li>
                </ul>
            </MenuToolbar>
            
            { !loading ? (
                <div className="row">
                    
                    <div className="col-xl-4 col-sm-12 right-group">
                        <Card
                            title={`Création d'un type de frais`}
                            titleColor={`#0652DD`}
                            titleIcon={<RiListSettingsLine />}
                            className="mb-4">
                            <div className="flex flex-column justify-center items-center">
                                <div>
                                    { showUploader() }
                                    <input type="hidden" {...register('icone', { required: true})}/>
                                </div>

                                { checkError('icone', errors, `L'icône est obligatoire`) }
                            </div>

                            { information && information.map((field, index) => {
                                return (
                                <div className="mb-8" key={index}>
                                    <div className="col-12 align-items-center input-group has-validation">
                                        <label className="form-label col-12 text-start pe-8">{ field.label }</label>
                                        <div className="col-12 form-inline">
                                            <input
                                                placeholder={ field.placeholder ?? field.label }
                                                className="form-control form-control-white"
                                                type={field.type ?? 'text'}
                                                {...register(field.name, field.params)}/>
                                        </div>
                                        <div className="col-12"></div>
                                        <div className="col-12">
                                            { checkError(field, errors) }
                                        </div>
                                    </div>
                                </div>)
                            }) }

                            <label className='fs-6 text-dark d-flex'>
                                
                                
                                <div className='form-check form-check-sm form-check-custom me-2'>
                                    <input
                                        className='form-check-input'
                                        type='checkbox'
                                        defaultChecked={forcePrestataire === 1}
                                        onChange={(event) => {
                                            const checked = event.target.checked;
                                            setValue('forcePrestataire', checked ? 1 : -1, { shouldDirty: true })
                                        }}/>
                                </div>
                                <span style={{cursor: 'pointer'}} className="text-hover-primary">Forcer une liste de prestataires pour ce type de frais</span>
                            </label>

                            <div className="relative">
                                <div className="py-8">
                                    <div className={`disable-prestataire ${forcePrestataire === 1 ? 'hide' : 'show'}`}>
                                        <div className="disable-message">
                                            Veuillez cocher 
                                            <span className="text-primary"> "Forcer une liste de prestataires pour ce type de frais" </span> 
                                            si vous souhaitez associer des prestataires à ce type de frais
                                        </div>
                                    </div>
                                    { forcePrestataire === 1 ? (<input type="hidden" {...register('prestataires', { required: true })}/>) : null }
                                    <div className="fs-4 fw-bold" style={{color: '#0652DD'}}>Les prestataires possibles :</div>

                                    <table className="table table-row-dashed table-row-gray-300 align-middle gs-0 gy-4">
                                        <thead>
                                            <tr className="border-0">
                                                <th className="p-0 min-w-150px fw-bold"></th>
                                                <th className="p-0 min-w-50px fw-bold"></th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            { loading === true ? (
                                                <tr>
                                                    <td align="center">
                                                        <div className="fs-4 text-left text-primary" colSpan="2">Chargement en cours <FaSpinner  className="ms-2 spin-animation"/> </div>
                                                    </td>
                                                </tr>
                                            ) : null }
                                                { !loading && prestataires && prestataires.length === 0 ? (
                                                    <tr>
                                                        <td align="left">
                                                            <div className="fs-5 text-left text-muted" colSpan="2"><AiOutlineUnorderedList /> Aucun prestataire n'a été ajouté pour le moment.</div>
                                                        </td>
                                                    </tr>) : 
                                                null }  

                                                { fields && fields.map((prestataire, index) => {
                                                    return (
                                                        <tr key={index}>
                                                            <td>
                                                                <div className="text-dark fw-bolder text-hover-primary fs-5">{ prestataire.nom }</div>
                                                                {/* <div className="text-muted fw-bold text-muted d-block fs-6"><FaPhone /> { field.telephone } / <AiOutlineMail /> { field.email ?? `N/A` }</div> */}
                                                            </td>
                                                            <td className="text-end">
                                                                <span className="btn btn-sm btn-icon btn-bg-light btn-danger" onClick={() => removeElement(index)}>
                                                                    <FaTimes />
                                                                </span>
                                                            </td>
                                                        </tr>
                                                    )
                                                })}      
                                        </tbody>
                                    </table>
                                    
                                    { forcePrestataire === 1 ? checkError('prestataires', errors, <div className="alert alert-warning"><FiAlertTriangle /> Vous devez ajouter au moins un prestataire</div>) : null }
                                    
                                    <div className="prestataire-add">
                                        <div className="fs-4 fw-bold mb-2" style={{color: 'grey'}}><FaPlus/> Ajouter un prestataire :</div>

                                        <div className="col-12 align-items-center input-group has-validation">
                                            <div className="text-muted mb-2">Veuillez saisir le nom du nouveau prestataire</div>
                                            <div className="col-12 flex">
                                                <div className="flex-auto">
                                                    <input
                                                        placeholder={`Nom du prestataire`}
                                                        className="form-control form-control-white"
                                                        type={'text'}
                                                        value={temporary}
                                                        onChange={(event) => setTemporary(event.target.value)}
                                                        onKeyDown={(event) => {
                                                            if ((event.key === 'Enter')) {
                                                                event.preventDefault();
                                                                addElement(event.target.value)
                                                            }

                                                            return true;
                                                        }}/>
                                                </div>
                                                <div>
                                                    <button
                                                        disabled={temporary === null}
                                                        className="ms-4 btn btn-success"
                                                        type="button"
                                                        onClick={() => addElement()}>
                                                        <FaPlus />
                                                    </button>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </Card>
                    </div>
                    <div className="col-6 left-group">
                        
                    </div>
                </div>
            ) : null}
        </form>
    )
}

export default FraisTypeForm
