const handleDiff = (dirtyFields, data) => {
    let updates = {};
    Object.keys(dirtyFields).map(field => {

        if ((data[field] && dirtyFields[field] === true) || (data[field] && dirtyFields[field]))
            updates = {
                ...updates,
                [field]: data[field]
            }
        return updates;
    });

    return updates;
}

export {
    handleDiff
}