export const filters = [
    {
        name: "reglerVendeur",
        data: [
            {
                label: `Tous les frais`,
                value: ``
            }, {
                label: `Que les frais à régler par les propriétaires`,
                value: 1
            }, {
                label: `Que les frais non facturés aux propriétaires`,
                value: -1
            }
        ]
    }
];

export const reformuleData = (data) =>{
    const listeFrais = [];
    data && data.map((item)=>{
        const frais = {
            ...item.bien,
            agenceNom: item.agenceNom,
            societeNom: item.societeNom
        }
        return listeFrais.push(frais);
    })

    return listeFrais
}

export const getFilterSociete = (data) => {
    let list = [
        {
            label: `Tous les sociétés`,
            value: ``
        }
    ];

    data && data.map((item)=>{
        return list.push({
            label: item.nom,
            value: item.id
        })
    })

    return list
}

export const getFilterAgence = (data) => {
    let list = [
        {
            label: `Toutes les agences`,
            value: ``
        }
    ];

    data && data.map((item)=>{
        return list.push({
            label: item.nom,
            value: item.id
        })
    })

    return list
}

export const getFilterTypeFrais = (data) => {
    let list = [
        {
            label: `Tous types de frais`,
            value: ``
        }
    ];

    data && data.map((item)=>{
        return list.push({
            label: item.nom,
            value: item.id
        })
    })

    return list
}