import UserForm from './partials/UserForm/UserForm.partial';
import { useQuery } from '@/components/Utils';
import { LoadingAnimation } from '@/components/UI';

const UserUpdate = ({ userId, action }) => {
    
    const { data, loading } = useQuery(`/user_detail`, {
        variables: {
            userId
        }
    });

    if (loading) return <LoadingAnimation />

    const params = {
        userDetail: data ?? {},
        action
    }

    return <UserForm loading={loading} {...params}/>
};

export default UserUpdate
