import { useForm } from 'react-hook-form';
import { useMutation } from '@/components/Utils';
import { FaExclamationTriangle } from 'react-icons/fa';
import { AiOutlineInfoCircle } from 'react-icons/ai';
import { Swal } from '../../../../components/UI';

const UserPassword = ({ id }) => {
    const { handleSubmit, reset, register, watch, formState: { isDirty } } = useForm({
        defaultValues: {
            password: ''
        }
    });

    const password = watch('password');
    const confirmPassword = watch('confirmPassword');

    const [savePassword, { fusion }] = useMutation('/user/update/password');

    const onSubmit = (data) => {
        savePassword({
            id,
            ...data
        }).then(response => {
            if (response.status === 201) {
                reset();
                Swal.fire({
                    title: 'Mot de passe mis à jour avec succès',
                    icon: 'success',
                    showConfirmButton: false,
                    timer: 1500
                });
            }
        })
    }

    const hasMinLength = () => {
        return password.length >= 8
    }

    const isEmpty = () => {
        return password.length <= 0 || confirmPassword.length <= 0;
    }

    const isSame = () => {
        if (isEmpty()) return true;

        return password === confirmPassword
    };

    return (
        <form onSubmit={handleSubmit(onSubmit)} autoComplete={`off`}>
            <div className="mb-4" style={{color: 'grey', fontSize: '18px'}}>
                Veuillez saisir le nouveau mot de passe
            </div>
            <div>
                <div className="col-sm-12 col-md-4 mb-4 form-inline">
                    <div className="form-label">{ `Nouveau mot de passe *` }</div>
                    <input
                        placeholder={'Nouveau mot de passe'}
                        className="form-control form-control-white"
                        {...register('password', { required: true })}
                        type={'password'}/>
                </div>
                { hasMinLength() === false && isDirty ?
                    <div className="flex mb-4 items-center" style={{color: 'blue', fontSize: '14px'}}>
                        <AiOutlineInfoCircle className="me-2"/>
                        Le mot de passe doit contenir au moins 8 caractères
                    </div> : 
                null }
                <div className="col-sm-12 col-md-4 mb-4 form-inline">
                    <div className="form-label">{ `Confirmer le mot de passe *` }</div>
                    <input
                        placeholder={'Confirmer le mot de passe'}
                        className="form-control form-control-white"
                        {...register('confirmPassword', { required: true })}
                        type={'password'}/>
                </div>
                <div className="flex mb-4">
                    { !isSame() ?
                        <div className="flex items-center" style={{color: 'red', fontSize: '14px'}}>
                            <FaExclamationTriangle className="me-2"/>
                            Les mot de passes ne correspondent pas
                        </div> : 
                    null }
                </div>

                <div className="col-sm-12 col-md-4 mb-4 form-inline">
                    <button
                        {...fusion({ disabled: (!isSame() || isEmpty()) })}
                        className="btn btn-primary">
                        Enregistrer le mot de passe
                    </button>
                </div>
            </div>
            <div>
                
            </div>
        </form>
    )
}

export default UserPassword