const information = [{
    name: "nom",
    label: "Provenance *",
    params: { required: true } 
},
// {
//     name: "tauxCommission",
//     label: "Taux *",
//     params: { required: true },
//     appendText : "%"
// },
{
    name: "number",
    label: "Numero *",
    params: { required: true },
    type: "number"
}]

export {
    information
};