const clean = (object) => {
    Object
        .entries(object)
        .forEach(([k, v]) => {
            if (v && typeof v === 'object') {
                clean(v);
            }
            if ((v && typeof v === 'object' && !Object.keys(v).length) || v === null || v === undefined || v === false) {
                if (Array.isArray(object)) {
                    object.splice(k, 1);
                } else {
                    delete object[k];
                }
            }
        });
    return object;
}

const compact = (object) => {
    Object
        .entries(object)
        .forEach(([key, value]) => {
            // console.log(value);
            if (value && typeof value === 'object') {
                compact(value);
            }

            if ((value && typeof value === 'object' && !Object.keys(value).length) || value === null || value === undefined || value === false) {
                delete object[key];
            }
        });

    return object;
}

export {
    clean,
    compact
}