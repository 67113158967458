import {
    useParams
} from 'react-router-dom';
import ProvenanceListe from './partials/CommissionListe/CommissionListe.partial';

const Commission = () => {
    const params = useParams();

    const handleSubpage = () => {
        switch(params.action) {
            default:
                return <ProvenanceListe />;
        }
    }

    return (
        <>
            { handleSubpage() }
        </>
    )
}

export default Commission