import React from 'react';
import './Pagination.styles.scss';
import ReactPaginate from 'react-paginate';

const Pagination = React.memo(({ count, params, defaultValue }) => {
    // const [current, setCurrent] = React.useState(defaultValue ?? 0);

    const handlePageClick = ({ selected }) => {
        params.set("page", selected + 1);

        // setCurrent((current) => selected);
    }
    
    return (
        <div className="d-flex pagination justify-content-between align-items-center flex-wrap">
            <ReactPaginate
                className={`pagination-list`}
                activeClassName={`active-page`}
                breakLabel="..."
                nextLabel={(<i className="las la-angle-right"></i>)}
                onPageChange={handlePageClick}
                pageRangeDisplayed={10}
                pageCount={count}
                previousLabel={(<i className="las la-angle-left"></i>)}
                // forcePage={current}
                renderOnZeroPageCount={null}
            />
        </div>
        )
});

const useHydra = (data, params) => {
    const limit = 10;
    const rows = (data && data["hydra:member"]) ?? [];
    
    const total = (data && data["hydra:totalItems"]) ?? 0;

    // Pagination
    let maxPage = 0;
    if (total && limit) {
        maxPage = Math.ceil(total / limit);
    }

    const getPagination = () => {
        return <Pagination count={maxPage} params={params}/>
    }

    return { rows, total, limit, getPagination};
}

export default useHydra;