import React from 'react';
import './SalesAgence.style.scss';
import ReactApexChart from 'react-apexcharts';
import { Card } from '@/components/UI';
import { useGet } from '@/components/Utils';
import { currencyFormat } from '@/components/UI';
import { connect } from 'react-redux';
import { AiOutlineCloseCircle, AiOutlineInfoCircle } from 'react-icons/ai';

const SalesAgence = ({ roleSelected, privilegeSelected }) => {
  const [year, setYear] = React.useState(new Date().getFullYear());
  const [alert, setAlert] = React.useState(null);
  const { data, loading, refetch } = useGet('/dashboard/agences?year='+year);

  const { data: yearData } = useGet('/dashboard/years');
  const years = yearData?.years;

  React.useEffect(() => {
    refetch();

    return () => {}
  }, [roleSelected, privilegeSelected, year])

  if (loading) return null;

  const agences = data?.agences ?? [];
  const categories = data?.categories ?? [];;
  const series = data?.series ?? [];

  const datas = {
    series: [{
      name: 'Chiffre d\'affaire',
      data: series
    }],
    options: {
      chart: {
        type: 'bar',
        height: 350
      },
      plotOptions: {
        bar: {
          horizontal: false,
          columnWidth: '55%',
          endingShape: 'rounded'
        },
      },
      dataLabels: {
        enabled: false
      },
      stroke: {
        show: true,
        width: 2,
        colors: ['transparent']
      },
      xaxis: {
        title: {
          text: 'Agences'
        },
        categories: categories,
      },
      yaxis: {
        title: {
          text: '€'
        }
      },
      fill: {
        opacity: 1
      },
      tooltip: {
        y: {
          formatter: function (val) {
            return val + " €" 
          }
        }
      }
    }
  }

  return (
      <Card
        title={`Année ${year} > Chiffre d'affaire par agences`}
        className="mb-4">
        {/* Filtre de la table */}
        
        {
            alert ? 
                <div className="alert alert-success" role="alert">
                    <AiOutlineCloseCircle 
                        onClick={() => setAlert(null)}
                        role="button" style={{fontSize: '30px', marginRight: '10px'}} 
                        className="alert-heading text-danger mb-1"
                        data-toggle="tooltip"
                        title="Fermer le texte d'aide"
                        />
                    Présente la somme des commissions par agences pour les biens immobiliers dont l’état = « Acte définitive signé » et pour une année donnée. Pour prendre en compte la commission d’un bien immobilier donné, il faut que la  « date de signature définitive provisionnelle » ou la « date de signature réelle » de ce bien se situe sur l’année choisie.
                </div> : <AiOutlineInfoCircle
                            onClick={() => setAlert(1)}
                            role="button" 
                            style={{fontSize: '30px'}} 
                            className="text-primary mb-5"
                            data-toggle="tooltip"
                            title="Afficher le texte d'aide"
                            />

        }

        <div className="agences-sales">
            <div className="agences-block">
              <div className="sales-subtitle">
                Chiffre d'affaire par agences
              </div>
              <div className="select-block">

              {
                years && 
                  <select
                      defaultValue={year}
                      className="form-select"
                      aria-label="Default select example"
                      onChange={(event) => setYear(event.target.value)}>
                      {years.map((y, index) =>
                          (<option key={index} value={y.year}>{y.year}</option>))}
                  </select>
              }
                </div>
            </div>
             
                {/* <div className="sales-button">
                    <button className="btn btn-success btn-sm font-weight-bold mr-2">Valider</button>
                </div> */}
        </div>

        {/* Chart et summary */}
        
        <div className="agences-wrapper">
          <div className="agences-chart">
            <div>
              <ReactApexChart options={datas.options} series={datas.series} type="bar" height={350} />
            </div>
          </div>

          <div className="agences-table">
            <table>
              <thead>
                <tr className="first-column">
                  <th>Nom de l'agence</th>
                  <th className="currency">€ HT</th>
                </tr>
              </thead>
              <tbody>
                {!agences || agences.length <= 0 ? (<tr><td colSpan={2}>Aucun résultat n'est disponible</td></tr>) : null}
                {agences && agences.map((agence, index)=>
                  <tr key={index}>
                    <td>{agence.nom}</td>
                    <td className="currency">{currencyFormat(agence.chiffreAffaire)}</td>
                  </tr>
                )}
              </tbody>
            </table>
          </div>
        </div>
      </Card>
  )
}

const mapStateToProps = (state) => {
  const { user } = state.AuthReducer;
  const { roleSelected, privilegeSelected } = user;
  return {
      roleSelected,
      privilegeSelected
  }
}

export default connect(mapStateToProps)(SalesAgence);
