import React from 'react';
import { MenuToolbar } from '@/routes';
import { FaMinus } from 'react-icons/fa';
import { GiReceiveMoney } from 'react-icons/gi';
import { checkError } from '@/components/UI/FormHandler/functions';
import { FaPlus, FaPercentage } from 'react-icons/fa';
import { Link } from 'react-router-dom';

// import { toAbsoluteUrl } from '@/_metronic/helpers/AssetHelpers';
import { useForm, Controller } from 'react-hook-form';
import { Card, CurrencyInput, Swal } from '@/components/UI';
import { useMutation, useGet } from '@/components/Utils';
import FraisDetail from '../../FraisDetail/FraisDetail.partial';
import RepartitionCommission from './partials/RepartitionCommission';
import { useEffect } from 'react';
import { connect } from 'react-redux';
import { Currency } from '../../../../../components/UI';
import { bordereauUpdateAttempt } from '../../../../../redux/Bordereau/bordereau.action';
import BordereauReducer from '../../../../../redux/Bordereau/bordereau.reducer';

const BordereauCommission = ({ bienDetail, refetchBienImmobilier, roleSelected, bordereauDispatch }) => {
    // Récupération des détails des frais
    const fraisDetail = useGet(`/frais/detail/${bienDetail.id}`);
    const frais = fraisDetail?.data ?? [];
    // Récupération des négociateurs associés au bien immobilier
    const commissionQuery = useGet(`/commission/detail/${bienDetail.id}`);
    const commissions = React.useMemo(() => commissionQuery?.data?.commissions ?? [], [commissionQuery?.data?.commissions]);

    const commercial = bienDetail?.commercial;
    const bordereau = bienDetail?.bordereau;

    const convertToMontantTtc = (montantHt) => {
        const ht = parseFloat(montantHt ?? 0);
        return (Math.round((ht * 1.2) * 100) / 100).toFixed(2);
    }
    
    const convertToMontantHt = (montanttc) => {
        const ttc = parseFloat(montanttc ?? 0);
        return (Math.round((ttc / 1.2) * 100) / 100).toFixed(2);
    }

    const fraisType2 = frais.filter((value) => value.categorie === 2);
    const fraisType3 = frais.filter((value) => value.categorie === 3);
    const fraisType4 = frais.filter((value) => value.categorie === 4);

    const fraisNormalize = frais.filter((value) => value.categorie !== 1);
    const totalFrais = fraisNormalize.reduce((acc, value) => acc += parseFloat(value.montantHt), 0);

    const dataFraisType2Ht = frais.filter((value) => value.categorie === 2).map((value) => "("+value.montantHt+" * "+commercial?.percentageCommission+"%)").join(" - ");
    const dataFraisType2Ttc = frais.filter((value) => value.categorie === 2).map((value) => "("+convertToMontantTtc(value.montantHt)+" * "+commercial?.percentageCommission+"%)").join(" - ");

    const dataFraisType3Ht = frais.filter((value) => value.categorie === 3).map((value) => value.montantHt+' €').join(" - ");
    const dataFraisType3Ttc = frais.filter((value) => value.categorie === 3).map((value) => convertToMontantTtc(value.montantHt)+' €').join(" - ");

    const dataFraisType4Ht = frais.filter((value) => value.categorie === 4).map((value) => value.montantHt+" €").join(" - ");
    const dataFraisType4Ttc = frais.filter((value) => value.categorie === 4).map((value) => convertToMontantTtc(value.montantHt)+" €").join(" - ");
    const dataFraisType4Total = frais.filter((value) => value.categorie === 4).reduce((acc, value) => acc += parseFloat(value.montantHt), 0);

    const dataFraisType2PourcentHt = frais.filter((value) => value.categorie === 2).map((value) => `(${value.montantHt} * (100 - ${commercial?.percentageCommission})%)`).join(" - ");
    const dataFraisType2PourcentTtc = frais.filter((value) => value.categorie === 2).map((value) => `(${convertToMontantTtc(value.montantHt)} * (100 - ${commercial?.percentageCommission})%)`).join(" - ");

    const dataFrais = {
        'dataFraisType2' : {dataFraisType2Ht, dataFraisType2Ttc, dataFraisType2PourcentHt, dataFraisType2PourcentTtc},
        'dataFraisType3' : {dataFraisType3Ht, dataFraisType3Ttc},
        'dataFraisType4' : {dataFraisType4Ht, dataFraisType4Ttc, dataFraisType4Total},
    }

    const totalFraisReduceMarge = fraisType3.reduce((acc, value) => acc += parseFloat(value.montantHt), 0);
    const totalFraisDeduce = fraisType4.reduce((acc, value) => acc += parseFloat(value.montantHt), 0);
    const totalFraisDeducePourcent = fraisType2.reduce((acc, value) => acc += parseFloat(value.montantHt) * (commercial?.percentageCommission / 100), 0);
    
    const totalFraisReduceMargePourcent = fraisType2.reduce((acc, value) => acc += ((parseFloat(value.montantHt) * (100 - commercial?.percentageCommission)) / 100), 0);

    const montantCommissionFixe = convertToMontantHt(commercial?.montantCommission) - totalFraisDeduce;

    const montantCommission = convertToMontantHt(commercial?.montantCommission);

    const totalFrasFixed = totalFraisReduceMarge + totalFraisReduceMargePourcent;
    const totalFrasDeduit = dataFraisType4Total;

    const { data: societe } = useGet(`/societes/agence/${bienDetail?.agenceId}`);

    // React hook form pour la validation du bien immobilier
    const { register, reset, watch, getValues, setValue, handleSubmit, control, formState: { errors, dirtyFields } } = useForm({
        defaultValues: {
            validate: bienDetail?.status ?? false,
            totalFrasFixed,
            totalFrasDeduit,
            rajoutCommission: bordereau?.rajoutCommission ?? 0,
            rajoutCommentaire: bordereau?.rajoutCommentaire ?? ``,
            soustraireCommission: bordereau?.soustraireCommission ?? 0,
            soustraireCommentaire: bordereau?.soustraireCommentaire ?? ``,
            montantCommissionFixe,
            montantCommission
        },
        mode: "onSubmit",
        reValidateMode: "onChange"
    });

    useEffect(() => {
      if (commissionQuery.loading === false && commissions) {
        const allValues = getValues();
        reset({
            ...allValues,
            commissions
        })
      }
      return () => {}
    }, [commissionQuery.loading, commissions, getValues, reset])
    
    // Méthode pour valider le formulaire react hook form
    const [saveBien, { fusion: saveFusion }] = useMutation('/bordereau/save/');

    const fusion = (props) => saveFusion(props);

    watch('rajoutCommission');
    watch('soustraireCommission');
    watch('totalFrais');
    watch('validate');

    const rajoutCommission = parseFloat(getValues("rajoutCommission"));
    const soustraireCommission = parseFloat(getValues("soustraireCommission"));
    const validate = getValues("validate");
    const cancelValidation = getValues("cancelValidation");

    const getCommissionProvenanceTtc = () => {
        let montantCommission = parseFloat(convertToMontantTtc(montantCommissionFixe));
        montantCommission = Number.isNaN(montantCommission) ? 0.0 : montantCommission;
        const tauxCommission = parseFloat(commercial?.percentageCommission) / 100.0
        return (montantCommission * tauxCommission);
    }

    const getCommissionProvenanceHt = () => {
        let montantCommission = parseFloat(montantCommissionFixe);
        montantCommission = Number.isNaN(montantCommission) ? 0.0 : montantCommission;
        const tauxCommission = parseFloat(commercial?.percentageCommission) / 100.0
        return (montantCommission * tauxCommission);
    }

    const getCommissionTotal = () => {
        const montantRajoutCommission = Number.isNaN(rajoutCommission) ? 0.0 : parseFloat(rajoutCommission);
        const montantSoustraireCommission = Number.isNaN(soustraireCommission) ? 0.0 : parseFloat(soustraireCommission);
        return getCommissionProvenanceHt() + (montantRajoutCommission - montantSoustraireCommission) - totalFraisDeducePourcent;
    }
    
    const getCommissionTotalDueTtc = () => {
        const montantRajoutCommission = Number.isNaN(rajoutCommission) ? 0.0 : parseFloat(rajoutCommission);
        const montantSoustraireCommission = Number.isNaN(soustraireCommission) ? 0.0 : parseFloat(soustraireCommission);
        return getCommissionProvenanceTtc() + (convertToMontantTtc(montantRajoutCommission) - convertToMontantTtc(montantSoustraireCommission)) - convertToMontantTtc(totalFraisDeducePourcent);
    }

    const getCommissionTotalDueHt = () => {
        // const tauxCommission = parseFloat(commercial?.provenance?.tauxCommission) / 100.0;
        return getCommissionTotal();
    }

    const montantCommissionDue = getCommissionTotalDueHt();

    const launchSave = async (rest) => {
        const updatedFields = {
            ...rest,
            bienId: bienDetail.id,
            totalFrasFixed,
            totalFrasDeduit,
            validate,
            montantCommissionFixe,
            montantCommissionDue,
            montantCommission
        };

        saveBien(updatedFields).then((response) => {
            if (response?.status === 201) {
                refetchBienImmobilier();
                bordereauDispatch();
            };
        })
    }

    const onSubmit = ({ ...rest }) => {
        if (parseInt(validate) === 1) {
            return Swal.fire({
                title: `<span style="color: blue">Clôturer le bordereau de commission?</span>`,
                icon: 'question',
                html: `Voulez-vous réellement clôturer le bordereau de commission?`,
                showCloseButton: false,
                showCancelButton: true,
                focusConfirm: false,
                confirmButtonText: 'Oui',
                confirmButtonColor: 'blue',
                cancelButtonText: 'Annuler'
            }).then(result => {
                if (result.isConfirmed) {
                    launchSave(rest);
                }
            })
        } else if(parseInt(cancelValidation) === 1) {
            return Swal.fire({
                title: `<span style="color: blue">Annuler la validation du bordereau de commission?</span>`,
                icon: 'question',
                html: `Voulez-vous vraiment annuler la validation du bordereau?`,
                showCloseButton: false,
                showCancelButton: true,
                focusConfirm: false,
                confirmButtonText: 'Oui',
                confirmButtonColor: 'blue',
                cancelButtonText: 'Non'
            }).then(result => {
                if (result.isConfirmed) {
                    launchSave(rest);
                }
            })
        } 
        else {
            launchSave(rest);
        }
    }

    const handleArchive = (archive) => {
        launchSave({archive: archive});
    }

    const monatantRedevance  = (montantCommissionFixe ?? 0) * societe?.percentageRedevance / 100;

    // Détail du formulaire : hasupdate, title and subtitle
    const hasUpdates = Object.keys(dirtyFields).length > 0;

    const title = `Bordereau commission`;
    const subtitle = ``;

    return (
        <form
            autoComplete={`off`}
            className="row"
            onSubmit={handleSubmit(onSubmit)}>

            <MenuToolbar
                title={title}
                subtitle={subtitle}>
                {/* action={() => 
                <button
                    {...fusion({ disabled: !hasUpdates })}
                    className="btn btn-success btn-sm font-weight-bold mr-2">
                    <FaPlus /> Sauvegarder
                </button>} */}
                <ul className="breadcrumb breadcrumb-separatorless fw-bold fs-7 my-1">
                    <li className="breadcrumb-item text-muted">
                        <Link to="/biens/liste" className="text-muted text-hover-primary">Liste des biens immobiliers</Link>
                    </li>
                    <li className="breadcrumb-item">
                        <span className="bullet bg-gray-200 w-5px h-2px"></span>
                    </li>
                </ul>
            </MenuToolbar>

            <div className="col-md-6">
                <Card
                    title={`Bordereau de commission`}
                    titleIcon={<GiReceiveMoney />}
                    titleColor={`#0fbcf9`}>

                    <div className="row mb-5">
                        <div className={societe?.franchise && (roleSelected === 2 || roleSelected === 1) ? "col-md-6 form-group" : "col-md-12 form-group"}>
                            <div className="form-label">{ `Montant de la commission sur vente HT` }</div>
                            <div className="input-group">
                                <CurrencyInput
                                    type="text"
                                    placeholder="Montant de la commission sur vente HT"
                                    defaultValue={isNaN((montantCommissionFixe ?? 0)) ? 0 : (montantCommissionFixe ?? 0)}
                                    disabled={true}
                                    className="form-control"/>
                            </div>
                        </div>

                        {
                            societe?.franchise && (roleSelected === 2 || roleSelected === 1)? (

                                <div className="col-md-6 form-group">
                                    <div className="form-label">{ `Montant à reverser en tant que franchise en HT` }</div>
                                    <div className="input-group">
                                        <CurrencyInput
                                            type="text"
                                            placeholder="Montant de la commission sur vente HT"
                                            defaultValue={ isNaN(monatantRedevance) ? 0 : monatantRedevance}
                                            disabled={true}
                                            className="form-control"/>
                                    </div>
                                </div>

                            ) : null
                        }

                    </div>

                    <div className="row mb-5">
                        <div className="col-md-12 form-group">
                            <div className="form-label">{ `Montant de la commission sur vente TTC` }</div>
                            <div className="input-group">
                                <CurrencyInput
                                    type="text"
                                    placeholder="Montant de la commission sur vente TTC"
                                    defaultValue={isNaN(convertToMontantTtc(montantCommissionFixe ?? 0)) ? 0 : convertToMontantTtc(montantCommissionFixe ?? 0)}
                                    disabled={true}
                                    key={`M-`+0}
                                    className="form-control"/>
                            </div>
                        </div>
                    </div>

                    <div className="row mb-5">
                        <div className="col-md-12 form-group">
                            <div className="form-label">{ `Calculé à partir du pourcentage de commission (taux à ${commercial?.percentageCommission} %) en TTC` }</div>
                            <div className="input-group">
                                <CurrencyInput
                                    type="text"
                                    placeholder="Provenance du lead"
                                    className="form-control"
                                    disabled={true}
                                    defaultValue={isNaN(getCommissionProvenanceTtc()) ? 0 : getCommissionProvenanceTtc()}/>
                            </div>
                            { checkError('tauxCommission', errors) }
                        </div>
                    </div>


                    <FraisDetail
                        frais={frais}
                        totalFrais={totalFrais}
                        loading={fraisDetail?.loading}
                        onReady={(totalFrais) => setValue('totalFrais', totalFrais)}
                        bienId={bienDetail.id}/>
                    
                    <div className="row mb-5">
                        <div className="col-md-12 form-group">
                            <div className="form-label"><FaPlus/> { `Montant à ajouter à la comission en HT` }</div>
                            <div className="input-group">
                                <Controller
                                    name="rajoutCommission"
                                    control={control}
                                    render={({ field }) => {
                                        return <CurrencyInput 
                                            {...field}
                                            className={`form-control`}
                                            disabled={bordereau?.status === 1}
                                            defaultValue={bordereau?.rajoutCommission ?? 0}
                                            placeholder={`Montant à ajouter à la comission`}/>
                                    }}/>
                            </div>
                            { checkError('rajoutCommission', errors) }
                        </div>

                        <div className="col-md-12 mt-2 form-group">
                            <textarea 
                                placeholder="Commentaire"
                                className="form-control"
                                disabled={bordereau?.status === 1}
                                defaultValue={bordereau?.rajoutCommentaire ?? ``}
                                {...register('rajoutCommentaire')}/>
                        </div>
                    </div>

                    <div className="row mb-5">
                        <div className="col-md-12 form-group">
                            <div className="form-label"><FaMinus /> { `Montant à soustraire à la comission en HT` }</div>
                            <div className="input-group">
                                <Controller
                                    name="soustraireCommission"
                                    control={control}
                                    render={({ field }) => {
                                        return <CurrencyInput 
                                            {...field}
                                            className={`form-control`}
                                            disabled={bordereau?.status === 1}
                                            defaultValue={bordereau?.soustraireCommission ?? 0}
                                            placeholder={`Montant à soustraire à la comission`}/>
                                    }}/>
                            </div>
                            { checkError('soustraireCommission', errors) }
                        </div>

                        <div className="col-md-12 mt-2 form-group">
                            <textarea 
                                placeholder="Commentaire"
                                className="form-control"
                                disabled={bordereau?.status === 1}
                                defaultValue={bordereau?.soustraireCommentaire ?? ``}
                                {...register('soustraireCommentaire')}/>
                        </div>

                        {
                            bordereau?.status === 1 && roleSelected < 3 ? (

                                <div className="form-check mt-5 mb-5 text-end">
                                <label className="form-check-label">
                                    <input
                                        className="form-check-input"
                                        type="checkbox"
                                        defaultChecked={bordereau?.archive}
                                        onChange={(event) => {
                                            const checked = event.target.checked;
                                            handleArchive(checked ? 1 : -1);
                                        }}/>
                                        Archiver
                                </label>
                            </div>

                            ) : null
                        }

                    </div>
                </Card>
            </div>
            
            <div className="col-md-6">
                <Card
                    title={`Repartitions des commissions`}
                    titleIcon={<FaPercentage />}
                    titleColor={`#f18f01`}>
                    
                    <RepartitionCommission
                        fusion={fusion}
                        hasUpdates={hasUpdates}
                        register={register}
                        control={control}
                        setValue={setValue}
                        getValues={getValues}
                        watch={watch}
                        getCommissionTotal={getCommissionTotal}
                        getCommissionTotalDueHt={getCommissionTotalDueHt}
                        getCommissionTotalDueTtc={getCommissionTotalDueTtc}
                        montantCommission={commercial?.montantCommission}
                        percentageCommission={commercial?.percentageCommission}
                        tauxCommission={commercial?.provenance?.tauxCommission ?? 0}
                        rajoutCommission={rajoutCommission}
                        soustraireCommission={soustraireCommission}
                        totalFrais={totalFrais}
                        bienDetail={bienDetail}
                        commissionQuery={commissionQuery}
                        bordereau={bordereau}
                        roleSelected={roleSelected}
                        convertToMontantTtc={convertToMontantTtc}
                        convertToMontantHt={convertToMontantHt}
                        dataFrais = {dataFrais}
                        totalFraisReduceMarge = {totalFraisReduceMarge}
                        totalFraisReduceMargePourcent = {totalFraisReduceMargePourcent}
                        totalFraisDeduce = {totalFraisDeduce}
                        totalFraisDeducePourcent = {totalFraisDeducePourcent}
                        commercial = {commercial}
                    />
                </Card>
            </div>
        </form>
    )
}

const mapStateToProps = (state) => {
    const { user } = state.AuthReducer;
    const { roleSelected, privilegeSelected } = user;
    const { bordereauValidate } = BordereauReducer;
    return {
        roleSelected,
        privilegeSelected,
        bordereauValidate
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        bordereauDispatch: () => dispatch(bordereauUpdateAttempt())
    }
}
  
export default connect(mapStateToProps, mapDispatchToProps)(BordereauCommission);