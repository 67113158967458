import { Link } from 'react-router-dom';

import AgenceForm from './partials/AgenceForm.partial';

import { AiFillPlusSquare } from 'react-icons/ai';
import { MenuToolbar } from '@/routes';
import { useNavigate } from 'react-router-dom';
import { useForm } from 'react-hook-form';
import { useMutation } from '@/components/Utils';
import { useUpload } from '@/components/UI';
import './AgenceCreate.styles.scss';

const AgenceCreate = () => {

    const navigate = useNavigate();
    const { getValues, register, handleSubmit, setValue, watch, formState: { dirtyFields, errors } } = useForm();


    const [saveAgence, { fusion }] = useMutation(`/agences`);

    const onSubmit = ({ status, ...rest }) => {
        saveAgence({
            status: status ? 1 : -1,
            ...rest
        }).then(response => {
            if (response.status === 201)
                navigate(`/agences/liste`);
        })
    }

    const { showUploader, uploading } = useUpload({
        folder: `/images/agences`,
        config: {
            width: 225,
            height: 225
        },
        useIcon: true,
        label: `Photo de l'agence`,
        labelAllowed: ``,
        defaultValue: null,
        onUploaded: ({ filePath }) => setValue(`photo`, filePath, { shouldDirty: true })
    })

    const status = getValues('status');
    watch('status');
    
    const params = {
        register,
        errors,
        setValue,
        status,
        showUploader,
    }

    const hasUpdates = Object.keys(dirtyFields).length === 0;

    return (
        <form
            className="agence-create"
            onSubmit={handleSubmit(onSubmit)}>
            <MenuToolbar
                title={`Ajout d'une nouvelle agence`}
                action={() => 
                    <button
                        {...fusion({ disabled: hasUpdates || uploading})}
                        type="submit"
                        className="btn btn-success btn-sm font-weight-bold mr-2">
                        <AiFillPlusSquare /> Sauvegarder
                    </button>}>
                <ul className="breadcrumb breadcrumb-separatorless fw-bold fs-7 my-1">
                    <li className="breadcrumb-item text-muted">
                        <Link to="/agences/liste" className="text-muted text-hover-primary">Liste des agences</Link>
                    </li>
                    <li className="breadcrumb-item">
                        <span className="bullet bg-gray-200 w-5px h-2px"></span>
                    </li>
                </ul>
            </MenuToolbar>

            <AgenceForm
                {...params}/>
        </form>
    )
}

export default AgenceCreate
