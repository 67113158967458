import React from 'react'

import AgenceToolbar from '../../AgenceToolbar/AgenceToolbar';
import { useForm, useFieldArray } from 'react-hook-form';
import { usePut, useQuery } from '@/components/Utils';
import { Card, SelectSearch, Swal } from '@/components/UI';

// Icon
import { FaPhone, FaPlus, FaSpinner, FaTimes } from 'react-icons/fa';
import { AiOutlineMail } from 'react-icons/ai';

const PrivilegeForm = ({ label, title, titleColor, titleIcon, roleId, agenceDetail }) => {
    const agenceId = agenceDetail.id;

    // Temporary value
    const [temporary, setTemporary] = React.useState(null);
    
    // Http request hook form configuration
    const [updatePrivilege, { fusion }] = usePut(`/agences/privileges`);
    const { data, loading } = useQuery(`/agences/privileges`, {
        variables: {
            roleId,
            agenceId
        }
    });
    
    // React Hook form configuration
    const { getValues, handleSubmit, reset, watch, control, formState: { dirtyFields } } = useForm({});
    const { fields, append, remove } = useFieldArray({
        control,
        name: "users",
    });

    watch('users');
    const users = getValues('users');
    
    // Default value for privileges
    React.useEffect(() => {
        if (data) {
            reset({
                users: data
            })
        }
        return () => {}
    }, [data, reset])

    const onSubmit = (data) => {
        updatePrivilege({
            ...data,
            agenceId,
            roleId
        }).then(response => {
            if (response.status === 200) {
                Swal.fire({
                    title: 'Agence mis à jour avec succès',
                    icon: 'success',
                    showConfirmButton: false,
                    timer: 1500
                });
            }
        });
    }

    const addElement = (clearSelect) => {
        if (!temporary) return null;

        // Check if agence already exist
        const exist = users.find(user => user.userId === temporary.id);


        if (exist === undefined) {
            append({
                nom: temporary.nom,
                prenom: temporary.prenom,
                telephone: temporary.telephone,
                email: temporary.email,
                userIri: `/api/users/${temporary.id}`,
                userId: temporary.id,
                roleId,
                agenceId,
                type: 1
            });
            clearSelect();
            setTemporary(null);
        } else {

            Swal.fire({
                title: `<span style="color: red">Cet utilisateur existe déjà</span>`,
                icon: 'error',
                html: `L'utilisateur est déjà affecté en tant que ${label} dans la liste`,
                showCloseButton: false,
                showConfirmButton: false,
                timer: 1500
            });
            clearSelect();
            setTemporary(null);
        }
        
    }

    const removeElement = (index) => {
        Swal.fire({
            title: `<span style="color: red">Supprimer le ${ label }?</span>`,
            icon: 'warning',
            html: `Voulez-vous réellement supprimer le rôle ${label} de cette personne?`,
            showCloseButton: false,
            showCancelButton: true,
            focusConfirm: false,
            confirmButtonText: 'Oui',
            confirmButtonColor: 'red',
            cancelButtonText: 'Annuler'
        }).then(result => {
            if (result.isConfirmed) {
                remove(index)
            }
        })
    }

    const hasUpdates = Object.keys(dirtyFields).length === 0;
    
    return (
        <form onSubmit={handleSubmit(onSubmit)}>
            
            <AgenceToolbar
                title={`Modification de l'agence`}
                subtitle={agenceDetail ? agenceDetail.nom + ` #${agenceDetail.id}` : ``}
                actionDisabled={hasUpdates}
                fusion={fusion}/>

            <div className="row">
                <div className="col-md-6">
                    <Card
                        title={title}
                        titleColor={titleColor}
                        titleIcon={titleIcon}>
                        
                        <div className="fs-3 mb-2">Nouveau { label } :</div>
                        <div className="d-flex mb-4">
                            <SelectSearch
                                url={`/users`}
                                config={{
                                    field: [`nom`, `prenom`],
                                    label: [`nom`, `prenom`]
                                }}
                                className="flex-auto me-2"
                                onSelect={({ value }) => setTemporary(value)}
                                append={({ clearSelect }) => 
                                    <button
                                        onClick={() => addElement(clearSelect)}
                                        disabled={temporary === null}
                                        className="btn btn-success"
                                        type="button">
                                        <FaPlus /> Ajouter
                                    </button>
                                }/>
                        </div>
                        
                        {/* Affichage de la liste des utilisateurs */}
                        <div className="fs-3 mb-2">Liste des { label }s de l'agence :</div>
                        <div style={{minHeight: '100px'}}>
                            <table className="table table-row-dashed table-row-gray-300 align-middle gs-0 gy-4">
                                <thead>
                                    <tr className="border-0">
                                        <th className="p-0 min-w-150px fw-bold"></th>
                                        <th className="p-0 min-w-50px fw-bold"></th>
                                    </tr>
                                </thead>
                                <tbody>
                                    { loading === true ? (
                                        <tr>
                                            <td align="center">
                                                <div className="fs-4 text-center text-primary" colSpan="2">Chargement en cours <FaSpinner  className="ms-2 spin-animation"/> </div>
                                            </td>
                                        </tr>
                                    ) : null }
                                    { !loading && users && users.length === 0 ? (
                                        <tr>
                                            <td align="center">
                                                <div className="fs-4 text-center text-muted" colSpan="2">Aucun { label } n'a été ajouté pour le moment.</div>
                                            </td>
                                        </tr>) : null }  

                                        { fields && fields.map((field, index) => {
                                            return (
                                                <tr key={index}>
                                                    <td>
                                                        <div className="text-dark fw-bolder text-hover-primary fs-5">{ field.nom } { field.prenom }</div>
                                                        <div className="text-muted fw-bold text-muted d-block fs-6"><FaPhone /> { field.telephone } / <AiOutlineMail /> { field.email ?? `N/A` }</div>
                                                    </td>
                                                    <td className="text-end">
                                                        <span className="btn btn-sm btn-icon btn-bg-light btn-danger" onClick={() => removeElement(index)}>
                                                            <FaTimes />
                                                        </span>
                                                    </td>
                                                </tr>
                                            )
                                        })}      
                                </tbody>
                            </table>
                        </div>
                    </Card>
                </div>
            </div>
        </form>
    )
}

export default PrivilegeForm
