import axios from '@/axios';
import { authUpdateRole } from '../Auth/auth.action';

export const ROLE_GET_REQUEST = 'ROLE_GET_REQUEST';
export const ROLE_GET_ERROR = 'ROLE_GET_ERROR';
export const ROLE_GET_SUCCESS = 'ROLE_GET_SUCCESS';

export const roleGetRequest = () => ({
    type: ROLE_GET_REQUEST
});

export const roleGetError = () => ({
    type: ROLE_GET_ERROR
});

export const roleGetSuccess = (roles) => ({
    type: ROLE_GET_SUCCESS,
    roles
});

export const roleGetAttempt = () => {
    return (dispatch) => {
        dispatch(roleGetRequest());
        return axios.get('/user/roles').then(response => {
            if (response) {
                const roles = response.data ?? null;
                dispatch((roleGetSuccess(roles)))
            } else {
                dispatch((roleGetError()))
            }
        }).catch(() => {
            dispatch((roleGetError()))
        })
    }
}

export const ROLE_UPDATE_REQUEST = 'ROLE_UPDATE_REQUEST';
export const ROLE_UPDATE_ERROR = 'ROLE_UPDATE_ERROR';
export const ROLE_UPDATE_SUCCESS = 'ROLE_UPDATE_SUCCESS';

export const roleUpdateRequest = () => ({
    type: ROLE_UPDATE_REQUEST
});

export const roleUpdateError = () => ({
    type: ROLE_UPDATE_ERROR
});

export const roleUpdateSuccess = () => ({
    type: ROLE_UPDATE_SUCCESS
});

export const roleUpdateAttempt = (privilegeId) => {
    return (dispatch) => {
        dispatch(roleUpdateRequest());
        return axios.post('/user/role/update', {
            privilegeId
        }).then(response => {
            if (response) {
                const role = response.data ?? null;
                const { roleId, privilegeId } = role;
                dispatch(roleUpdateSuccess())
                dispatch(authUpdateRole(roleId, privilegeId))
            } else {
                dispatch((roleUpdateError()))
            }
        }).catch(() => {
            dispatch((roleUpdateError()))
        })
    }
}