import { useGet, useFilter } from '@/components/Utils';

import { Card, DataTable, LoadingAnimation } from '@/components/UI';

import './../Performance.styles.scss';
import { connect } from 'react-redux';
import { useCallback, useEffect, useMemo, useRef } from 'react';
import './PerformanceNegociateur.styles.scss';
import { MenuToolbar } from '../../../routes';

const PerformanceNegociateur = ({roleSelected, privilegeSelected}) => {
    let [params, query] = useFilter();

    const dateDebut = useRef(null);
    const dateFin = useRef(null);

    const { data, loading, refetch } = useGet(`/dashboard/negociateurs${query}`, {
        headers: {
            'Accept': 'application/ld+json'
        }
    });

    const { data: listProvenances } = useGet(`/provenances?pagination=false`, {
        headers: {
            'Accept': 'application/json'
        }
    });
    
    const columnProvenance = useMemo(() =>{
        const arrayColumn = [];
        if (listProvenances) {
            listProvenances.map((item) => {
                const data = {
                    label: item.nom,
                    type: 'provenance',
                    id: item.nom,
                    component: ({ provenance }) => {
                        let total = 0;
                        provenance.length > 0 && provenance.map((value)=>{      
                            item.nom === value.nomProvenance ? total += value.nombreBien : total+= 0; 
                        })
                        return  <span className={total > 0 ? 'badge badge-success' : ''}> {total} </span>
                    }
                }
                return arrayColumn.push(data);
            })
        }

        return arrayColumn
    }, [listProvenances]);

    const rows = data?.negociateurs ?? [];

    useEffect(() => {
        refetch();
        return () => {}
    }, [roleSelected, privilegeSelected]);

    const filterByDate = useCallback(
        () => {
            if (dateDebut.current.value !== "" && dateFin.current.value !== "") {                
                params.updates({
                    dateDebut: dateDebut.current.value,
                    dateFin: dateFin.current.value,
                });
            }
        },
        [params],
    );

    const columns = [{
        name: 'type',
        label: '',
        component: ({ nom, prenom }) => (<span className="text-dark fw-bolder text-hover-primary fs-6">{ nom } {prenom}</span>)
    }, {
        label: 'Tous',
        type: 'state',
        id: 'nbrAll',
        component: ({ nbrAll }) => (
            <span className={nbrAll > 0 ? 'badge badge-primary' : 'badge badge-danger'}>{nbrAll}</span>
            )
    }, {
        label: 'En vente',
        type: 'state',
        id: 'nbrEnVente',
        component: ({ nbrEnVente }) => (
            <span className={nbrEnVente > 0 ? 'badge badge-primary' : 'badge badge-danger'}>{nbrEnVente}</span>
            )
    }, {
        label: 'Sous offre',
        type: 'state',
        id: 'nbrSousOffre',
        component: ({ nbrSousOffre }) => (
            <span className={nbrSousOffre > 0 ? 'badge badge-primary' : 'badge badge-danger'}>{nbrSousOffre}</span>
            )
    }, {
        label: 'Sous promesse de vent',
        type: 'state',
        id: 'nbrSousPromesse',
        component: ({ nbrSousPromesse }) => (
            <span className={nbrSousPromesse > 0 ? 'badge badge-primary' : 'badge badge-danger'}>{nbrSousPromesse}</span>
            )
    }, {
        label: 'Acte définitif signé',
        type: 'state',
        id: 'nbrActe',
        component: ({ nbrActe }) => (
            <span className={nbrActe > 0 ? 'badge badge-primary' : 'badge badge-danger'}>{nbrActe}</span>
            )
    }, {
        label: 'En location',
        type: 'state',
        id: 'nbrLocation',
        component: ({ nbrLocation }) => (
            <span className={nbrLocation > 0 ? 'badge badge-primary' : 'badge badge-danger'}>{nbrLocation}</span>
            )
    }, {
        label: 'Loué',
        type: 'state',
        id: 'nbrLoue',
        component: ({ nbrLoue }) => (
            <span className={nbrLoue > 0 ? 'badge badge-primary' : 'badge badge-danger'}>{nbrLoue}</span>
            )
    }, {
        label: 'Archivé',
        type: 'state',
        id: 'nbrArchive',
        component: ({ nbrArchive }) => (
            <span className={nbrArchive > 0 ? 'badge badge-primary' : 'badge badge-danger'}>{nbrArchive}</span>
            )
    }
    , ...columnProvenance , {
        name: 'type',
        label: '',
        component: ({ nom, prenom }) => (<span className="text-dark fw-bolder text-hover-primary fs-6">{ nom } {prenom}</span>)
    }];

    return (
        <>
            <MenuToolbar
                title={`Performances`}
                >

                <div className="previous-search d-flex align-items-center position-relative my-1 me-auto p-2 bd-highlight"
                    style={{paddingTop:'3px', paddingBottom: '3px'}}
                    >
                    <div className="search-date">
                        <div className="search-text">du</div> 
                        <input 
                            type="date"
                            className="form-control form-control-sm"
                            ref={dateDebut}
                        />
                    
                        <div className="search-text" style={{marginLeft: '5px'}}>au</div> 
                        <input
                            type="date"
                            className="form-control form-control-sm"
                            ref={dateFin}
                        />
                    </div>
                    <div className="previous-button">
                        <button
                            className="btn btn-success btn-sm font-weight-bold mr-2"
                            onClick = {() => filterByDate()}
                        >
                            Valider
                        </button>
                    </div>
                </div>
            </MenuToolbar>

            <div className="relative performance-negociateur mt-5">
                { loading ? (<LoadingAnimation className={`mb-4`}/>) : (
                    <>
                        <Card 
                            className="relative mb-4 agence-list"
                            titleColor={`#00000`}
                            title={'Nombre de biens par négociateur'}
                            >
                            <DataTable
                                className="performance-provenance"
                                classique={true}
                                columns={columns}
                                columnsShort={true}
                                params={params}
                                rows={rows}/>
                        </Card>

                    </>
                ) }
            </div>
        </>
    )
}

const mapStateToProps = (state) => {
    const { user } = state.AuthReducer;
    const { roleSelected, privilegeSelected } = user;
    return {
        roleSelected,
        privilegeSelected
    }
}

export default connect(mapStateToProps)(PerformanceNegociateur)