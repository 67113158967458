import {
    useParams
} from 'react-router-dom';
import ProvenanceListe from './partials/ProvenanceListe/ProvenanceListe.partial';
import ProvenanceForm from './partials/ProvenanceForm/ProvenanceForm.partial';
// import AgenceCreate from './AgenceCreate.page';
import { useGet } from '@/components/Utils';

const Provenance = () => {
    const params = useParams();

    const fraisId = params?.fraisId ?? ``

    const { data, loading } = useGet(`/provenances/${fraisId}`);

    const props = {
        ...params,
        data,
        loading
    }

    const handleSubpage = () => {
        switch(params.action) {
            case 'liste':
                return <ProvenanceListe />;
            
            case 'create':
                return <ProvenanceForm {...props} data={null}/>

            case 'update':
                return <ProvenanceForm {...props}/>

            default:
                return null;
        }
    }

    return (
        <>
            { handleSubpage() }
        </>
    )
}

export default Provenance