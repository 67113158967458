import { MenuToolbar } from '@/routes';
import { Link } from 'react-router-dom';
import { AiFillPlusSquare } from 'react-icons/ai';

const AgenceToolbar = ({ actionDisabled, title, subtitle, fusion }) => {
    return (
        <MenuToolbar
            title={title}
            subtitle={subtitle}
            action={() => 
                <button
                    {...fusion({ disabled: actionDisabled })}
                    type="submit"
                    className="btn btn-success btn-sm font-weight-bold mr-2">
                    <AiFillPlusSquare /> Sauvegarder
                </button>}>
                <ul className="breadcrumb breadcrumb-separatorless fw-bold fs-7 my-1">
                    <li className="breadcrumb-item text-muted">
                        <Link to="/agences/liste" className="text-muted text-hover-primary">Liste des agences</Link>
                    </li>
                    <li className="breadcrumb-item">
                        <span className="bullet bg-gray-200 w-5px h-2px"></span>
                    </li>
                </ul>
        </MenuToolbar>
    )
}

export default AgenceToolbar
