import {
    useParams
} from 'react-router-dom';
import FraisListe from './partials/FraisListe/FraisListe.partial';

const Frais = () => {
    const params = useParams();

    const handleSubpage = () => {
        switch(params.action) {
            default:
                return <FraisListe />;
        }
    }

    return (
        <>
            { handleSubpage() }
        </>
    )
}

export default Frais