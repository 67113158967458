import React from 'react';
import { FaPlus, FaTimes } from 'react-icons/fa';
import { SelectSearch } from '@/components/UI';

const RoleMultiple = ({
    url = `agences`,
    type = 1,
    role,
    register,
    checkError,
    errors,
    setValue,
    clearErrors,
    privileges,
    permissions,
    disable
}) => {
    
    const typeLabel = type === 1 ? 'agenceId' : 'societeId';
    const [temporary, setTemporary] = React.useState(null);
    
    const handleSelect = (value) => {
        setTemporary(value);
    }

    const addElement = (clearSelect) => {

        if (!temporary) return null;

        const defaultPrivilege = privileges[role.key] ?? [];
        let privilege = false;

        // Check if privilege already added to list
        if (privileges[role.key])
            privilege = privileges[role.key].find(privilege => privilege[typeLabel] === temporary.id)
        
        if (!privilege) {
            setValue(`privileges.${role.key}`, [
                ...defaultPrivilege,
                {
                    permissionId: role.permissionId,
                    [typeLabel]: temporary.id,
                    nom: temporary.nom,
                    type
                }
            ], { shouldDirty: true });
            clearSelect();
            setTemporary(null);
            clearErrors(`privileges.${role.key}`);
        }
    }

    return (
        <>
            { checkError(`privileges.${role.key}`, errors, (<div className="fs-6 fw-bold">Vous devez ajouter au moins un élément</div>)) }
            <div className="d-flex mt-4">
                <SelectSearch
                    url={url}
                    className="flex-auto me-2"
                    disabled={permissions && !permissions.includes(role.name)}
                    onSelect={({ value }) => handleSelect(value)}
                    append={({ clearSelect }) => 
                        <button
                            disabled={(permissions && !permissions.includes(role.name)) || disable}
                            className="btn btn-success"
                            type="button"
                            onClick={() => addElement(clearSelect)}>
                            <FaPlus /> Ajouter
                        </button>
                    }/>
            </div>

            <div className="pt-4">
                <table className="table table-border table-row-dashed table-row-gray-200 align-middle gs-0 gy-1">
                    <thead>
                        <tr className="border-0">
                            <th className="p-0 min-w-150px"></th>
                            <th className="p-0 min-w-50px"></th>
                        </tr>
                    </thead>
                    <tbody>
                        { (privileges && !privileges[role.key]) || privileges[role.key].length === 0 ? (
                            <tr>
                                <td>
                                    <span className="">Aucun élément n'a été associée pour le moment</span>
                                </td>
                            </tr>) : null }  

                        { privileges[role.key] && privileges[role.key].map((privilege, key) => {
                                return (
                                    <tr key={key}>
                                        <td>
                                            <span className="text-dark text-hover-primary fs-6">{ privilege.nom }</span>
                                        </td>
                                        <td className="text-end">
                                            <span className={"btn btn-sm btn-icon btn-bg-light btn-danger " + (disable ? 'readonly' : '')} onClick={() => {
                                                setValue(`privileges.${role.key}`, [
                                                    ...privileges[role.key].slice(0, key),
                                                    ...privileges[role.key].slice(key + 1),
                                                ], { shouldDirty: true })
                                            }}>
                                                <FaTimes />
                                            </span>
                                        </td>
                                    </tr>
                                )
                            })}      
                    </tbody>
                </table>
                
                { permissions && permissions.includes(role.name) ? (
                    <input type="hidden" {...register(`privileges.${role.key}`, { required: true })} />
                ) : null }
            </div>
        </>
    )
}

export default RoleMultiple
