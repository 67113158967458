import React from 'react'
import { useGet } from '@/components/Utils';
import { publicAssets } from '@/_metronic/helpers';
import './FraisSelector.styles.scss';
import { FaCheck, FaSpinner } from 'react-icons/fa';

const FraisSelector = ({ defaultValue, onSelect }) => {
    const { data, loading } = useGet(`/frais_types`, {
        headers: {
            'Accept': 'application/json'
        }
    });

    const frais = data ?? [];

    const [value, setValue] = React.useState(null);

    const isActive = (fraisItem) => {
        if (value && value.id === fraisItem.id) return true;

        return false;
    }

    const handleChange = React.useCallback((frais) => {
        setValue(frais);

        if (typeof onSelect === 'function') onSelect(frais);
    }, [setValue, onSelect])


    React.useEffect(() => {
        if (defaultValue && data && value === null) {
            const defaultFrais = data.find(item => item.id === defaultValue.id);
            if (defaultValue) handleChange(defaultFrais);
        }
        
        return () => {}
    }, [defaultValue, data, handleChange, value])

    return (
        <div className="frais-selector">
            { loading === true ? (
            <div className="frais-loading">
                <div className="loading-icon">
                    <FaSpinner className="spin-quickly"/>
                </div>
                Chargement en cours
            </div>) : null }
            { loading === false && frais && frais.map((fraisItem, key) => {
                return (
                    <div key={key} className={`frais-box ${isActive(fraisItem) ? `active` : ``}`} onClick={() => handleChange(fraisItem)}>
                        <img src={publicAssets(fraisItem.icone)} alt={fraisItem.nom}/>
                        <div>{ fraisItem.nom }</div>

                        <div className="frais-check-active">
                            <FaCheck/>
                        </div>
                    </div>)
            }) }
        </div>
    )
}

export default FraisSelector
