import './RepartitionCommission.styles.scss';
import { Currency, CurrencyInput } from '@/components/UI';
import { useFieldArray } from 'react-hook-form';
import { FaCircleNotch, FaExclamationTriangle } from 'react-icons/fa';
import { Dropzone, PercentageInput } from '../../../../../../components/UI';

const RepartitionCommission = ({
    fusion,
    hasUpdates,
    register,
    setValue,
    control,
    getCommissionTotal,
    getCommissionTotalDueHt,
    getCommissionTotalDueTtc,
    montantCommission,
    percentageCommission,
    tauxCommission,
    rajoutCommission,
    soustraireCommission,
    totalFrais,
    bienDetail,
    commissionQuery,
    bordereau,
    roleSelected,
    convertToMontantTtc,
    convertToMontantHt,
    dataFrais,
    totalFraisReduceMarge,
    totalFraisReduceMargePourcent,
    totalFraisDeduce,
    totalFraisDeducePourcent,
    commercial,
  }) => {
  // const commissionTotal = getCommissionTotal();
  const commissionTotalDueHt = getCommissionTotalDueHt();
  const commissionTotalDueTtc = getCommissionTotalDueTtc();

  const { fields, update } = useFieldArray({
    control,
    name: "commissions"
  });

  const { validatedAt, validatedBy, archivateBy, archivateAt } = bordereau ?? {};

  const negociateurs = fields.filter(field => field.type === "negociateur");
  const directeurs = fields.filter(field => field.type === "directeur");

  const checkPercentage = () => {
    
    let totalPercentage = fields
    .filter(field => field.type === "negociateur")
    .reduce((acc, value) => acc += parseFloat(value.taux), 0);
    totalPercentage = isNaN(totalPercentage) ? 0 : totalPercentage;
    return (totalPercentage === 100 || totalPercentage === 0);
  }

  const { loading } = commissionQuery;

  // Récupération du montant de part selon le pourcentage
  const getCommissionDirecteur = (index) => {
    if (fields[index]?.taux)
      return (fields[index].taux * montantCommission / 100)

    return 0;
  }

  const getTotalCommissionDirecteur = () => {
    let totalCommissionDirecteur = 0;
    directeurs.map((directeur) =>
      totalCommissionDirecteur = totalCommissionDirecteur + getCommissionDirecteur(directeur.index)
    );

    return totalCommissionDirecteur;
  }

  // Récupération de la marge restante à moriss
  const getMargeBruteCommission = () => {
    return convertToMontantHt(parseFloat(montantCommission)) - dataFrais.dataFraisType4.dataFraisType4Total - commissionTotalDueHt - getTotalCommissionDirecteur() - parseFloat(totalFraisReduceMarge ?? 0) - soustraireCommission - rajoutCommission - totalFraisReduceMargePourcent;
  }

  const getMargeBruteCommissionTtc = () => {
    return parseFloat(montantCommission) - convertToMontantTtc(dataFrais.dataFraisType4.dataFraisType4Total) - commissionTotalDueTtc - convertToMontantTtc(getTotalCommissionDirecteur()) - parseFloat(convertToMontantTtc(totalFraisReduceMarge) ?? 0) - convertToMontantTtc(soustraireCommission) - convertToMontantTtc(rajoutCommission) - convertToMontantTtc(totalFraisReduceMargePourcent);
  }

  const getPercentageHt = (montant) => {

    const percentage = (montant * 100) / commissionTotalDueHt;

    return isNaN(percentage) ? 0 : percentage.toFixed(2);
  }

  if (loading === true) return (<div>Chargement en des répartitions <FaCircleNotch className="spin-quickly"/></div>)

  return (
    <div className="mt-5">
      {/* Commission sur vente */}
      <div className="commission-grandtotal">
        <div className="value-display">
          <div>Commission totale dûe en HT:</div> &nbsp;
          <Currency value={commissionTotalDueHt}/>
        </div>
        <div className="value-formula">
        <Currency value={commissionTotalDueHt}/> = 
        (<Currency value={convertToMontantHt(montantCommission)}/>{dataFrais.dataFraisType4.dataFraisType4Ht !== '' ? (' - ' + dataFrais.dataFraisType4.dataFraisType4Ht) : ' - 0.00 €' }) x { percentageCommission }% + <Currency value={rajoutCommission}/> - <Currency value={soustraireCommission}/>{dataFrais.dataFraisType2.dataFraisType2Ht !== '' ? (' - ' + dataFrais.dataFraisType2.dataFraisType2Ht) : '' }
        </div>
      </div>

      <div className="commission-grandtotal">
        <div className="value-display">
          <div>Commission totale dûe en TTC:</div> &nbsp;
          <Currency value={commissionTotalDueTtc}/>
        </div>
        <div className="value-formula">
        <Currency value={commissionTotalDueTtc}/> = 
        (<Currency value={montantCommission}/>{dataFrais.dataFraisType4.dataFraisType4Ttc !== '' ? (' - ' + dataFrais.dataFraisType4.dataFraisType4Ttc) : ' - 0.00 €' }) x { percentageCommission }% + <Currency value={convertToMontantTtc(rajoutCommission)}/> - <Currency value={convertToMontantTtc(soustraireCommission)}/>{dataFrais.dataFraisType2.dataFraisType2Ttc !== '' ? (' - ' + dataFrais.dataFraisType2.dataFraisType2Ttc) : '' }
        </div>
      </div>

      <div className="commission-subtitle">N&Eacute;GOCIATEUR</div>
      {/* List des commissions */}
      <div className="commission-list">


        <div className="commission-item" key={'montantBase'}></div>
        {negociateurs.map((negociateur) =>
          <div className="commission-item" key={negociateur.index}>

            <div className="commission-wrapper">
              <div className="commission-title">
                Commission pour&nbsp;
                <span style={{textDecoration: 'underline'}}>
                  {negociateur.nom} {negociateur.prenom}
                </span>
              </div>

              <div className="commission-amount">
                <div className="commission-percentage">

                  <CurrencyInput
                    name="montant"
                    defaultValue={negociateur.montant}
                    disabled={bordereau?.status === 1}
                    suffix={ ' € HT'}
                    onChange={(value) => update(negociateur.index, {
                      ...fields[negociateur.index],
                      montant: value,
                      taux: getPercentageHt(value),
                    })}
                    className="form-control input-large"
                  />
                </div>

                <div className="commission-part">
                  <Currency value={convertToMontantTtc(negociateur.montant)} suffix={ ' € TTC'}/>
                </div>

                <div className="commission-part">
                  <span> = &nbsp; {isNaN(negociateur.taux) ? 0 : negociateur.taux} %</span>
                </div>

              </div>
            </div>

            <div className="commission-wrapper">
              <div className="commission-title commission-avanced">dont acompte déjà versé</div>
              <div className="commission-amount">
                <div className="commission-percentage">
                  <CurrencyInput
                    name="pourcentage"
                    defaultValue={negociateur.acompte}
                    disabled={bordereau?.status === 1}
                    onChange={(value) => update(negociateur.index, {
                      ...fields[negociateur.index],
                      acompte: value
                    })}
                    className="form-control input-large"
                  />
                </div>
              </div>

              {/* {roleSelected !== 4 && <div className="commission-ispaid">
                  <label className="form-check input-checkbox">
                    <input
                      className="form-check-input"
                      type="checkbox"
                      defaultChecked={negociateur.regler}
                      disabled={bordereau?.status === 1}
                      onChange={function(event) {
                        const checked = event.target.checked;
                        update(negociateur.index, {
                          ...fields[negociateur.index],
                          regler: checked ? 1 : -1
                        });
                      }}/>
                    <span className="form-check-label fw-bold">
                      Réglé par Moriss
                    </span>
                  </label>
                </div>} */}
            </div>

            <div className="commission-wrapper" style={{marginTop:'10px'}}>
              <Dropzone
                  className="small-dropzone w-100"
                  labelItem={`la Facture (pdf)`}
                  accpect={'application/pdf'}
                  multiple={'true'}
                  defaultValue={negociateur && negociateur?.facture ? negociateur.facture : null}
                  onUploaded={({ id, filePath }) => {
                      update(negociateur.index, {
                          ...fields[negociateur.index],
                          facture: filePath ? id : null
                        });
                  }}/>
            </div>
          </div>
        )}
      </div>
      
      {

        directeurs.length > 0 ? (
          <>
            <div className="commission-subtitle">DIRECTEUR D'AGENCE</div>
            {/* Liste DIRECTEUR */}
            <div className="commission-list">
              {directeurs.map((directeur) =>
                <div className="commission-item" key={directeur.index}>

                  <div className="commission-wrapper">
                    <div className="commission-title">
                      Commission pour&nbsp;
                      <span style={{textDecoration: 'underline'}}>
                        {directeur.nom} {directeur.prenom}
                      </span>
                    </div>

                    <div className="commission-amount">
                      <div className="commission-percentage" lang="en-US">
                        <input
                          type="number"
                          name="pourcentage"
                          min={0}
                          step="0.25"
                          lang="en"
                          className="form-control input-short"
                          disabled={bordereau?.status === 1}
                          defaultValue={directeur.taux}
                          onChange={(event) => update(directeur.index, {
                            ...fields[directeur.index],
                            taux: event.target.value
                          })}/>
                        <span>% = </span>
                      </div>

                      <div className="commission-part">
                        <Currency value={getCommissionDirecteur(directeur.index)}/>
                      </div>
                          
                      {/* {roleSelected !== 4 && <div className="commission-ispaid">
                        <label className="form-check input-checkbox">
                          <input
                            className="form-check-input"
                            type="checkbox"
                            defaultChecked={directeur?.regler}
                            disabled={bordereau?.status === 1}
                            onChange={function(event) {
                              const checked = event.target.checked;
                              update(directeur.index, {
                                ...fields[directeur.index],
                                regler: checked ? 1 : -1
                              });
                            }}/>
                          <span className="form-check-label fw-bold">
                            Réglé par Moriss
                          </span>
                        </label>
                      </div>} */}
                    </div>
                  </div>

                  <div className="commission-wrapper">
                    <div className="commission-title commission-avanced">dont acompte déjà versé</div>
                    <div className="commission-amount">
                      <div className="commission-percentage">
                        <CurrencyInput
                          name="pourcentage"
                          defaultValue={directeur.acompte}
                          disabled={bordereau?.status === 1}
                          onChange={(value) => update(directeur.index, {
                            ...fields[directeur.index],
                            acompte: value
                          })}
                          className="form-control input-short"
                        />
                      </div>
                    </div>
                  </div>
                </div>
              )}
            </div>

          </>
        ) : ''
      }

      {
        roleSelected < 3 &&

        <>
    
          {/* Marge restante à moriss */}
          <div className="pb-2 commission-marge">
            <div className="value-display">
              Marge restante à Moriss en HT:&nbsp;
              <Currency value={getMargeBruteCommission()}/>
            </div>
            <div className="value-formula">

            <Currency value={getMargeBruteCommission()}/> = 
            {` `}<Currency value={convertToMontantHt(montantCommission)}/> - 
            {` `}<Currency value={dataFrais.dataFraisType4.dataFraisType4Total}/> -
            {` `}<Currency value={commissionTotalDueHt}/>
            {directeurs.length > 0 ? ( directeurs.map((directeur) => (<> - <Currency key={directeur.index} value={getCommissionDirecteur(directeur.index)}/> </>)) ) : <> </> }
            -  <Currency value={soustraireCommission}/> - <Currency value={rajoutCommission}/>{dataFrais.dataFraisType3.dataFraisType3Ht !== '' ? (' - (' + dataFrais.dataFraisType3.dataFraisType3Ht)+')' : '' }{dataFrais.dataFraisType2.dataFraisType2PourcentHt !== '' ? (` - ${dataFrais.dataFraisType2.dataFraisType2PourcentHt}`) : '' }
            
            </div>
          </div>


          <div className="pb-2 commission-marge">
            <div className="value-display">
              Marge restante à Moriss en TTC:&nbsp;
              <Currency value={getMargeBruteCommissionTtc()}/>
            </div>
            <div className="value-formula">

            <Currency value={getMargeBruteCommissionTtc()}/> = 
            {` `}<Currency value={montantCommission}/> - 
            {` `}<Currency value={convertToMontantTtc(dataFrais.dataFraisType4.dataFraisType4Total)}/> - 
            {` `}<Currency value={commissionTotalDueTtc}/>
            {directeurs.length > 0 ? ( directeurs.map((directeur) => (<> - <Currency key={directeur.index} value={convertToMontantTtc(getCommissionDirecteur(directeur.index))}/> </>)) ) : <> </> }
            - <Currency value={convertToMontantTtc(soustraireCommission)}/> - <Currency value={convertToMontantTtc(rajoutCommission)}/>{dataFrais.dataFraisType3.dataFraisType3Ttc !== '' ? (' - (' + dataFrais.dataFraisType3.dataFraisType3Ttc)+')' : '' }{dataFrais.dataFraisType2.dataFraisType2PourcentTtc !== '' ? (` - ${dataFrais.dataFraisType2.dataFraisType2PourcentTtc}`) : '' }
            
            </div>
          </div>

        </>

      }
      
      { bordereau?.status <= 0 || !bordereau ? (
        <>

          {
            roleSelected !== 4 ? (

              <>

                {
                  roleSelected < 3 ? (
                    <>
                      <div className="form-check mt-5 mb-5">
                        <label className="form-check-label">
                            <input
                                className="form-check-input"
                                type="checkbox"
                                disabled={roleSelected === 4 || bienDetail.etat !== 3?true:false}
                                onChange={(event) => {
                                  const checked = event.target.checked;
                                  setValue('validate', (checked ? 1 : 0), { shouldDirty: true });
                                }}/>
                                Je valide définitivement le bordereau et je fige l'ensemble des données de cette vente
                        </label>
                      </div>

                      <button
                          {...fusion({ disabled: !checkPercentage() || bienDetail.etat !== 3})}
                          className="btn btn-primary"
                          style={{width: '100%'}}>
                          Valider
                      </button>

                    </>

                  ) : null
                }

              </>

            ) : (

                <>
                  <div className="form-check mt-5 mb-5">
                    <label className="form-check-label">
                        <input
                            className="form-check-input"
                            type="checkbox"
                            defaultChecked={commercial.requestValidation}
                            onChange={(event) => {
                              const checked = event.target.checked;
                              setValue('requestValidation', (checked ? 1 : 0), { shouldDirty: true });
                            }}/>
                            Je demande la validation du bordereau
                    </label>
                  </div>

                  <button
                      {...fusion({ disabled: !checkPercentage() || bienDetail.etat !== 3})}
                      className="btn btn-primary"
                      style={{width: '100%'}}>
                      Valider
                  </button>

                </>
            )
          
          }

          { !checkPercentage() && hasUpdates ? (
            <div className="alert alert-warning mt-4 flex items-center justify-center" role="alert">
              <FaExclamationTriangle /> &nbsp; 
              <div className="ms-4">La somme des parts des commissions pour les négociateurs est incorrecte</div>
            </div>) : 
          null }
        </>
      ) : (
          <>

            <div className="alert alert-warning">
              Le bordereau de commission a été validé par : <br />
              <span style={{fontWeight: "bold", textDecoration: "underline"}}>{validatedBy?.nom} {validatedBy?.prenom}</span> le { validatedAt }
            </div>

            {
              roleSelected === 1 ? (

                <>

                  <input type="hidden" defaultValue={1} {...register(`cancelValidation`, { required: true })} />

                  <button
                      className="btn btn-primary"
                      style={{width: '100%'}}>
                      Annuler la validation du bordereau
                  </button>

                </>

              ) : null
            }

          </>
      )}
      
    </div>
  )
}


export default RepartitionCommission;
