import React, { useCallback, useMemo, useRef } from 'react';
import './SalesDetailed.style.scss';
import { Card } from '@/components/UI';
import { Currency } from '@/components/UI';
import { useFilter, useGet } from '../../../../components/Utils';
import { connect } from 'react-redux';
import { ListGroup } from 'react-bootstrap-v5';
import FilterByState from '../../../../components/Filter/FilterByState/FilterByState.component';
import { AiOutlineCloseCircle, AiOutlineInfoCircle } from 'react-icons/ai';
import { Link } from 'react-router-dom';
import { LoadingAnimation } from '../../../../components/UI';

const SalesDetailed = ({roleSelected, privilegeSelected}) => {
    let [params, query] = useFilter();
    const dateDebut = useRef(null);
    const dateFin = useRef(null);
    const [alert, setAlert] = React.useState(null);
    const [alertFrais, setAlertFrais] = React.useState(null);
    const currentDate = new Date();
    const year = currentDate.getFullYear();
    
    const { data, loading: loadingStat, refetch } = useGet(`/dashboard/detailed${query}`);

    React.useEffect(() => {
        refetch();
    
        return () => {}
    }, [roleSelected, privilegeSelected])

    const stats = data?.stats ?? {};

    const filterByDate = useCallback(
        () => {               
            params.updates({
                dateDebut: dateDebut.current.value,
                dateFin: dateFin.current.value,
            });
        },
        [params],
    );

    const { data: fraisList, loading, refetch: refetchFrais } = useGet(`/frais/list${query}`, {
        headers: {
            'Accept': 'application/ld+json'
        }
    });

    const total = useMemo(() => fraisList?.total, [fraisList]);

    const dateDebutBien = useRef(null);
    const dateFinBien = useRef(null);

    const filterByDateBien = useCallback(
        () => {
            if (dateDebutBien.current.value !== "" && dateFinBien.current.value !== "") {                
                params.updates({
                    dateDebutBien: dateDebutBien.current.value,
                    dateFinBien: dateFinBien.current.value,
                });
            }
        },
        [params],
    );

    return (

        <>
            <Card
                title={stats.franchise ? `Chiffre d'affaire détaillé et Redevance` : `Chiffre d'affaire détaillé`}
                className="mb-4 detailed-sales">

                    {
                    alert ? 
                        <div className="alert alert-success" role="alert">
                        <AiOutlineCloseCircle 
                            onClick={() => setAlert(null)}
                            role="button" style={{fontSize: '30px', marginRight: '10px'}} 
                            className="alert-heading text-danger mb-1"
                            data-toggle="tooltip"
                            title="Fermer le texte d'aide"
                            />
                        Vous pouvez ici choisir une période et un état de commercialisation afin d'obtenir des chiffres qui prennent en compte tous les biens immobiliers concernés (bordereaux validés ou non validés).
                        <hr></hr>
                        <p className="mb-1">
                            <strong>Montant total de vente :</strong> Somme des « Prix FAI de l'annonce » des biens sur la période donnée.
                        </p>
                        <p className="mb-1">
                            <strong>Montant total de commission :</strong> Somme des montants de Commission sur la période donnée.
                        </p>
                        <p className="mb-0">
                            <strong>Bénéfice Moriss :</strong> Marge totale restante à Moriss. On ne prend en compte que les bordereaux avec état = « Acte définitive signé »
                        </p>
                        </div> : <AiOutlineInfoCircle
                                    onClick={() => setAlert(1)}
                                    role="button" 
                                    style={{fontSize: '30px'}} 
                                    className="text-primary mb-5"
                                    data-toggle="tooltip"
                                    title="Afficher le texte d'aide"
                                    />

                    }

                <ListGroup style={{marginBottom:'10px'}}>
                    <ListGroup.Item>
                        
                    <div className="row p-2">

                        <div className="col-md-2">
                            <FilterByState
                                params={params}/>
                        </div>

                        <div className="col-md-3">
                            <div className='d-flex align-items-center position-relative my-1 ms-2'>
                                <span style={{flex: '0 0 auto', marginRight: '5px'}}>
                                    Période entre le
                                </span>
                                <input 
                                    type="date"
                                    className="form-control form-control-sm"
                                    defaultValue={String(`${year}-01-01`)}
                                    ref={dateDebut}/>
                            </div>
                        </div>

                        <div className="col-md-2">
                            <div className='d-flex align-items-center position-relative my-1 ms-2'>
                                <span style={{flex: '0 0 auto', marginRight: '5px'}}>
                                    et le
                                </span>
                                <input 
                                    type="date"
                                    className="form-control form-control-sm"
                                    defaultValue={String(`${year}-12-31`)}
                                    ref={dateFin}/>
                            </div>
                        </div>

                        <div className="col-md-2">
                            <div className='d-flex align-items-center position-relative my-1 ms-2'>
                                <button
                                    className="btn btn-success btn-sm font-weight-bold mr-2"
                                    onClick = {() => filterByDate()}>
                                        Valider
                                </button>
                            </div>
                        </div>
                        </div>

                    </ListGroup.Item>
                </ListGroup>

                {
                    loadingStat ? <LoadingAnimation className={`mb-4`}/> :

                    <>

                        <div className='m-5'>
                            <b>Montant total de vente:</b> { stats.totalVente ? <> <Currency value={stats.totalVente} />  HT </> : 'NAC' }
                        </div>       
                
                        <div className="flex mb-10">
                            <div className="sales-table" style={{marginRight:'10px'}}>
                                <div className="table-wrapper">
                                    <div className="table-block">
                                        <div className='mb-5'>
                                            <strong>Chiffre d'affaire détaillé</strong>
                                        </div>
                                        <table className="table-summary">
                                            <thead>
                                                <tr className="first-column">
                                                    <th>Type</th>
                                                    <th className="currency">€ HT</th>
                                                    <th className="currency">€ TTC</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                <tr>
                                                    <td>Montant total de commission</td>
                                                    <td className="currency">{ stats.totalCommission ? <Currency value={stats.totalCommission / 1.2} /> : 'NAC' }</td>
                                                    <td className="currency">{ stats.totalCommission ? <Currency value={stats.totalCommission} /> : 'NAC' }</td>
                                                </tr>

                                                {
                                                    roleSelected !== 4 ? (
                                                        <tr>
                                                            <td>Bénéfice Moriss</td>
                                                            <td className="currency">{ stats.margeCommission ? <Currency value={stats.margeCommission} /> : 'NAC' }</td>
                                                            <td className="currency">{ stats.margeCommissionTtc ? <Currency value={stats.margeCommissionTtc} /> : 'NAC' }</td>
                                                        </tr>
                                                    ) : null
                                                }
                                            
                                            </tbody>
                                        </table>
                                    </div>

                                    
                                </div>
                            </div>

                            {
                                stats.franchise && (roleSelected === 2 || roleSelected === 3) ? (

                                    <div className="sales-table">
                                        <div className="table-wrapper">
                                            <div className="table-block">
                                                <div className='mb-5'>
                                                    <strong>Redevance Moriss</strong>
                                                </div>
                                                <table className="table-summary">
                                                    <thead>
                                                        <tr className="first-column">
                                                            <th>Somme des commissions € HT x le pourcentage de redevance Franchise</th>
                                                        </tr>
                                                    </thead>
                                                    <tbody>
                                                        <tr>
                                                            <td className="currency"><Currency value={stats.totalCommission / 1.2} /> HT x {stats.redevance} % = { stats.totalCommission ? <Link style={{display:"contents"}} to={`/biens/liste`} className={`nav-link text-active-primary pb-4`}>
                                                                <Currency value={(stats.totalCommission / 1.2) * parseFloat(stats.redevance) / 100} /> HT
                                                            </Link> : 'NAC' }</td>
                                                        </tr>
                                                    </tbody>
                                                </table>
                                            </div>

                                            
                                        </div>
                                    </div>

                                ) : null
                            }
                            

                        </div>

                    </>
                }

            </Card>

            <Card
                className="mb-5"
                title="Total des frais">

                {
                    alertFrais ? 
                        <div className="alert alert-success" role="alert">
                            <AiOutlineCloseCircle 
                                onClick={() => setAlertFrais(null)}
                                role="button" style={{fontSize: '30px', marginRight: '10px'}} 
                                className="alert-heading text-danger mb-1"
                                data-toggle="tooltip"
                                title="Fermer le texte d'aide"
                                />
                            Vous pouvez ici choisir une période qui va permettre de prendre en compte les biens immobiliers qui ont été créés dans l’outil dans cette période afin d'obtenir le total des frais.
                            <hr></hr>
                            <p className="mb-0">
                                <strong>Total des frais :</strong> Somme des montant des frais payés 
                            </p>
                        </div> : <AiOutlineInfoCircle
                                    onClick={() => setAlertFrais(1)}
                                    role="button" 
                                    style={{fontSize: '30px'}} 
                                    className="text-primary mb-5"
                                    data-toggle="tooltip"
                                    title="Afficher le texte d'aide"
                                    />

                }

                <ListGroup>
                    <ListGroup.Item>

                        <div className="previous-search d-flex align-items-center position-relative my-1 me-auto p-2 bd-highlight"
                            style={{paddingTop:'3px', paddingBottom: '3px'}}
                            >
                            <div className="search-date">
                                <div className="search-text" style={{'width': '200%'}}>Date de création des biens du</div> 
                                <input 
                                    type="date"
                                    className="form-control form-control-sm"
                                    defaultValue={String(`${year}-01-01`)}
                                    ref={dateDebutBien}
                                />
                            
                                <div className="search-text" style={{marginLeft: '5px'}}>au</div> 
                                <input
                                    type="date"
                                    className="form-control form-control-sm"
                                    defaultValue={String(`${year}-12-31`)}
                                    ref={dateFinBien}
                                />
                            </div>
                            <div className="previous-button">
                                <button
                                    className="btn btn-success btn-sm font-weight-bold mr-2"
                                    onClick = {() => filterByDateBien()}
                                >
                                    Valider
                                </button>
                            </div>
                        </div>

                    </ListGroup.Item>
                </ListGroup>

                <div className='m-5'>
                    <b>Total des frais:</b> <Currency value={total} /> HT - <Currency value={total * 1.2} /> TTC
                </div>

            </Card>
        </>
  )
};

const mapStateToProps = (state) => {
    const { user } = state.AuthReducer;
    const { roleSelected, privilegeSelected } = user;
    return {
        roleSelected,
        privilegeSelected
    }
  }
  
export default connect(mapStateToProps)(SalesDetailed);
