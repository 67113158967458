import './MenuToolbar.styles.scss';

const MenuToolbar = ({ action, title, subtitle, children }) => {

    const renderAction = () => {
        if (typeof action === "function")  {
            return action();
        }
        
        return null;
    }

    return (
        <div className="toolbar" id="kt_toolbar" style={{width: 'auto'}}>

            <div id="kt_toolbar_container" className="toolbar-container container-fluid d-flex align-items-stretch justify-content-between">

                <div className="page-title d-flex align-items-center flex-wrap me-3 mb-lg-0">
                    <div className="d-flex align-items-center">
                        <div className="d-flex align-items-center text-dark fw-bolder fs-3 my-1">
                            { title }
                        </div>
                        { subtitle ? <div className="fs-3">&nbsp; &bull; &nbsp;{ subtitle }</div> : null }
                    </div>
                    
                    <span className="h-20px border-gray-200 border-start mx-4"></span>
                    
                    { children }
                </div>

                <div className="align-items-center my-2">
                    { renderAction() }
                </div>

            </div>
            
        </div>
    )
}

export default MenuToolbar
