const errorUser = (data) =>{
    let errorList = {};
    if(data){
        let listError = data.split('\n');
        listError.map((error)=>{
            const key = error.split(': ')[0];
            const message = error.split(': ')[1];
            return errorList[key] = message;
        })
    }else{
        errorList = null;
    }
    return errorList
}

export {
    errorUser
}