import React from 'react';
import { useQuery } from '@/components/Utils';
import { FaCircleNotch } from 'react-icons/fa';

const DirecteurMultiple = ({ agenceId, setValue, directeursValues }) => {
    const { data, refetch, loading } = useQuery(`/agence/directeurs`, {
        variables: {
            agenceId
        }
    });

    const directeurs = data ?? [];

    React.useEffect(() => {
        if (agenceId) refetch();

        return () => {}
    }, [agenceId])

    if (!agenceId) return <div
        className="directeur-multiple" 
        style={{fontSize: '16px'}}>
            Veuillez choisir une agence
        </div>;

    if (loading === true) return <div
        className="directeur-multiple"
        style={{fontSize: '16px'}}>
            Chargement en cours <FaCircleNotch className="spin-quickly"/>
        </div>;

    const selectDirecteur = (checked, directeurId) => {
        // let currentValues = directeursValues.sort((a, b) => parseFloat(a.id) - parseFloat(b.id));
        let directeursList = [];

        if (directeursValues) directeursList = [...directeursValues];

        
        if (checked) {
            const indexDirecteur = directeursList.findIndex(directeur => parseInt(directeur.userId) === parseInt(directeurId));
            if (indexDirecteur === -1) {
                directeursList = [
                    ...directeursList,
                    {
                        userId: directeurId,
                        user: `/api/users/${directeurId}`
                    }
                ]
            }
        } else {
            directeursList = directeursList.filter(directeur => {
                return parseInt(directeur.userId) !== parseInt(directeurId)
            })
        }

        setValue('directeurs', directeursList, { shouldDirty: true });
    }

    const isChecked = (directeurId) => {
        const indexDirecteur = (directeursValues || []).findIndex(directeur => parseInt(directeur.userId) === parseInt(directeurId));

        return indexDirecteur >= 0;
    }

    return (
        <div className="directeur-multiple">
                {!directeurs || directeurs?.length <= 0 ? <div>Aucun directeur n'est associé à cette agence pour le moment.</div> : null }
                {directeurs && directeurs.map((directeur, index) =>
                    <label key={index} className="form-check" style={{fontSize: '16px'}}>
                        <input
                            className="form-check-input"
                            type="checkbox"
                            defaultChecked={isChecked(directeur.userId)}
                            onChange={function(event) {
                                const checked = event.target.checked;
                                selectDirecteur(checked ? true : false, directeur.userId);
                            }}/>
                            
                        <span className="form-check-label fw-bold">{directeur.nom} {directeur.prenom}</span>
                    </label>
                )}
            
        </div>
    )
}

export default DirecteurMultiple