import {
    Routes,
    Route
} from 'react-router-dom';

import Dashboard from '@/pages/Dashboard/Dashboard.page';
import UserPage from '@/pages/User/User.page';
import AgencePage from '@/pages/Agence/Agence.page';
import SocietePage from '@/pages/Societe/Societe.page';
import BienPage from '@/pages/Bien/Bien.page';
import FraisType from '@/pages/FraisType/FraisType.page';
import Provenance from '../pages/Provenance/Provenance.page';
import Commission from '../pages/Commission/Commission.page';
import Frais from '../pages/Frais/Frais.page';
import Performance from '../pages/Performance/Performance.page';

const routes = [
    {
        path: "/",
        element: <Dashboard />
    },
    {
      path: "/performance",
      element: <Performance />
    }
]

const PrivateRoutes = () => {
  return (
      <Routes>
        { routes.map((route, index) => <Route key={index} {...route}/>) }
        <Route path="/users/:action" element={<UserPage />}>
          <Route path="/users/:action/:userId" element={<UserPage />}/>
        </Route>

        <Route path="/agences/:action" element={<AgencePage />}>
          <Route path="/agences/:action/:agenceId" element={<AgencePage />}/>
          <Route path="/agences/:action/:agenceId/:tab" element={<AgencePage />}/>
        </Route>

        <Route path="/societes/:action" element={<SocietePage />}>
          <Route path="/societes/:action/:societeId" />
        </Route>

        <Route path="/biens/:action" element={<BienPage />}>
          <Route path="/biens/:action/:bienId" element={<BienPage />}/>
          <Route path="/biens/:action/:bienId/:tab" element={<BienPage />}/>
        </Route>

        <Route path="/commission/:action" element={<Commission />}>
          <Route path="/commission/:action/:fraisId" element={<Commission />}/>
        </Route>

        <Route path="/frais/:action" element={<Frais />}>
          <Route path="/frais/:action/:fraisId" element={<Frais />}/>
        </Route>

        <Route path="/provenances/:action" element={<Provenance />}>
          <Route path="/provenances/:action/:fraisId" element={<Provenance />}/>
        </Route>

        <Route path="/frais_type/:action" element={<FraisType />}>
          <Route path="/frais_type/:action/:fraisId" element={<FraisType />}/>
        </Route>

      </Routes>
    )
}

export default PrivateRoutes
