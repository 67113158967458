import React, { useCallback, useRef } from 'react';
import { Card } from '@/components/UI';
import ReactApexChart from 'react-apexcharts';
import { useQuery } from '@/components/Utils';
import { currencyFormat } from '@/components/UI';
import './SalesTurnover.styles.scss';
import { connect } from 'react-redux';
import { AiOutlineInfoCircle } from 'react-icons/ai';
import { AiOutlineCloseCircle } from 'react-icons/ai';

const colors = ['#0096c7', '#f48c06'];
const names = ['Montants réels encaissés', 'Prévisionnel à encaisser'];

const SalesTurnover = ({roleSelected, privilegeSelected}) => {
  const [type, setType] = React.useState(1);
  const [date, setDate] = React.useState(null);
  const [alert, setAlert] = React.useState(null);
  const startDateRef = useRef(null);
  const endDateRef = useRef(null);

  const { data, refetch } = useQuery(`/dashboard/turnover`, {
    variables: {
      type,
      date
    }
  });

  const handleFilter = useCallback(e => {
    setDate({start_date: startDateRef.current.value, end_date: endDateRef.current.value});
  }, [startDateRef, endDateRef])

  React.useEffect(() => {
    if (type || date) refetch();
  
    return () => {}
  }, [type, date, roleSelected, privilegeSelected])
  

  const series = data?.series ?? [];
  const categories = data?.categories ?? [];

  const datas = {
    series: [{
      name: names[type - 1],
      data: series
    }],
    options: {
      chart: {
        height: 350,
        type: 'area'
      },
      dataLabels: {
        enabled: false
      },
      colors: [colors[type - 1]],
      stroke: {
        curve: 'smooth'
      },
      xaxis: {
        categories: categories,
      },
      yaxis: {
        labels: {
          formatter: function (value) {
            return currencyFormat(value) + " €";
          }
        },
      },
      tooltip: {
        x: {
          // format: 'dd/MM/yy HH:mm'
        },
      },
    },
  }

  return (
        <Card
            title="Evolution du Chiffre d'affaire HT"
            className="mb-4 sales-turnover">

            {
              alert ? 
                <div className="alert alert-success" role="alert">
                  <AiOutlineCloseCircle 
                    onClick={() => setAlert(null)}
                    role="button" style={{fontSize: '30px', marginRight: '10px'}} 
                    className="alert-heading text-danger mb-1"
                    data-toggle="tooltip"
                    title="Fermer le texte d'aide"
                    />
                  Vous pouvez dans ce graphique choisir une période afin d'obtenir les montants de commissions qui prennent en compte tous les biens immobiliers concernés.
                  <hr></hr>
                  <p className="mb-1">
                    <strong>Commission réelle :</strong> Affiche la somme des commissions des 6 derniers mois passés. Une commission est prise en compte si état = « Acte définitif signé ». On se base sur la date de signature réelle.
                    <p><i className='alert-success'>A noter que le montant doit être le même que sur le bloc « Chiffre d’affaire détaillé » au niveau de la ligne « Montant total de commission » si on filtre sur la même période et qu’on indique « Acte définitif signé ».</i></p>
                  </p>
                  <p className="mb-0">
                    <strong>Commission prévisionnelle :</strong> Affiche la somme des commissions des 6 derniers mois passés et les 6 prochains mois. Une commission est prise en compte si état non encore sur « Acte définitive signé ». On se base sur la date de signature prévisionnelle
                  </p>
                </div> : <AiOutlineInfoCircle
                            onClick={() => setAlert(1)}
                            role="button" 
                            style={{fontSize: '30px'}} 
                            className="text-primary mb-5"
                            data-toggle="tooltip"
                            title="Afficher le texte d'aide"
                            />

            }

            <nav className={`nav nav-tabs type-${type}`}>
              <div className={`nav-link type-${type} ${type === 1 ? `active` : ``}`} onClick={() => setType(1)}>Commission réelle</div>
              <div className={`nav-link type-${type} ${type === 2 ? `active` : ``}`} onClick={() => setType(2)}>Commission prévisionnelle</div>
            </nav>
            <div className="dashboard-result">
                <div className="previous-result">
                    <div className="previous-title">
                      {/* <p className="title-text">Période passée</p> */}
                    </div>
                    <div className="previous-block">
                        <div className="previous-subtitle">
                            <h4>Montants de commissions HT</h4>
                        </div>
                        <div className="previous-search">
                            <div className="search-date">
                                <div className="search-text">du</div> 
                                <div><input type="date" ref={startDateRef} className="form-control"/></div>
                            </div>
                            <div className="search-date">
                                <div className="search-text">au</div> 
                                <div><input type="date" ref={endDateRef} className="form-control"/></div>
                            </div>
                            <div className="previous-button">
                                <button className="btn btn-success btn-sm font-weight-bold mr-2" onClick={handleFilter}>Valider</button>
                            </div>
                        </div>

                    </div>
                </div>
                {/* <div id="chart">
                    <ApexChart options={state.options} series={state.series} type="bar" height={350} />
                </div> */}
                {/* <div className="forecast-result">
                    <div className="forecast-title">
                        <h4>Prévisionnel à venir</h4>
                    </div>
                </div>           */}

                <div id="chart">
                    <ReactApexChart options={datas.options} series={datas.series} type="area" height={350} />
                </div>
            </div>
        </Card>
  );
};

const mapStateToProps = (state) => {
  const { user } = state.AuthReducer;
  const { roleSelected, privilegeSelected } = user;
  return {
      roleSelected,
      privilegeSelected
  }
}

export default connect(mapStateToProps)(SalesTurnover);
