import './Auth.styles.scss';
import { connect } from 'react-redux';
import { userLoginAttempt } from '@/redux/Auth/auth.action.js';
import { assets } from '@/_metronic/helpers';
import { useForm } from "react-hook-form";
import { FaSpinner } from 'react-icons/fa';

const Auth = ({ userLogin, isAuthenticating, error }) => {
    const { register, handleSubmit, formState: { errors } } = useForm();

    const onSubmit = ({ username, password }) => {

        userLogin(username, password);
    }

    return (
        <div className="auth-page">
            <div className="auth-form">
                <form onSubmit={handleSubmit(onSubmit)}>
                    <div className="logo-wrapper mb-2">
                        <img src={assets(`logo.png`)} alt={`Moriss logo`}/>
                    </div>

                    <div className="text-center fs-1 my-4">
                        Connexion à l'administration
                    </div>

                    <div className="form-group">
                        <label className="form-label">Nom d'utilisateur : </label>
                        <input
                            className="form-control"
                            placeholder="Nom d'utilisateur"
                            {...register('username', { required: true })}
                            type="text"/>
                        { errors && errors?.username ? <div className="error-message">Veuillez saisir votre nom d'utilisateur</div> : null }
                    </div>
                    

                    <div className="form-group">
                        <label className="form-label">Mot de passe : </label>
                        <input
                            className="form-control"
                            placeholder="Mot de passe"
                            {...register('password', { required: true })}
                            type="password"/>
                        { errors && errors?.password ? <div className="error-message">Veuillez saisir votre mot de passe</div> : null }
                    </div>
                    

                    <div className="form-group">
                        <button
                            disabled={isAuthenticating}
                            className="btn btn-primary"
                            style={{width: `100%`}}
                            type="submit">
                            { !isAuthenticating ? `Se connecter` : (<span><FaSpinner className="spin-animation"/> Connexion en cours</span>) }
                        </button>
                    </div>
                    { error ? (<div className="text-center" style={{color: 'red'}}>{ error }</div>) : null }
                </form>
            </div>

            <div className="auth-background" style={{backgroundImage: `url(${assets('auth-bg.jpg')})`}}></div>
        </div>
    )
}

const mapStateToProps = (state) => {
    const { error, isAuthenticating } = state.AuthReducer;
    return {
        isAuthenticating,
        error
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        userLogin: (username, password) => dispatch(userLoginAttempt(username, password))
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(Auth);
