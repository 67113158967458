import { Link } from 'react-router-dom'
import AgencePrivileges from './partials/AgencePrivileges/AgencePrivileges.partial';
import AgenceUpdate from './partials/AgenceUpdate/AgenceUpdate';

import { useGet } from '@/components/Utils';
import { FaSpinner } from 'react-icons/fa';
import './AgenceDetail.styles.scss';

const AgenceDetail = (props) => {
    const { agenceId, tab } = props;
    const { data, loading } = useGet(`/agences/${agenceId}`); // loading

    const handleActive = (activeTab = undefined) => {
        if (activeTab === tab) 
            return `active`;

        return ``;
    }

    const agenceDetail = data;

    const handleTab = () => {
        switch (tab) {
            case 'directeurs':
                return <AgencePrivileges {...props} agenceDetail={agenceDetail}/>;

            case 'negociateurs':
                return <AgencePrivileges {...props} agenceDetail={agenceDetail}/>;

            default:
                return <AgenceUpdate {...props} agenceDetail={agenceDetail}/>;
        }
    }

    return (
        <div className="agence-detail">
            <ul className="nav nav-custom nav-tabs nav-line-tabs nav-line-tabs-2x border-0 fs-4 fw-bold mb-8">
                <li className="nav-item">
                    <Link
                        to={`/agences/update/${agenceId}`}
                        className={`nav-link text-active-primary pb-4 ${handleActive()}`}>Information de l'agence</Link>
                </li>

                <li className="nav-item">
                    <Link
                        to={`/agences/update/${agenceId}/directeurs`} 
                        className={`nav-link text-active-primary pb-4 ${handleActive('directeurs')}`}>Les directeurs</Link>
                </li>

                <li className="nav-item">
                    <Link
                        to={`/agences/update/${agenceId}/negociateurs`}
                        className={`nav-link text-active-primary pb-4 ${handleActive('negociateurs')}`}>Les négociateurs</Link>
                </li>
            </ul>

            <div className="relative">
                { handleTab() }

                { loading ? 
                <div className="cover-relative flex bg-white justify-center items-center text-primary fs-4" style={{minHeight: '100px'}}>Chargement en cours <FaSpinner className="spin-animation"/></div> : null}
            </div>
        </div>);
}

export default AgenceDetail
