const initialState = {
    roles: [],
    roleLoading: false,
    roleError: false
}

const RoleReducer = (state = initialState, action) => {
    switch (action.type) {
        case 'ROLE_GET_SUCCESS':
            return {
                ...state,
                roles: action.roles
            }
            
        default: 
            return state
    }
}

export default RoleReducer;