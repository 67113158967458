import React from 'react';
import { MenuToolbar } from '@/routes';
import { Link } from 'react-router-dom';

import { MdOutlineAttachEmail } from 'react-icons/md';
import { FaPlus, FaRegEdit, FaRegTimesCircle } from 'react-icons/fa';
import { AiOutlineAudit } from 'react-icons/ai';

import { DataTable, Card, Swal, Currency, EmailSender } from '@/components/UI';
import { useGet, useHydra, DateFormat } from '@/components/Utils';
import { Modal } from 'react-bootstrap-v5';
import { publicAssets } from '@/_metronic/helpers';

import FraisAdd from './FraisAdd.form';
import './BienFrais.styles.scss';
import axios from '@/axios';

const BienFrais = ({ bienDetail }) => {
    
    const [show, toggleShow] = React.useState(false);
    const [email, toggleEmail] = React.useState(null);
    const [bienFrais, setBienFrais] = React.useState(false);



    const { data, loading, refetch } = useGet(`/bien_frais?bien=${bienDetail?.id}`, {
        headers: {
            'Accept': 'application/ld+json'
        }
    });

    let { rows } = useHydra(data, null);

    const handleSelect = (value) => {
        setBienFrais(value);
        toggleShow(true);
    }

    const handleRemove = ({ id, frais }) => {
        Swal.fire({
            title: `<span style="color: red">Supprimer le frais de ${frais?.nom}?</span>`,
            icon: 'warning',
            html: `Voulez-vous réellement supprimer le frais de ${frais?.nom}?`,
            showCloseButton: false,
            showCancelButton: true,
            focusConfirm: false,
            confirmButtonText: 'Oui',
            confirmButtonColor: 'red',
            cancelButtonText: 'Annuler'
        }).then(result => {
            if (result.isConfirmed) {
                axios.patch(`/bien_frais/${id}`, {
                    isDeleted: 1
                }, {
                    headers: {
                        "Accept": "application/json",
                        "Content-Type": "application/merge-patch+json"
                    }
                }).then(() => refetch())
            }
        })
    }

    const handleEmail = (value) => {
        toggleEmail(value);
    }

    const updateReglerVendeur = (id, checked, target) => {
        const parent = target.parentElement;
        const textElem = parent.querySelector('.regler-vendeur-text');

        textElem.textContent = checked ? `Oui` : `Non`;

        axios.patch(`/bien_frais/${id}`, {
            reglerVendeur: checked ? 1 : -1
        }, {
            headers: {
                "Accept": "application/json",
                "Content-Type": "application/merge-patch+json"
            }
        }).then(() => {
            textElem.textContent = checked ? `Oui` : `Non`;
            refetch();
        })
    }

    const initMontantTtc = (montantHt) => {
        const ht = parseFloat(montantHt ?? 0);
        const tva = ht * (20/100);
        return ht + tva;
    }

    const columns = [{
        name: 'icone',
        label: '',
        component: ({ frais }) => 
        <div className="frais-column">
            <div>
                <img src={publicAssets(frais.icone)} alt={frais.icone}  width={30} height={30}/>
            </div>
            <div>{ frais.nom }</div>
        </div>
    }, {
        name: 'montantHt',
        label: 'Montant € HT',
        component: ({ montantHt }) => <Currency value={montantHt}/>
    }, {
        name: 'montanTtc',
        label: 'Montant € TTC',
        component: ({ montantHt }) => <Currency value={initMontantTtc(montantHt)}/>
    }, {
        name: 'dateReglement',
        label: 'Date de la prestation',
        component: ({ dateReglement }) => DateFormat(dateReglement)
    },
    // ,{
    //     label: 'A régler par le vendeur',
    //     component: ({ id, reglerVendeur }) => 
    //     <div className="form-check">
    //         <label className="form-check-label">
    //             <input
    //                 className="form-check-input"
    //                 type="checkbox"
    //                 defaultChecked={reglerVendeur === 1}
    //                 onChange={(event) => {
    //                     const checked = event.target.checked;
    //                     return updateReglerVendeur(id, checked, event.target);
    //                 }}/>
    //             <span className="regler-vendeur-text">{ reglerVendeur === 1 ? `Oui` : `Non` }</span>
    //         </label>
    //     </div>,
    //     style: {
    //         textAlign: 'center'
    //     },
    //     styles: {
    //         textAlign: 'center'
    //     },
    // }
    {
        name: 'categorie',
        label: 'Pris en charge financier du frais',
        styles: {
            width: '300px'
        },
        component: ({ categorie }) => categorie && categorie?.name ? categorie?.name : `Aucun`
    }, {
        name: 'facture',
        label: 'Facture',
        component: ({ facture }) => facture && facture?.filePath ? <a href={publicAssets(facture?.filePath)} target="_blank" rel="noreferrer">Télécharger</a> : `N/A`
    }, {
        name: 'commentaire',
        label: 'Commentaire',
        styles: {
            width: '175px'
        }
    }, {
        label: 'Action',
        component: (frais) => {
            const proprietaire = bienDetail.proprietaire;
            const email = proprietaire.email;
            return <div style={{fontSize: '18px'}}>
                        { frais && frais.facture ? <MdOutlineAttachEmail
                            style={{cursor: 'pointer', color: 'blue'}}
                            onClick={() => handleEmail({
                                email,
                                attachment: frais.facture
                            })}/> 
                        : null }

                        <FaRegEdit
                            className="ms-4 text-primary"
                            style={{cursor: 'pointer'}}
                            onClick={() => handleSelect(frais)}/>
                        
                        <FaRegTimesCircle
                            className="ms-4 text-danger"
                            style={{cursor: 'pointer'}}
                            onClick={() => handleRemove(frais)}/>
                    </div>
        }
    }]

    const getSummary = () => {
        // const reducer = (previousValue, currentValue) => previousValue + parseFloat(currentValue.montantHt);
        let total = 0.0;
        let offertVendeur = 0.0;
        let avanceVendeur = 0.0;

        rows.map(row => {
            total = total + parseFloat(row.montantHt);

            if (row.categorie && row.categorie.id === 1) {
                avanceVendeur = avanceVendeur + parseFloat(row.montantHt);
            } else {
                offertVendeur = offertVendeur + parseFloat(row.montantHt);
            }

            return total;
        });

        return {
            total,
            offertVendeur,
            avanceVendeur
        }
    }

    const summary = getSummary();

    return (
        <>
            <div className="bien-frais">
                <Modal
                    show={show}
                    centered
                    onHide={() => {
                        toggleShow(false);
                        setBienFrais(null);
                    }}
                    dialogClassName="mw-900px">
                    <Modal.Header>
                        <Modal.Title id="contained-modal-title-vcenter">
                            <span className={bienFrais ? `text-warning` : `text-primary`}>{ bienFrais ? `Edition du frais de ${bienFrais?.frais?.nom}` : `Ajout d'un frais au bien immobilier` }</span>
                        </Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <FraisAdd
                            bienFrais={bienFrais}
                            withBien={true}
                            onSuccess={() => {
                                toggleShow(false);
                                refetch();
                            }}
                            bienId={bienDetail?.id}/>
                    </Modal.Body>
                </Modal>

                <MenuToolbar
                    title={`Modification bien immobilier`}
                    subtitle={`Frais #${bienDetail.id}`}
                    action={null}>
                    <ul className="breadcrumb breadcrumb-separatorless fw-bold fs-7 my-1">
                        <li className="breadcrumb-item text-muted">
                            <Link to="/biens/liste" className="text-muted text-hover-primary">Liste des biens immobiliers</Link>
                        </li>
                        <li className="breadcrumb-item">
                            <span className="bullet bg-gray-200 w-5px h-2px"></span>
                        </li>
                    </ul>
                </MenuToolbar>
                
                <div className="row">
                    <div className="col-md-12">
                        <Card
                            title={`Les frais liés à cette vente`}
                            titleIcon={<AiOutlineAudit />}
                            titleColor={`#0984e3`}>
                            <button
                                className="btn btn-sm btn-primary"
                                onClick={() => toggleShow(true)}
                            >
                                <FaPlus /> Nouveau frais
                            </button>

                            <DataTable
                                className="frais-datatable"
                                classique={true}
                                columns={columns}
                                loading={loading}
                                rows={rows}/>

                            { loading === false ? (
                                <>
                                    <div className="my-4 fw-bold text-danger fs-4">
                                        Total des frais : {summary.total} € HT 
                                    </div>


                                    <div className="flex fs-5">
                                        <div className="px-4">
                                            <div>- Offert au vendeur : {summary.offertVendeur} € HT - <Currency value={initMontantTtc(summary.offertVendeur)}></Currency> TTC</div>
                                            {/* <div>- Offert à l'acquéreur : [xx] € HT</div> */}
                                            {/* <div>==&gt; Total des frais offerts : [xx] €HT</div> */}
                                        </div>
                                        <div className="px-4">
                                            <div>- Avancée au vendeur : {summary.avanceVendeur} € HT</div>
                                            {/* <div>- Avancée à l'acquéreur : [xx] € HT</div> */}
                                            {/* <div>==&gt; Total des frais avancées : [xx] €HT</div> */}
                                        </div>
                                    </div>
                                </>) : 
                            null }
                            
                        </Card>
                    </div>
                </div>
            </div>

            <EmailSender
                data={email ? {
                    ...email,
                    object: "[Moriss] Facture à régler",
                    message: `Bonjour,\n\nMorris vous adresse la facture en fichier joint et vous remercie par avance pour votre aimable règlement.\n\nCordialement,\n\nL'équipe Moriss Immobilier`
                } : false}
                onClose={() => toggleEmail(null)}/>
        </>
        
    )
}

export default BienFrais
