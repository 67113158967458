import PrivilegeForm from "./PrivilegeForm";
import { RiUser2Fill } from 'react-icons/ri';

const AgenceDirecteur = ({ agenceDetail }) => {
    return <PrivilegeForm
                label={`directeur`}
                title={`Les directeurs`}
                titleColor={`#227093`}
                titleIcon={<RiUser2Fill />}
                roleId={3}
                agenceDetail={agenceDetail}/>
}

export default AgenceDirecteur
