// Onglet information du bien
const informations = [{
    name: "titreAnnonce",
    label: "Titre de l'annonce *",
    params: { required: true }
}]

const adresses = [{
    name: "adressePrimaire",
    label: "Adresse ligne 1 *",
    params: { required: true }
}, {
    name: "adresseSecondaire",
    label: "Adresse ligne 2"
}];

const proprietaires = [{
    name: "proprietaire.email",
    label: "Email"
}, {
    name: "proprietaire.telephone",
    label: "Téléphone *",
    params: { required: true }
}];

const acquereurs = [{
    name: "acquereur.email",
    label: "Email"
}, {
    name: "acquereur.telephone",
    label: "Téléphone"
}];

// Onglet suivi & commercialisation


export {
    informations,
    adresses,
    proprietaires,
    acquereurs
}