import React from 'react';
import { NumericFormat } from 'react-number-format';

const CustomizedInput = React.forwardRef(({
    readOnly,
    className,
    placeholder,
    decimalSeparator = ".",
    defaultValue,
    decimalScale= 2,
    suffix = " %",
    onChange,
    disabled
  }, ref) => {

  const handleChange = (value) => {
    if (typeof onChange === 'function') onChange(value.value);
  }

  return <NumericFormat
    readOnly={readOnly}
    getInputRef={ref}
    className={className}
    placeholder={placeholder}
    defaultValue={defaultValue}
    decimalScale={decimalScale}
    decimalSeparator={decimalSeparator}
    onValueChange={handleChange}
    suffix={suffix}
    disabled={disabled}
  />;
})

export default CustomizedInput
