const informations = [{
    name: "username",
    label: "Identifiant",
    params: { required: true }

}, {
    name: "email",
    label: "Email",
    type: "email",
    params: { required: true }
}, {
    name: "telephone",
    label: "Téléphone",
    params: { required: true }
}]

export {
    informations
}