import './AgenceItem.styles.scss';
import { FaTimes } from 'react-icons/fa';

const AgenceItem = ({ nom, adressePrimaire, remove }) => {

    return (
        <>
            <tr>
                <td>
                    <span className="text-dark fw-bolder text-hover-primary mb-1 fs-6">{ nom }</span>
                    <span className="text-muted fw-bold d-block">{ adressePrimaire }</span>
                </td>
                <td className="text-end">
                    <span
                        onClick={() => remove()}
                        className="btn btn-sm btn-icon btn-bg-light btn-danger">
                        <FaTimes />
                    </span>
                </td>
            </tr>
        </>
    )
}

export default AgenceItem
