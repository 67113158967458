import React from 'react';
import './SalesNegociateurs.style.scss';
import ReactApexChart from 'react-apexcharts';
import { Card } from '@/components/UI';
import { useGet } from '@/components/Utils';
import { currencyFormat } from '@/components/UI';
// import { FaSpinner } from 'react-icons/fa';
import { connect } from 'react-redux';
import FilterByAgence from '@/components/Filter/FilterByAgence/FilterByAgence.component';
import { useFilter } from '@/components/Utils';
import { AiOutlineCloseCircle, AiOutlineInfoCircle } from 'react-icons/ai';
import { LoadingAnimation } from '../../../../components/UI';

const SalesNegociateurs = ({ roleSelected, privilegeSelected }) => {
  const [year, setYear] = React.useState(new Date().getFullYear());
  let [params, query] = useFilter();
  const [alert, setAlert] = React.useState(null);
  const [alertCom, setAlertCom] = React.useState(null);

  query = query === '' ? '?year='+year : query+'&year='+year;

  const { data, loading, refetch } = useGet(`/dashboard/negociateurs${query}`);

  sessionStorage.setItem("agence", params.agence);

  const { data: dataBenefice, loading: loadingBenefice, refetch: refetchBenefice} = useGet(`/dashboard/negociateurs${query}&benefice=true`);

  const { data: yearData } = useGet('/dashboard/years');
  const years = yearData?.years;

  React.useEffect(() => {
    refetch();
    refetchBenefice();

    return () => {}
  }, [roleSelected, privilegeSelected, year]);

  const negociateurs = data?.negociateurs ?? [];
  const categories = data?.categories ?? [];
  const series = data?.series ?? [];
  
  const negociateursBenefice = dataBenefice?.negociateurs ?? [];
  const seriesBenefice = dataBenefice?.series ?? [];

  const datas = {
    series: [{
      name: 'Chiffre d\'affaire',
      data: series
    }],
    options: {
      chart: {
        type: 'bar',
        height: 350
      },
      plotOptions: {
        bar: {
          horizontal: false,
          columnWidth: '55%',
          endingShape: 'rounded'
        },
      },
      dataLabels: {
        enabled: false
      },
      stroke: {
        show: true,
        width: 2,
        colors: ['transparent']
      },
      xaxis: {
        title: {
          text: 'Négociateurs'
        },
        categories: categories,
      },
      yaxis: {
        title: {
          text: '€'
        }
      },
      fill: {
        opacity: 1
      },
      tooltip: {
        y: {
          formatter: function (val) {
            return val + " €" 
          }
        }
      }
    }
  }

  const datasBenefice = {
    series: [{
      name: 'Chiffre d\'affaire',
      data: seriesBenefice
    }],
    options: {
      chart: {
        type: 'bar',
        height: 350
      },
      plotOptions: {
        bar: {
          horizontal: false,
          columnWidth: '55%',
          endingShape: 'rounded'
        },
      },
      dataLabels: {
        enabled: false
      },
      stroke: {
        show: true,
        width: 2,
        colors: ['transparent']
      },
      xaxis: {
        title: {
          text: 'Négociateurs'
        },
        categories: categories,
      },
      yaxis: {
        title: {
          text: '€'
        }
      },
      fill: {
        opacity: 1
      },
      tooltip: {
        y: {
          formatter: function (val) {
            return val + " €" 
          }
        }
      }
    }
  }

  const reCountNego = Math.round((negociateurs?.length / 3));
  const reCountNegoBenefice = Math.round((negociateursBenefice?.length / 3));

  return (
      <Card
        title={`Année ${year} > Chiffre d'affaire par négociateurs`}
        className="mb-4">
        
        <div>
          {/* Filtre de la table */}

          <div className="negociateurs-sales">
              <div className="negociateurs-block">
                <p className="negociateurs-description">Bénéfice HT par négociateurs</p>

                <div style={{'marginRight': '10px'}}>
                  <FilterByAgence
                      onChange={(value) => params.updates({
                          agence: value
                      })}/>
                </div>

                <div className="select-block">
                    {
                      years &&
                        <select
                            defaultValue={year}
                            className="form-select"
                            aria-label="Default select example"
                            onChange={(event) => setYear(event.target.value)}>
                            {years.map((y, index) =>
                                (<option key={index} value={y.year}>{y.year}</option>))}
                        </select>
                    }
                  </div>
              </div>
  

          </div>

          {
            alert ? 
                <div className="alert alert-success" role="alert">
                    <AiOutlineCloseCircle 
                        onClick={() => setAlert(null)}
                        role="button" style={{fontSize: '30px', marginRight: '10px'}} 
                        className="alert-heading text-danger mb-1"
                        data-toggle="tooltip"
                        title="Fermer le texte d'aide"
                        />
                    Présente la somme des commissions par négociateurs pour une année donnée. Pour prendre en compte la commission d’un bien immobilier donné, il faut que « date de signature réelle » de ce bien se situe sur l’année choisie. Cela prend en compte les commissions indiquées dans le bordereau et quelque soit l’état du bordereau.
                </div> : <AiOutlineInfoCircle
                            onClick={() => setAlert(1)}
                            role="button" 
                            style={{fontSize: '30px'}} 
                            className="text-primary mb-5"
                            data-toggle="tooltip"
                            title="Afficher le texte d'aide"
                            />

          }

          {/* Chart et summary */}

          { loading ? <LoadingAnimation className={`mb-4`}/> :
            <div className="negociateurs-wrapper">
              <div className="negociateurs-chart">
                <div>
                  <ReactApexChart options={datas.options} series={datas.series} type="bar" height={350} />
                </div>
              </div>

              {
                negociateurs.length > 50 ?
                  <div className="row">
                    <div className="col-md-12 col-lg-6 col-xl-4">
                      
                      <div className="negociateurs-table w-100" style={{marginRight: '20px'}}>
                        <table>
                          <thead>
                            <tr className="first-column">
                              <th>Nom du négociateur</th>
                              <th>Inter cabinet</th>
                              <th className="currency">€ HT</th>
                            </tr>
                          </thead>
                          <tbody>
                            {!negociateurs || negociateurs.length <= 0 ? (<tr><td colSpan={2}>Aucun résultat n'est disponible</td></tr>) : null}
                            {negociateurs && negociateurs.map((negociateur, index)=>

                              (
                                index < reCountNego &&  
                                <tr key={index}>
                                  <td>{negociateur.nom} {negociateur.prenom}</td>
                                  <td>{negociateur.nbrCabinet}</td>
                                  <td className="currency">{currencyFormat(negociateur.chiffreAffaire)}</td>
                                </tr>
                              )
                            
                            )}
                          </tbody>
                        </table>
                      </div>
                    </div>

                    <div className="col-md-12 col-lg-6 col-xl-4">
                      {
                        negociateurs[reCountNego] && 
                        <div className="negociateurs-table w-100" style={{marginRight: '20px'}}>
                          <table>
                            <thead>
                              <tr className="first-column">
                                <th>Nom du négociateur</th>
                                <th>Inter cabinet</th>
                                <th className="currency">€ HT</th>
                              </tr>
                            </thead>
                            <tbody>
                              {!negociateurs || negociateurs.length <= 0 ? (<tr><td colSpan={2}>Aucun résultat n'est disponible</td></tr>) : null}
                              {negociateurs && negociateurs.map((negociateur, index) =>

                                (
                                  (index > reCountNego && index < (reCountNego * 2) + 1) &&  
                                  <tr key={index}>
                                    <td>{negociateur.nom} {negociateur.prenom}</td>
                                    <td>{negociateur.nbrCabinet}</td>
                                    <td className="currency">{currencyFormat(negociateur.chiffreAffaire)}</td>
                                  </tr>
                                )
                              
                              )}
                            </tbody>
                          </table>
                        </div>
                      }

                    </div>

                    <div className="col-md-12 col-lg-6 col-xl-4">
                      
                      {
                        negociateurs[(reCountNego * 2) + 1] &&

                        <div className="negociateurs-table w-100">
                          <table>
                            <thead>
                              <tr className="first-column">
                                <th>Nom du négociateur</th>
                                <th>Inter cabinet</th>
                                <th className="currency">€ HT</th>
                              </tr>
                            </thead>
                            <tbody>
                              {!negociateurs || negociateurs.length <= 0 ? (<tr><td colSpan={2}>Aucun résultat n'est disponible</td></tr>) : null}
                              {negociateurs && negociateurs.map((negociateur, index)=>

                                (
                                  (index > (reCountNego * 2)) &&  
                                  <tr key={index}>
                                    <td>{negociateur.nom} {negociateur.prenom}</td>
                                    <td>{negociateur.nbrCabinet}</td>
                                    <td className="currency">{currencyFormat(negociateur.chiffreAffaire)}</td>
                                  </tr>
                                )
                              
                              )}
                            </tbody>
                          </table>
                        </div>
                      }

                    </div>

                  </div> :
                  <div className="row">
                    <div className="col-md-12 col-lg-12 col-xl-6">
                      
                      <div className="negociateurs-table w-100" style={{marginRight: '20px'}}>
                        <table>
                          <thead>
                            <tr className="first-column">
                              <th>Nom du négociateur</th>
                              <th>Inter cabinet</th>
                              <th className="currency">€ HT</th>
                            </tr>
                          </thead>
                          <tbody>
                            {!negociateurs || negociateurs.length <= 0 ? (<tr><td colSpan={2}>Aucun résultat n'est disponible</td></tr>) : null}
                            {negociateurs && negociateurs.map((negociateur, index)=>

                              <tr key={index}>
                                <td>{negociateur.nom} {negociateur.prenom}</td>
                                <td>{negociateur.nbrCabinet}</td>
                                <td className="currency">{currencyFormat(negociateur.chiffreAffaire)}</td>
                              </tr>
                            
                            )}
                          </tbody>
                        </table>
                      </div>
                    </div>
                  </div>
              }


            </div>
          }
          

        </div>

        {/*/////////////////////////////////////////////////////////////////////////////////////////////////////////*/}
        
        <div className='mt-10'>
          {/* Filtre de la table */}
          <div className="negociateurs-sales">
              <div className="negociateurs-block">
                <p className="negociateurs-description">Commission HT par négociateurs</p>

                <div style={{'marginRight': '10px'}}>
                  <FilterByAgence
                      onChange={(value) => params.updates({
                          agence: value
                      })}/>
                </div>
                
                <div className="select-block">
                  {
                    years && 
                      <select
                          defaultValue={year}
                          className="form-select"
                          aria-label="Default select example"
                          onChange={(event) => setYear(event.target.value)}>
                          {years.map((y, index) =>
                              (<option key={index} value={y.year}>{y.year}</option>))}
                      </select>
                  }
                  </div>
              </div>
  

          </div>

          {
            alertCom ? 
                <div className="alert alert-success" role="alert">
                    <AiOutlineCloseCircle 
                        onClick={() => setAlertCom(null)}
                        role="button" style={{fontSize: '30px', marginRight: '10px'}} 
                        className="alert-heading text-danger mb-1"
                        data-toggle="tooltip"
                        title="Fermer le texte d'aide"
                        />
                    Présente la somme des montants de commissions (« Montant de la commission sur vente HT ») des biens immobiliers divisé par le nombre de négociateurs concernés. Pour prendre en compte la commission d’un bien immobilier donné, il faut que « date de signature réelle » de ce bien se situe sur l’année choisie.
                </div> : <AiOutlineInfoCircle
                            onClick={() => setAlertCom(1)}
                            role="button" 
                            style={{fontSize: '30px'}} 
                            className="text-primary mb-5"
                            data-toggle="tooltip"
                            title="Afficher le texte d'aide"
                            />

          }

          {/* Chart et summary */}

          { loadingBenefice ? <LoadingAnimation className={`mb-4`}/> :
          
            <div className="negociateurs-wrapper">
              <div className="negociateurs-chart">
                <div>
                  <ReactApexChart options={datasBenefice.options} series={datasBenefice.series} type="bar" height={350} />
                </div>
              </div>

              {
                negociateursBenefice.length > 50 ?
                  <div className="row">
                    <div className="col-md-12 col-lg-6 col-xl-4">
                      
                      <div className="negociateurs-table w-100"  style={{marginRight: '20px'}}>
                        <table>
                          <thead>
                            <tr className="first-column">
                              <th>Nom du négociateur</th>
                              <th>Inter cabinet</th>
                              <th className="currency">€ HT</th>
                            </tr>
                          </thead>
                          <tbody>
                            {!negociateursBenefice || negociateursBenefice.length <= 0 ? (<tr><td colSpan={2}>Aucun résultat n'est disponible</td></tr>) : null}
                            {negociateursBenefice && negociateursBenefice.map((negociateur, index)=>

                              (
                                index < reCountNegoBenefice &&  
                                  <tr key={index}>
                                    <td>{negociateur.nom} {negociateur.prenom}</td>
                                    <td>{negociateur.nbrCabinet}</td>
                                    <td className="currency">{currencyFormat(negociateur.chiffreAffaire)}</td>
                                  </tr>
                              )
                            )}
                          </tbody>
                        </table>
                      </div>
                    </div>

                    <div className="col-md-12 col-lg-6 col-xl-4">
                      {
                        negociateursBenefice[reCountNegoBenefice] &&
                          <div className="negociateurs-table w-100"  style={{marginRight: '20px'}}>
                            <table>
                              <thead>
                                <tr className="first-column">
                                  <th>Nom du négociateur</th>
                                  <th>Inter cabinet</th>
                                  <th className="currency">€ HT</th>
                                </tr>
                              </thead>
                              <tbody>
                                {!negociateursBenefice || negociateursBenefice.length <= 0 ? (<tr><td colSpan={2}>Aucun résultat n'est disponible</td></tr>) : null}
                                {negociateursBenefice && negociateursBenefice.map((negociateur, index)=>

                                  (
                                    (index > reCountNegoBenefice && index < (reCountNegoBenefice * 2) + 1) &&   
                                      <tr key={index}>
                                        <td>{negociateur.nom} {negociateur.prenom}</td>
                                        <td>{negociateur.nbrCabinet}</td>
                                        <td className="currency">{currencyFormat(negociateur.chiffreAffaire)}</td>
                                      </tr>
                                  )
                                )}
                              </tbody>
                            </table>
                          </div>
                      }

                    </div>

                    <div className="col-md-12 col-lg-6 col-xl-4">
                    
                      {
                        negociateursBenefice[(reCountNegoBenefice * 2) + 1] &&
                        <div className="negociateurs-table w-100">
                            <table>
                              <thead>
                                <tr className="first-column">
                                  <th>Nom du négociateur</th>
                                  <th>Inter cabinet</th>
                                  <th className="currency">€ HT</th>
                                </tr>
                              </thead>
                              <tbody>
                                {!negociateursBenefice || negociateursBenefice.length <= 0 ? (<tr><td colSpan={2}>Aucun résultat n'est disponible</td></tr>) : null}
                                {negociateursBenefice && negociateursBenefice.map((negociateur, index)=>

                                  (
                                    (index > (reCountNegoBenefice * 2)) &&   
                                      <tr key={index}>
                                        <td>{negociateur.nom} {negociateur.prenom}</td>
                                        <td>{negociateur.nbrCabinet}</td>
                                        <td className="currency">{currencyFormat(negociateur.chiffreAffaire)}</td>
                                      </tr>
                                  )
                                )}
                              </tbody>
                            </table>
                          </div>
                      }
                    </div>

                  </div> :
                  <div className="row">
                    <div className="col-md-12 col-lg-12 col-xl-6">
                      
                      <div className="negociateurs-table w-100"  style={{marginRight: '20px'}}>
                        <table>
                          <thead>
                            <tr className="first-column">
                              <th>Nom du négociateur</th>
                              <th>Inter cabinet</th>
                              <th className="currency">€ HT</th>
                            </tr>
                          </thead>
                          <tbody>
                            {!negociateursBenefice || negociateursBenefice.length <= 0 ? (<tr><td colSpan={2}>Aucun résultat n'est disponible</td></tr>) : null}
                            {negociateursBenefice && negociateursBenefice.map((negociateur, index)=>
                                <tr key={index}>
                                  <td>{negociateur.nom} {negociateur.prenom}</td>
                                  <td>{negociateur.nbrCabinet}</td>
                                  <td className="currency">{currencyFormat(negociateur.chiffreAffaire)}</td>
                                </tr>
                            )}
                          </tbody>
                        </table>
                      </div>
                    </div>
                  </div>

              }


            </div>
          }

        </div>
      </Card>
  )
}

const mapStateToProps = (state) => {
  const { user } = state.AuthReducer;
  const { roleSelected, privilegeSelected } = user;
  return {
      roleSelected,
      privilegeSelected
  }
}

export default connect(mapStateToProps)(SalesNegociateurs);
