import { Link } from 'react-router-dom';

import { ImLocation } from 'react-icons/im';
import { connect } from 'react-redux';
import { useGet, useFilter, useHydra } from '@/components/Utils';

import { Card, DataTable, LoadingAnimation } from '@/components/UI';

import './PerformanceBien.styles.scss';
import { publicAssets , toAbsoluteUrl } from '@/_metronic/helpers';
import { useEffect } from 'react';
import { BsSearch } from 'react-icons/bs';

const PerformanceBien = ({roleSelected, privilegeSelected}) => {
    let [params, query] = useFilter();
    const { data, loading, refetch } = useGet(`/bien_immobiliers${query}`, {
        headers: {
            'Accept': 'application/ld+json'
        }
    });

    const { rows, getPagination } = useHydra(data, params);

    useEffect(() => {
        refetch();
        return () => {}
    }, [roleSelected, privilegeSelected])

    const photoUrl = (photo) => {
        if (photo)
            return publicAssets(photo);
        else 
            return toAbsoluteUrl('/media/house.png');
    }

    const columns = [{
        name: 'photo',
        component: ({ photo, titreAnnonce }) => 
        <div className="bien-picture">
            <img src={photoUrl(photo)} alt={titreAnnonce}/>
        </div>
    },{
        label: 'Adresse',
        component: (bien) => (
            <div className="flex">
                <div className="border border-gray-300 border-dashed rounded w-50px h-50px flex justify-center items-center me-4 fs-1 text-primary">
                    <ImLocation />
                </div>
                <div>
                    <div>{ bien.adressePrimaire }</div>
                    <div>{ bien.adresseSecondaire }</div>
                    <div>{ bien.codePostal }</div>
                    <div>{ bien.ville }</div>
                </div>
            </div>
            )
    }, {
        label: 'Provenance du lead',
        isEmpty: `0.00`,
        component: ({ mandatType, commercial }) => {
            return (
            <>
                { commercial && commercial?.provenance ? (
                    <div>
                        <span className="badge-provenance">
                            { commercial?.provenance ? commercial?.provenance?.nom : null }
                        </span>
                        <div className="flex">
                            <div className="mandat-type">{ parseInt(mandatType) === 1 ? `Mandat simple` : `Mandat exclusif` }</div>
                        </div>
                    </div>
                ) : null }
            </>)
        }
    }, {
        label: 'Négociateur(s)',
        isEmpty: `0.00`,
        component: ({ negociateurs }) => {
            return (
            <>
                { negociateurs && negociateurs.map(negociateur => 
                    <div style={{display: 'flex', flexDirection: 'column', alignItems: 'flex-start'}}>
                        <span className='badge badge-light mt-2'>{ negociateur.nom + ` ` + negociateur.prenom }</span>
                    </div> 
                ) }
            </>)
        }
    }, {
        label: 'Action',
        component: ({ id }) => <Link to={`/biens/update/${id}`}><BsSearch /></Link>
    }]

    return (
        <div className="relative performance-bien">
            
                    <Card 
                        className="relative mb-4 agence-list"
                        titleColor={`#00000`}
                        title={'Nouveaux biens immobiliers'}
                        >

                        <DataTable
                            className="bien-datatable"
                            classique={true}
                            columns={columns}
                            rows={rows}/>

                        { loading ? (<LoadingAnimation className={`mb-4`}/>) : null }

                    </Card>

                    <div className="mt-4">
                        { getPagination() }
                    </div>
            
        </div>
    )
}

const mapStateToProps = (state) => {
    const { user } = state.AuthReducer;
    const { roleSelected, privilegeSelected } = user;
    return {
        roleSelected,
        privilegeSelected
    }
}

export default connect(mapStateToProps)(PerformanceBien)
