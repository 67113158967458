import React, { useState } from 'react'

const HeaderShorting = (props) => {

    const [isActive, setIsActive] = useState(false);
    const [isIndex, setIsIndex] = useState(false);

    const item = localStorage.getItem("params");

    const handleClick = (index, type, id) => {

        setIsActive(current => !current);
        setIsIndex(index);
        const direction = localStorage.getItem("direction") === "asc" ? "desc" : "asc";
        localStorage.setItem("direction", direction);

        props.onShortColumn({type, id, direction});            
    }

    return (
        <>
            <thead>
                <tr className="fw-bold fs-6 text-gray-800 border-bottom border-gray-200">
                    { props.columns.map(({ label, style, type, id }, index) => {
                        return (<th
                            key={index}
                            style={style}>

                            <div 
                                className={((isActive && isIndex === id) || (!isActive && isIndex === id) || item == id) ? 'fw-bolder text-primary fs-6' : ''}
                                onClick={(event) => handleClick(index, type, id)}
                                style={{cursor: "pointer"}}
                                >
                                { label }
                            </div>
                            
                        </th>)
                    })}
                </tr>
            </thead>  
        </>
    )
}

export default HeaderShorting
