import { MenuToolbar } from '@/routes';
import './Performance.styles.scss';
import PerformanceBien from './PerformanceBien/PerformanceBien';
import PerformanceNegociateur from './PerformanceNegociateur/PerformanceNegociateur';


const Performance = () => {

    localStorage.removeItem("params");

    return (
        <div className="agence-page">
            <PerformanceNegociateur />
        </div>
    )
}

export default Performance
