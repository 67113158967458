import { MenuToolbar } from '@/routes';
import { Link } from 'react-router-dom';

import { FaRegEdit } from 'react-icons/fa';
import { RiContactsBook2Line } from 'react-icons/ri';
import { ImLocation } from 'react-icons/im';
import { useGet, useFilter, useHydra } from '@/components/Utils';

import { Card, DataTable, Badge, SearchInput, LoadingAnimation } from '@/components/UI';

import './AgenceList.styles.scss';
import { MultipleInput } from '../../../../components/UI';

const AgenceList = () => {
    let [params, query] = useFilter();
    const { data, loading } = useGet(`/agences${query}`, {
        headers: {
            'Accept': 'application/ld+json'
        }
    });

    const { rows, total, getPagination } = useHydra(data, params);

    const columns = [{
        name: 'nom',
        label: 'Nom',
        component: ({ nom }) => (<span className="text-dark fw-bolder text-hover-primary fs-6">{ nom }</span>)
    }, {
        label: 'Adresse',
        component: ({ adresse }) => (
            <div className="flex">
                <div className="border border-gray-300 border-dashed rounded w-50px h-50px flex justify-center items-center me-4 fs-1 text-primary">
                    <ImLocation />
                </div>
                <div>
                    <div>{ adresse.adressePrimaire }</div>
                    <div>{ adresse.adresseSecondaire }</div>
                    <div>{ adresse.codePostal }</div>
                    <div>{ adresse.ville }</div>
                </div>
            </div>
            )
    }, {
        label: 'Contact',
        component: ({ contact }) => (
            <div className="flex">
                <div className="border border-gray-300 border-dashed rounded w-50px h-50px flex justify-center items-center me-4 fs-1 text-primary">
                    <RiContactsBook2Line />
                </div>
                <div>
                    <div>{ contact.contactPrimaire }</div>
                    <div>{ contact.contactSecondaire }</div>
                    <div>{ contact.email }</div>
                </div>
            </div>
            )
    }, {
        name: 'status',
        label: 'Etat',
        component: ({ status }) => status === 1 ? (<Badge color="success">Active</Badge>) : (<Badge color="danger">Inactive</Badge>)
    }, {
        label: 'Action',
        component: ({ id }) => <Link to={`/agences/update/${id}`}><FaRegEdit /></Link>
    }]

    return (
        <div className="performance-page">
            <MenuToolbar
                title={`Liste des agences`}
                action={() => <Link to="/agences/create" className="btn btn-primary btn-sm font-weight-bold mr-2">Créer une agence</Link>}>
                <ul className="breadcrumb breadcrumb-separatorless fw-bold fs-7 my-1">
                    <li className="breadcrumb-item text-muted">
                        <span className="text-muted text-hover-primary">{ total } trouvés</span>
                    </li>
                    <li className="breadcrumb-item">
                        <span className="bullet bg-gray-200 w-5px h-2px"></span>
                    </li>
                </ul>

                <SearchInput
                    label={`Nom`}
                    onChange={(value) => params.updates({
                        nom: value,
                        page: 1
                    })}/>
                
                <SearchInput
                    label={`Code Postal`}
                    className="ms-2"
                    withIcon={false}
                    onChange={(value) => params.updates({
                        "adresse.codePostal": value,
                        page: 1
                    })}/>

                <MultipleInput
                    options={[{
                        label: `Toutes`,
                        value: ''
                    }, {
                        label: `Que les actives`,
                        value: 1
                    }, {
                        label: `Que les inactives`,
                        value: -1
                    }]}
                    label={`Status`}
                    className="ms-2"
                    withIcon={false}
                    onChange={(value) => params.updates({
                        status: value,
                        page: 1
                    })}/>
            </MenuToolbar>
            
            <div className="relative">

                { (
                    <>
                        { loading ? (<LoadingAnimation className={`mb-4`}/>) : null}

                        <Card 
                            className="relative mb-4 performance-list"
                            titleColor={`#ff6348`}>
                            <DataTable
                                className="performance-datatable"
                                classique={true}
                                columns={columns}
                                rows={rows}/>
                        </Card>

                        { getPagination() }
                    </>
                ) }
            </div>
        </div>
    )
}

export default AgenceList
