const information = [{
    name: "nom",
    label: "Nom de l'agence *",
    params: { required: true } 
}]

const adresse = [{
    name: "adresse.adressePrimaire",
    label: "Adresse ligne 1 *",
    params: { required: true }

}, {
    name: "adresse.adresseSecondaire",
    label: "Adresse ligne 2"

}, {
    name: "adresse.codePostal",
    label: "Code Postal *",
    params: { required: true }
}, {
    name: "adresse.ville",
    label: "Ville *",
    params: { required: true }
}]

const contact = [{
    name: "contact.contactPrimaire",
    label: "Téléphone (standard) *",
    params: { required: true }
}, {
    name: "contact.contactSecondaire",
    label: "Téléphone secondaire"
}, {
    name: "contact.email",
    label: "Email de contact"
}]

export {
    information,
    adresse,
    contact
};