import React from 'react';

const useFilter = (defaultValues = null) => {
    const [query, setQuery] = React.useState(``)
    const [params, setParams] = React.useState(defaultValues ?? {});

    const superParams = {
        ...params,
        get: (field) => {
            return params[field]
        },
        set: (field, value) => {
            setParams({
                ...params,
                [field]: value
            });
        },
        remove: (key) => {
            if (params[key]) delete params[key];
            setParams({
                ...params
            });
        },
        updates: (values) => {
            setParams({
                ...params,
                ...values
            });
        }
    }

    const build = () => {
        const keys = Object.keys(params);
        const reducer = (previousValue, key, index) => {
            if (params[key]) previousValue = previousValue + `${key}=${params[key]}`;

            if (index < (keys.length - 1)) previousValue = previousValue + `&`;
            return previousValue;
        }
        let query = ``;
        if (keys.length > 0)
            query = keys.reduce(reducer, '?')

        setQuery(query);
    }

    React.useEffect(() => {
        build();

        return () => {}
        // eslint-disable-next-line
    }, [params])

    return [superParams, query];
}

export default useFilter
