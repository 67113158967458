
let bordereauValidate = null;

const initialState = {
    bordereauValidate,
}

const BordereauReducer = (state = initialState, action) => {
    const bordereauValidate = {
        ...state.bordereauValidate
    };

    return {
        ...state,
        bordereauValidate
    }
}

export default BordereauReducer;