import { MenuToolbar } from '@/routes';
import { Link } from 'react-router-dom';

import { useGet, useHydra, useFilter } from '@/components/Utils';
import { Card, DataTable, SearchInput,Swal, LoadingAnimation } from '@/components/UI';
import './SocieteList.styles.scss';

import { BsBuilding } from 'react-icons/bs';
import { FaRegEdit, FaRegTimesCircle  } from 'react-icons/fa';
import axios from '@/axios';

const SocieteList = () => {
    let [params, query] = useFilter();
    const { data, loading, refetch  } = useGet(`/societes${query}`, {
        headers: {
            'Accept': 'application/ld+json'
        }
    })

    const { rows, total, getPagination} = useHydra(data, params);

    const handleRemove = ( societe )=>{
        Swal.fire({
            title: `<span style="color: red">Supprimer la société ${societe?.nom}?</span>`,
            icon: 'warning',
            html: `Voulez-vous réellement supprimer la société ${societe?.nom}?`,
            showCloseButton: false,
            showCancelButton: true,
            focusConfirm: false,
            confirmButtonText: 'Oui',
            confirmButtonColor: 'red',
            cancelButtonText: 'Annuler'
        }).then(result => {
            if (result.isConfirmed) {
                axios.delete(`/societes/${societe.id}`, {
                    headers: {
                        "Accept": "application/json",
                        "Content-Type": "application/merge-patch+json"
                    }
                }).then(()=> refetch())
            }
        })
    }
    const columns = [{
        name: 'nom',
        label: 'Nom',
        component: ({ nom }) => <span className="text-dark fw-bolder text-hover-primary text-uppercase fs-6">{ nom }</span>
    }, {
        name: 'agences',
        label: 'Les agences',
        component: ({ agences }) => {
            return <div className="flex">
                <div className="text-warning border border-gray-300 border-dashed rounded w-50px h-50px flex justify-center items-center me-4 fs-1 text-primary">
                    <BsBuilding />
                </div>
                <div>
                    { agences.map((agence, key) => (<div key={key}>{ agence.nom }</div>)) }
                </div>
            </div>
        } 
    }, {
        name: 'franchise',
        label: 'Franchise',
        component: ({ franchise, percentageRedevance }) => <span className="text-dark fw-bolder text-hover-primary text-uppercase fs-6">
            { franchise ? percentageRedevance+`%` : '-' }
        </span>
    }, {
        label: 'Action',
        component: (societe) => 
        <>
            <Link to={`/societes/update/${societe.id}`}><FaRegEdit /></Link>
            {!societe.agences.length > 0 &&
            <FaRegTimesCircle
                className="ms-4 text-danger"
                style={{cursor: 'pointer'}}
                onClick={() => handleRemove(societe)}
            />}    
        </>
    }];

    return (
        <>
            <MenuToolbar
                title={`Liste des sociétés`}
                action={() => <Link to="/societes/create" className="btn btn-primary btn-sm font-weight-bold mr-2">Créer une société</Link>}>
                <ul className="breadcrumb breadcrumb-separatorless fw-bold fs-7 my-1">
                    <li className="breadcrumb-item text-muted">
                        <span className="text-muted text-hover-primary">{ total } trouvées</span>
                    </li>
                    <li className="breadcrumb-item">
                        <span className="bullet bg-gray-200 w-5px h-2px"></span>
                    </li>
                </ul>

                <SearchInput
                    label={`Nom`}
                    onChange={(value) => params.updates({
                        nom: value,
                        page: 1
                    })}/>
            </MenuToolbar>
            
            <div className="relative">
                { (
                    <>

                        {loading ? (<LoadingAnimation className={`mb-4`}/>) : null}

                        <Card className="relative societe-list mb-4">
                            <DataTable
                                className="societe-datatable"
                                classique={true}
                                columns={columns}
                                rows={rows}/>
                        </Card>
                    </>
                ) }
            </div>

            { getPagination() }
        </>
    )
}

export default SocieteList
