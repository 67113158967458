import AgenceDirecteur from './forms/AgenceDirecteur.form';
import AgenceNegociateur from './forms/AgenceNegociateur.form';

const AgencePrivileges = (props) => {
    
    const { tab, agenceDetail } = props;

    return (
        <>
            { tab === 'directeurs' && agenceDetail ? <AgenceDirecteur {...props}/> : null }
            { tab === 'negociateurs' && agenceDetail ? <AgenceNegociateur {...props}/> : null }
        </>
    )
}

export default AgencePrivileges
