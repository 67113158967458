import React from 'react';
import { information, adresse, contact } from './AgenceField';

import { Card, checkError } from '@/components/UI';
import { FaInfo, FaLocationArrow, FaPhoneAlt } from 'react-icons/fa';
import './AgenceForm.styles.scss';

const AgenceForm = ({ setValue, status, showUploader, register, loading, errors  }) => {

    const checkRef = React.useRef();
    
    const groups = [{
        title: 'Information',
        titleColor: '#706fd3',
        titleIcon: <FaInfo />,
        fields: information,
        position: `left`
    }, {
        title: 'Contact',
        titleColor: '#706fd3',
        titleIcon: <FaPhoneAlt />,
        fields: contact,
        position: `left`
    }, {
        title: 'Adresse',
        titleColor: '#706fd3',
        titleIcon: <FaLocationArrow />,
        fields: adresse,
        position: `right`
    }]

    const leftGroup = groups && groups.filter(group => group.position === `left`);
    const rightGroup = groups && groups.filter(group => group.position === `right`);

    React.useEffect(() => {
        const checkboxStatus = checkRef?.current ?? null;
        if (checkboxStatus) {
            checkboxStatus.addEventListener('change', function(event) {
                const checked = event.target.checked;
                setValue('status', checked ? 1 : -1, { shouldDirty: true })
            })
        }

        return () => {
            checkboxStatus && checkboxStatus.removeEventListener('change', null);
        }
    }, [setValue])

    return (
        <div className="agence-form">
            { !loading ? (
                <div className="row">
                    <div className="agence-info left-group">
                        { leftGroup && leftGroup.map(({ title, titleColor, titleIcon, fields }, key) => {
                            return (
                            <Card
                                key={key}
                                title={title}
                                titleColor={titleColor}
                                titleIcon={titleIcon}
                                className="mb-4">
                                
                                { key === 0 ? <div className="mb-4">{ showUploader() }</div> : null }
                                
                                { fields && fields.map((field, index) => {
                                    return (
                                    <div className="mb-8" key={index}>
                                        <div className="col-12 align-items-center input-group has-validation">
                                            <label className="form-label col-12 text-start pe-8">{ field.label }</label>
                                            <div className="col-12 form-inline">
                                                <input
                                                    placeholder={ field.placeholder ?? field.label }
                                                    className="form-control form-control-white"
                                                    type={field.type ?? 'text'}
                                                    {...register(field.name, field.params)}/>
                                            </div>
                                            <div className="col-12"></div>
                                            <div className="col-12">
                                                { checkError(field, errors) }
                                            </div>
                                        </div>
                                    </div>)
                                }) }

                                { key === leftGroup.length - 1 && (
                                <>
                                    <label className="form-check form-switch form-check-custom">
                                        <div>Etat ? : &nbsp;</div>
                                        <input
                                            className="form-check-input"
                                            type="checkbox"
                                            ref={checkRef}
                                            defaultChecked={status === 1}/>

                                        <span className="form-check-label fw-bold text-muted">{ parseInt(status) === -1 ? 'Inactive' : 'Active'}</span>
                                    </label>
                                </>)}
                            </Card>)
                        })}
                    </div>

                    <div className="agence-adresse left-group">
                        { rightGroup && rightGroup.map(({ title, titleColor, titleIcon, fields }, key) => {
                            return (
                            <Card
                                key={key}
                                title={title}
                                titleColor={titleColor}
                                titleIcon={titleIcon}
                                className="mb-4">
                                { fields && fields.map((field, index) => {
                                    return (
                                    <div className="mb-8" key={index}>
                                        <div className="col-12 align-items-center input-group has-validation">
                                            <label className="form-label col-12 text-start pe-8">{ field.label }</label>
                                            <div className="col-12 form-inline">
                                                <input
                                                    placeholder={ field.placeholder ?? field.label }
                                                    className="form-control form-control-white"
                                                    type={field.type ?? 'text'}
                                                    {...register(field.name, field.params)}/>
                                            </div>
                                            <div className="col-12"></div>
                                            <div className="col-12">
                                                { checkError(field, errors) }
                                            </div>
                                        </div>
                                    </div>)
                                }) }
                                </Card>)
                        })}
                    </div>
                </div>
            ) : null}
        </div>
    )
}

export default AgenceForm
