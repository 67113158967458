import React, { ChangeEvent } from "react";
import { connect } from "react-redux";
import { useLocation } from "react-router-dom";
import { roleGetAttempt, roleUpdateAttempt } from "../../../../redux/Role/role.action";

function MenuInner({ user, roles, roleGet, roleUpdate }: any) {
  const location = useLocation();
  // On mount dispatch and get all roles
  React.useEffect(() => {
    roleGet();
  }, [location.key, roleGet])

  const handleChangeRole = (event: ChangeEvent<HTMLSelectElement>) => {
    const privilegeId = event.target.value;

    roleUpdate(privilegeId);
  }

  if (!roles || roles.length <= 0) return null;

  return (
    <>
      <div className="py-2">
        <select
          className="form-select"
          aria-label="Default select example"
          onChange={handleChangeRole}
          defaultValue={user?.privilegeSelected}>
          { roles && roles.map((role:any, key:number)=>
            <option
            key={key}
              value={role.privilegeId}
              >
              {role.nom} {role.agenceName ?? role.societeName}
            </option>
          ) }
        </select>
      </div>
    </>
  )
}

const mapStateToProps = (state: any) => {
  const { roles } = state.RoleReducer;
  const { user } = state.AuthReducer;
  return {
    user,
    roles
  }
}

const mapDispatchToProps = (dispatch: any) => {
  return {
    roleGet: () => dispatch(roleGetAttempt()),
    roleUpdate: (privilegeId: any) => dispatch(roleUpdateAttempt(privilegeId))
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(MenuInner);