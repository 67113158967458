import { combineReducers } from 'redux';

import AuthReducer from './Auth/auth.reducer';
import RoleReducer from './Role/role.reducer';
import BordereauReducer from './Bordereau/bordereau.reducer';

export default combineReducers({
    AuthReducer,
    RoleReducer,
    BordereauReducer
});