import { Badge, Card, Currency, Swal } from '@/components/UI';
import './CommissionDetail.styles.scss';
import { publicAssets, toAbsoluteUrl } from '@/_metronic/helpers';
import { FaCheckCircle, FaTimes } from 'react-icons/fa';
import { useMutation } from '@/components/Utils';

const CommissionDetail = ({ setSelected, detail, refetch }) => {
    const bien = detail?.bien ?? {};
    const commercial = bien?.commercial ?? {};
    const user = detail?.user ?? {};

    const [soldCommission, { fusion }] = useMutation(`/commissions/sold`);

    const launchSoldCommission = () => {
        soldCommission({
            commissionId: detail.id
        }).then(response => {
            if (response.status === 201) {
                setSelected(null);
                refetch();
            }
        })
    }

    const soldCommissionConfirm = () => {
        return Swal.fire({
            title: `<span style="color: blue"> ${detail?.regler === true ? 'Dérégler' : 'Régler' } la commission ?</span>`,
            icon: 'question',
            html: `Voulez-vous vraiment marquer la commission comme ${detail?.regler === true ? 'non réglée' : 'réglée' }?`,
            showCloseButton: false,
            showCancelButton: true,
            focusConfirm: false,
            confirmButtonText: 'Oui',
            confirmButtonColor: 'green',
            cancelButtonText: 'Annuler'
        }).then(result => {
            if (result.isConfirmed) {
                launchSoldCommission();
            }
        })
    }
    
    const photoUrl = (photo) => {
        if (photo)
            return publicAssets(photo);
        else 
            return toAbsoluteUrl('/media/house.png');
    }

    return (
        <div className={`commission-detail ${detail !== null ? 'show' : 'hide'}`}>
            <div className="commission-overlay" onClick={() => setSelected(null)}></div>
            <div className="commission-body">
                <Card title={`Detail de la commission`}>
                    <div className="commission-header">
                        <div className="me-8">
                            Photo :
                            <div className="commission-bien">
                                <img src={photoUrl(bien?.photo)} alt={bien?.titreAnnonce}/>
                            </div>
                        </div>
                        <div className="ms-4 commission-info">
                            <div className="mb-2">Adresse : <br/>{ bien?.adressePrimaire }</div>
                            <div className="mb-2">Code Postal : <br/>{ bien?.codePostal }</div>
                            <div className="mb-2">Ville : <br/>{ bien?.ville }</div>
                            <div className="mb-2">Prix de vente FAI : <br/><Currency value={bien?.prixAnnonce}/></div>
                            <div className="mb-2">Prix effectif : <br/><Currency value={commercial?.prixEffectif}/></div>
                            <div className="mb-2">Montant commission : <br/><Currency value={commercial?.montantCommission}/></div>
                        </div>
                    </div>

                    <div className="mt-8 pt-4" style={{borderTop: '1px solid #e7e7e7'}}>
                        <div className="mb-2">
                            <div style={{fontSize: '18px'}}>Information sur le négociateur :</div>
                            <div>Nom du négociateur : { user.nom } { user.prenom }</div>
                            <div>Commission à payer : <Currency value={detail?.montant}/></div>
                            <div>Acompte : <Currency value={detail?.acompte}/></div>
                            <div>Etat : { detail?.regler === true ? <Badge color="success">Reglé</Badge> : <Badge color="danger">Non réglé</Badge> }</div>
                            <div></div>
                        </div>
                        <div className="flex">

                        <button
                            {...fusion()}
                            className="btn btn-success"
                            onClick={() => soldCommissionConfirm()}>
                            <FaCheckCircle /> { detail?.regler === true ? <> Marquer comme non réglé </> : <> Marquer comme réglé </> }
                        </button>
                            
                        <button
                            className="btn btn-warning ms-2"
                            onClick={() => setSelected(null)}>
                            <FaTimes/> Fermer
                        </button>

                        </div>
                    </div>
                </Card>
            </div>
        </div>
    )
}

export default CommissionDetail