// import React from 'react'
import './Dashboard.style.scss';
import SalesTurnover from './partials/SalesTurnover/SalesTurnover';
import SalesDetailed from './partials/SalesDetailed/SalesDetailed';
import SalesAgence from './partials/SalesAgence/SalesAgence';
import SalesNegociateurs from './partials/SalesNegociateurs/SalesNegociateurs';
import { connect } from 'react-redux';
import { AiFillBell } from 'react-icons/ai';
import { MenuToolbar } from '../../routes';
// import SalesSociety from './Administrateur/SalesSociety/SalesSociety';

const Dashboard = ({roleSelected, privilegeSelected}) => {
    return (
        <div className="dashboard-page">

            <MenuToolbar
                title={``}
                >
                <div className="alert alert-custom alert-white" style={{paddingBottom:'0px'}}>
                    <AiFillBell style={{fontSize: '30px'}} className="text-primary"></AiFillBell> Le tableau de bord prend en compte tous les biens immobiliers dont les bordereaux ont été validés ou non.
                </div>
            </MenuToolbar>

            <SalesTurnover />
            <SalesDetailed />
            <SalesAgence />
            <SalesNegociateurs />
       
        </div>
    )
}

const mapStateToProps = (state) => {
    const { user } = state.AuthReducer;
    const { roleSelected, privilegeSelected } = user;
    return {
        roleSelected,
        privilegeSelected
    }
  }
  
  export default connect(mapStateToProps)(Dashboard);
