import {
    useParams
} from 'react-router-dom';
import SocieteList from './partials/SocieteList/SocieteList.partial';
import SocieteForm from './partials/SocieteForm.partial';

const SocietePage = () => {
    const params = useParams();

    const handleSubpage = () => {
        switch(params.action) {
            case 'liste':
                return <SocieteList />;
            
            case 'create':
                return <SocieteForm {...params}/>

            case 'update':
                return <SocieteForm {...params}/>

            default:
            return null;
        }
    }

    return (
        <>
            { handleSubpage() }
        </>
    )
}

export default SocietePage
