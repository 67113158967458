const information = [{
    name: "nom",
    label: "Nom de la société *",
    params: { required: true } 
},
// {
//     name: "percentage_redevance",
//     label: "Franchise ",
//     type: "checkbox",
//     className: "form-check-input",
//     params: { required: true },
//     styles: {
//         margin: '50px'
//     } 
// }
]

export {
    information
};