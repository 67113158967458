const SelectItem = ({ row, className, onClick, config, multipleLabel }) => {
    return (
        <div
            onClick={() => onClick(row)}
            className={`list-group-item list-group-item-action ${className}`}>
            { multipleLabel(row, config, 'field') }
        </div>
    )
}

export default SelectItem
