import React from 'react';
import { information } from './ProvenanceField';
import { MenuToolbar } from '@/routes';
import { Link, useNavigate } from 'react-router-dom';
import { Card, Swal } from '@/components/UI';
import { checkError } from '@/components/UI/FormHandler/functions';
import { useForm } from 'react-hook-form';

import './ProvenanceForm.styles.scss';

// Icon imports
import { AiFillPlusSquare } from 'react-icons/ai';
import { VscSourceControl } from 'react-icons/vsc';

import { usePatch, useMutation } from '@/components/Utils';

const FraisTypeForm = ({ data, loading, fraisId = null, action }) => {
    const navigate = useNavigate();
    const isUpdate = () => action === "update" && data;

    const { getValues, setValue, register, watch, handleSubmit, reset, formState: { isDirty, errors } } = useForm();

    const [updateProvenance, { fusion: updateFusion }] = usePatch(`/provenances/${fraisId}`);
    const [saveProvenance, { fusion: saveFusion }] = useMutation(`/provenances`);

    const fusion = action === "update" ? updateFusion : saveFusion;

    const onSubmit = ({ ...rest }) => {
        const provenance = {
            ...rest
        }

        provenance.number = parseInt(provenance.number)

        if (action === 'update')
            return updateProvenance(provenance).then(() => {
                Swal.fire({
                    title: 'Provenance a été mis à jour avec succès',
                    icon: 'success',
                    showConfirmButton: false,
                    timer: 1500
                });
                navigate(`/provenances/liste`);
            });

        return saveProvenance(provenance).then(() => {
            Swal.fire({
                title: 'Provenance ajoutée avec succès',
                icon: 'success',
                showConfirmButton: false,
                timer: 1500
            });
            navigate(`/provenances/liste`);
        })
    }

    watch('etat');
    const etat = getValues('etat');

    React.useEffect(() => {
        if (data) reset(data);
        return () => {}
    }, [data, reset])

    return (
        <form
            className={`frais-typeform ${isUpdate() ? `frais-update` : `frais-create`}`}
            onSubmit={handleSubmit(onSubmit)}>
            <MenuToolbar
                title={isUpdate() ? `Edition d'une provenance` : `Création d'une provenance`}
                subtitle={ data ? data.nom : ``}
                action={() => 
                    <button
                        {...fusion({ disabled: !isDirty})}
                        type="submit" 
                        className="btn btn-success btn-sm font-weight-bold mr-2">
                        <AiFillPlusSquare /> Sauvegarder
                    </button>}>
                <ul className="breadcrumb breadcrumb-separatorless fw-bold fs-7 my-1">
                    <li className="breadcrumb-item text-muted">
                        <Link to="/provenances/liste" className="text-muted text-hover-primary">Liste des provenances</Link>
                    </li>
                    <li className="breadcrumb-item">
                        <span className="bullet bg-gray-200 w-5px h-2px"></span>
                    </li>
                </ul>
            </MenuToolbar>
            
            { !loading ? (
                <div className="row">
                    <div className="col-xl-8 col-sm-12 left-group"></div>
                    <div className="col-xl-4 col-sm-12 right-group">
                        <Card
                            title={`Détail de la provenance`}
                            titleColor={`#ff6b6b`}
                            titleIcon={<VscSourceControl />}
                            className="mb-4">
                            { information && information.map((field, index) => {
                                return (
                                <div className="mb-8" key={index}>
                                    <div className="col-12 align-items-center input-group has-validation">
                                        <label className="form-label col-12 text-start pe-8">{ field.label }</label>
                                        <div className="col-12 form-inline flex items-center">
                                            <input
                                                placeholder={ field.placeholder ?? field.label }
                                                className="form-control form-control-white"
                                                type={field.type ?? 'text'}
                                                {...register(field.name, field.params)}/>
                                            <div className="fs-4 ms-2">{ field.appendText }</div>
                                        </div>
                                        <div className="col-12"></div>
                                        <div className="col-12">
                                            { checkError(field, errors) }
                                        </div>
                                    </div>
                                </div>)
                            }) }
                            <label className="form-check form-switch form-check-custom">
                                <div>Etat ? : &nbsp;</div>
                                <input
                                    className="form-check-input"
                                    type="checkbox"
                                    // ref={checkboxRef}
                                    defaultChecked={etat === 1}
                                    onChange={function(event) {
                                        const checked = event.target.checked;
                                        setValue('etat', checked ? 1 : -1, { shouldDirty: true })
                                    }}/>

                                <span className="form-check-label fw-bold text-muted">{ parseInt(etat) === -1 ? 'Inactive' : 'Active'}</span>
                            </label>
                        </Card>
                    </div>
                </div>
            ) : null}
        </form>
    )
}

export default FraisTypeForm
