import React from 'react'

const Header = ({ columns }) => {
    return (
        <>
            <thead>
                <tr className="fw-bold fs-6 text-gray-800 border-bottom border-gray-200">
                    { columns.map(({ label, style }, index) => {
                        return (<th
                            key={index}
                            style={style}>
                            { label }
                        </th>)
                    })}
                </tr>
            </thead>  
        </>
    )
}

export default Header
