import { useGet } from '@/components/Utils';
import { MultipleInput } from '@/components/UI';

const FilterByUser = ({ onChange, dataUser, role }) => {

    const query = role === 4 ? '?roles=ROLE_NEGOCIATEUR' : '';
    
    let { data, loading } = useGet(`/users/list${query}`);

    const users = data ?? [];

    let usersFilter = [{
        label: role === 4 ? 'Toutes les négociateurs' : `Toutes les utilisateurs`,
        value: ''
    }];


    if (loading) return null;

    users.users.map(user => {
        return usersFilter = [...usersFilter, {
            label: user.nom + ' ' + user.prenom,
            value: user.id
        }]
    });
    
    return (
        <MultipleInput
            options={usersFilter}
            label={`Utilisateur`}
            defaultValue={dataUser}
            className="ms-2"
            withIcon={false}
            fixWidth={false}
            onChange={(value) => onChange(value)}
        />
    )
}

export default FilterByUser