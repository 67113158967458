import {
    useParams
} from 'react-router-dom';
import FraisTypeListe from './partials/FraisTypeListe/FraisTypeListe.partial';
import FraisTypeForm from './partials/FraisTypeForm/FraisTypeForm.partial';
// import AgenceCreate from './AgenceCreate.page';
import { useGet } from '@/components/Utils';

const FraisType = () => {
    const params = useParams();

    const fraisId = params?.fraisId ?? ``

    const { data, loading } = useGet(`/frais_types/${fraisId}`);

    const props = {
        ...params,
        data,
        loading
    }

    const handleSubpage = () => {
        switch(params.action) {
            case 'liste':
                return <FraisTypeListe />;
            
            case 'create':
                return <FraisTypeForm {...props} data={null}/>

            case 'update':
                return <FraisTypeForm {...props}/>

            default:
                return null;
        }
    }

    return (
        <>
            { handleSubpage() }
        </>
    )
}

export default FraisType