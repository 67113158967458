/* eslint-disable jsx-a11y/anchor-is-valid */
import { FC } from 'react'
import { publicAssets, toAbsoluteUrl } from '../../../helpers'
import { connect } from 'react-redux';
import { userLogoutAttempt } from '../../../../redux/Auth/auth.action';

const HeaderUser: FC = ({user, logoutAttempt }:any) => {

  const logout = () => {
    logoutAttempt();
  }

  const refresh = () => {
    logoutAttempt();
    window.location.reload();
  }

  const avatarURL = user?.avatar ? publicAssets(user.avatar) : toAbsoluteUrl('/media/empty-user.jpg');

  return (
    <div
      className='menu menu-sub menu-sub-dropdown menu-column menu-rounded menu-gray-600 menu-state-bg menu-state-primary fw-bold py-4 fs-6 w-275px'
      data-kt-menu='true'
    >
      <div className='menu-item px-3'>
        <div className='menu-content d-flex align-items-center px-3'>
          <div className='symbol symbol-50px me-5'>
            <img
              alt='User profile'
              src={avatarURL} />
          </div>

          <div className='d-flex flex-column'>
            <div className='fw-bolder d-flex align-items-center fs-5'>
              {user?.nom} {user?.prenom}
            </div>
            <a href='#' className='fw-bold text-muted text-hover-primary fs-7'>
              {user?.username}
            </a>
          </div>
        </div>
      </div>

      <div className='separator my-2'></div>

      {/* <div className='menu-item px-5'>
        <Link to={'/crafted/pages/profile'} className='menu-link px-5'>
          My Profile
        </Link>
      </div> */}

      {/* <div className='separator my-2'></div> */}

      {/* <Languages /> */}

      {/* <div className='menu-item px-5 my-1'>
        <Link to='/crafted/account/settings' className='menu-link px-5'>
          Paramètre du compte
        </Link>
      </div> */}

      <div className='menu-item px-5'>
        <a onClick={refresh} className='menu-link px-5'>
          Vider le cache
        </a>
        <a onClick={logout} className='menu-link px-5'>
          Se déconnecter
        </a>
      </div>
    </div>
  )
}

const mapStateToProps = (state: any) => {
  const { user, logoutAttempt } = state.AuthReducer;
  return {
      user,
      logoutAttempt
  }
}

const mapDispatchToProps = (dispatch: any) => {
  return {
    logoutAttempt: () => dispatch(userLogoutAttempt())
  }
}

const HeaderUserMenu = connect(mapStateToProps, mapDispatchToProps)(HeaderUser);

export {
  HeaderUserMenu
}
