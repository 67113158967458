import { useCallback, useEffect, useState } from 'react';
import { MenuToolbar } from '@/routes';
import { useGet, useHydra, useFilter } from '@/components/Utils';
import { Card, DataTable, MultipleInput, Badge, LoadingAnimation, Currency } from '@/components/UI';
import './CommissionListe.styles.scss';
import { connect } from 'react-redux';
import { publicAssets, toAbsoluteUrl } from '@/_metronic/helpers';
import { BsSearch } from 'react-icons/bs';
import CommissionDetail from '../CommissionDetail/CommissionDetail.partial';
import FilterByAgence from '../../../../components/Filter/FilterByAgence/FilterByAgence.component';
import FilterByUser from '../../../../components/Filter/FilterByUser/FilterByUser.component';
import { useSearchParams } from 'react-router-dom';
import { FaFileDownload } from 'react-icons/fa';

const CommissionListe = ({ roleSelected, privilegeSelected }) => {
    const [selected, setSelected] = useState(null);
    let [params, query] = useFilter();
    const [searchParams, setSearchParams] = useSearchParams();
    let userId = searchParams.get("user");
    const { data, loading , refetch} = useGet(`/bien_commissions${query}`, {
        headers: {
            'Accept': 'application/ld+json'
        }
    })

    useEffect(() => {
        refetch();
    
        return () => {}
    }, [roleSelected, privilegeSelected])

    const { rows, total, getPagination } = useHydra(data, params);

    const photoUrl = (photo) => {
        if (photo)
            return publicAssets(photo);
        else 
            return toAbsoluteUrl('/media/house.png');
    }

    const columns = [{
        name: 'photo',
        label: 'Photo',
        component: ({ bien }) => 
        <div className="bien-picture">
            <img src={photoUrl(bien?.photo)} alt={bien?.titreAnnonce}/>
        </div>
    }, {
        name: 'addresse',
        label: 'Adresse',
        component: ({ bien }) => 
            <div>
                <p>{bien.titreAnnonce}</p>
                <p>{bien.adressePrimaire}</p>
                <p>{bien.adresseSecondaire}</p>
                <p>{bien.codePostal} {bien.ville}</p>
            </div>
    }, {
        name: 'prix_vente',
        label: 'Prix vente FAI',
        component: ({ bien }) => <Currency value={bien.prixAnnonce}/>
    }, {
        name: 'prix_effectif',
        label: 'Prix effectif',
        component: ({ bien }) => <Currency value={bien.commercial.prixEffectif}/>
    }, {
        name: 'commission',
        label: 'Commission',
        component: ({ bien }) => <Currency value={bien.commercial.montantCommission}/>
    }, {
        name: 'commission_payer',
        label: 'Commission à payer',
        component: ({ montant }) => <Currency value={montant}/>
    }, {
        name: 'etat',
        label: 'Négociateur',
        component: ({ user, facture }) => 
        <div>
            <p className="badge badge-light">{user.nom} {user.prenom}</p>
            <div className="clearfix"></div>
            {
                facture ? (

                    <a href={publicAssets(facture.filePath)} target="_blank" rel="noreferrer">
                        <div className="dropzone-action ms-4" style={{color: 'blue', cursor: 'pointer', fontSize: '12px'}}><FaFileDownload/> Télécharger la Facture</div> 
                    </a>

                ) : null
            }
        </div>
    }, {
        name: 'etat',
        label: 'Etat',
        component: ({ regler }) => regler === true ? (<Badge color="primary">Réglé</Badge>) : (<Badge color="danger">Non réglé</Badge>)
    }, {
        label: 'Action',
        component: (commission) => <div style={{color: 'blue', cursor: 'pointer'}} onClick={() => setSelected(commission)}><BsSearch /></div>
    }];

    return (
        <>
            <MenuToolbar
                title={`Commissions validées`}>
                <ul className="breadcrumb breadcrumb-separatorless fw-bold fs-7 my-1">
                    <li className="breadcrumb-item text-muted">
                        <span className="text-muted text-hover-primary">{ total } trouvées</span>
                    </li>
                    <li className="breadcrumb-item">
                        <span className="bullet bg-gray-200 w-5px h-2px"></span>
                    </li>
                </ul>

                <MultipleInput
                    options={[{
                        label: `Toutes les commissions`,
                        value: ''
                    }, {
                        label: `Commissions non réglées`,
                        value: 0
                    }, {
                        label: `Commissions réglées`,
                        value: 1
                    }]}
                    label={`Status`}
                    className="ms-2"
                    withIcon={false}
                    onChange={(value) => params.updates({
                        regler: value,
                        page: 1
                    })}/>

                  
                <FilterByAgence
                    onChange={(value) => params.updates({
                        agence: value,
                        page: 1
                    })}/>
            
    
                <FilterByUser
                    onChange={(value) => params.updates({
                        user: value,
                        page: 1
                    })}
                    dataUser={userId}
                    role={4}
                    />

            </MenuToolbar>

            <div className="relative">
                { (
                    <>
                        { loading ? (<LoadingAnimation className={`mb-4`}/>) : null}

                        <Card className="relative commission-list mb-4">
                            <DataTable
                                className="commission-datatable"
                                classique={true}
                                columns={columns}
                                rows={rows}/>
                        </Card>

                        { getPagination() }
                    </>
                ) }
            </div>

            <CommissionDetail
                refetch={refetch}
                setSelected={setSelected}
                detail={selected}/>
        </>
    )
}
const mapStateToProps = (state) => {
    const { user } = state.AuthReducer;
    const { roleSelected, privilegeSelected } = user;
    return {
        roleSelected,
        privilegeSelected
    }
}

export default connect(mapStateToProps)(CommissionListe)

