import { useGet } from '@/components/Utils';
import { MultipleInput } from '@/components/UI';
import React from 'react';
import { connect } from 'react-redux';

const FilterByAgence = ({ onChange, roleSelected, privilegeSelected }) => {
    let { data, loading, refetch } = useGet(`/agences/all`, {
        headers: {
            'Accept': 'application/json'
        }
    });

    React.useEffect(() => {
        
        refetch();
    
        return () => {}
    }, [roleSelected, privilegeSelected])

    const agences = data ?? [];

    let agencesFilter = [{
        label: `Toutes les agences`,
        value: ''
    }];


    if (loading) return null;

    agences.map(agence => {
        return agencesFilter = [...agencesFilter, {
            label: agence.nom,
            value: agence.id
        }]
    })
    
    return (
        <MultipleInput
            options={agencesFilter}
            label={`Agence`}
            className="ms-2"
            withIcon={false}
            fixWidth={false}
            defaultValue={sessionStorage.getItem("agence")}
            onChange={(value) => onChange(value)}
        />
    )
}


const mapStateToProps = (state) => {
    const { user } = state.AuthReducer;
    const { roleSelected, privilegeSelected } = user;
    return {
        roleSelected,
        privilegeSelected
    }
}

export default connect(mapStateToProps)(FilterByAgence);