import React, { useCallback, useRef, useState } from 'react';
import { MenuToolbar } from '@/routes';
import { Link, useSearchParams } from 'react-router-dom';
import { useGet, useFilter, useHydra } from '@/components/Utils';
import { SearchInput, MultipleInput, LoadingAnimation, DataTable, Currency } from '@/components/UI';
import { connect } from 'react-redux';

// Icon
import { FaRegEdit } from 'react-icons/fa';
import { GiHouse } from 'react-icons/gi';

import { Card, Badge } from '@/components/UI';
import { publicAssets, toAbsoluteUrl } from '@/_metronic/helpers';

import './BienList.styles.scss';
import FilterByAgence from '../../../components/Filter/FilterByAgence/FilterByAgence.component';
import FilterByUser from '../../../components/Filter/FilterByUser/FilterByUser.component';
import FilterByState from '../../../components/Filter/FilterByState/FilterByState.component';
import { Accordion, ListGroup } from 'react-bootstrap-v5';
import { AiFillFunnelPlot } from 'react-icons/ai';

const BienList = ({ roleSelected, privilegeSelected }) => {
    let [params, query] = useFilter();
    const [searchParams, setSearchParams] = useSearchParams();
    let userId = searchParams.get("user");
    let requestValidation = searchParams.get("requestValidation");
    const dateDebut = useRef(null);
    const dateFin = useRef(null);
    const [request, setRequest] = useState(null);

    query = query === '' && userId ? '?user='+userId : query;
    
    query = requestValidation ? ((query === '' && requestValidation) ? '?requestValidation=true' : (!query.includes('requestValidation=true') ? query+'&requestValidation=true' : query)) : query;

    const bordereauValide = sessionStorage.getItem("bordereauValide");
    const bordereauToValidate = sessionStorage.getItem("bordereauToValidate");
    const isArchive = sessionStorage.getItem("isArchive");
    const isOrder = sessionStorage.getItem("isOrder");
    const state = sessionStorage.getItem("state");
    const getDateDebut = sessionStorage.getItem("dateDebut");
    const getDateFin = sessionStorage.getItem("dateFin");
    const getAgence = sessionStorage.getItem("agence");
    const getUser = sessionStorage.getItem("user");
    const codePostal = sessionStorage.getItem("codePostal");
    const adressePrimaire = sessionStorage.getItem("adressePrimaire");
    const ville = sessionStorage.getItem("ville");

    if (bordereauValide === "true") {
        query = query + "&status=true";
    }

    if (bordereauToValidate === "true") {
        query = query + "&requestValidation=true";
    }
    
    if (isArchive === "true") {
        query = query + "&archive=true";
    }
    
    if (state) {
        query = query + "&state="+state;
    }
    
    if (isOrder) {
        query = query + (isOrder === "asc" ? "&order=asc" : "&order=desc");
    }

    if (getDateDebut && getDateFin) {
        query = query + "&dateDebut="+getDateDebut+"&dateFin="+getDateFin;
    }

    if (getAgence) {
        query = query + "&agence="+getAgence;
    }

    if (getUser) {
        query = query + "&user="+getUser;
    }

    if (codePostal) {
        query = query + "&codePostal="+codePostal;
    }

    if (adressePrimaire) {
        query = query + "&adressePrimaire="+adressePrimaire;
    }

    if (ville) {
        query = query + "&ville="+ville;
    }

    const { data, loading, refetch } = useGet(`/bien_immobiliers${query}`, {
        headers: {
            'Accept': 'application/ld+json'
        }
    });

    let queryCount = query === '' ? '?pagination=false' : query+"&pagination=false";
    
    const { data: dataComplet } = useGet(`/bien_immobiliers${queryCount}`, {
        headers: {
            'Accept': 'application/ld+json'
        }
    });

    const dataParam = state ? state : params.state;

    React.useEffect(() => {
        
        refetch();

        params.updates({
            user: userId,
        });
    
        return () => {}
    }, [roleSelected, privilegeSelected, userId]);

    const handleUpdate = useCallback((value) => {

        params.updates({
            user: value,
        });

        sessionStorage.setItem("user", value);

        // eslint-disable-next-line react-hooks/exhaustive-deps
        userId = value;

    }, []) 
    

    const { rows, total, getPagination } = useHydra(data, params);

    const photoUrl = (photo) => {
        if (photo)
            return publicAssets(photo);
        else 
            return toAbsoluteUrl('/media/house.png');
    }

    const filterByDate = useCallback(
        () => {               
            params.updates({
                dateDebut: dateDebut.current.value,
                dateFin: dateFin.current.value,
            });

            sessionStorage.setItem("dateDebut", dateDebut.current.value);
            sessionStorage.setItem("dateFin", dateFin.current.value);
            
        },
        [params],
    );

    const convertToMontantTtc = (montantHt) => {
        const ht = parseFloat(montantHt ?? 0);
        return (Math.round((ht * 1.2) * 100) / 100).toFixed(2);
    }
    
    const convertToMontantHt = (montanttc) => {
        const ttc = parseFloat(montanttc ?? 0);
        return (Math.round((ttc / 1.2) * 100) / 100).toFixed(2);
    }

    const totalMontant = dataComplet ? dataComplet['hydra:member'].reduce((acc, value) => {
        const montant = (value.commercial !== undefined && value.commercial.montantCommission !== undefined) ? parseFloat(value.commercial.montantCommission) : 0
        return acc += montant;
    }, 0) : 0;

    const totalVente = dataComplet ? dataComplet['hydra:member'].reduce((acc, value) => {
        const montant = (value.commercial !== undefined && value.commercial.prixNet !== undefined) ? parseFloat(value.commercial.prixNet) : 0
        return acc += montant;
    }, 0) : 0;

    const columns = [{
        name: 'photoPath',
        label: 'Photo',
        component: ({ photo, titreAnnonce }) => 
            <div className="bien-picture">
                <img src={photoUrl(photo)} alt={titreAnnonce}/>
            </div>
    }, {
        name: 'adresse',
        label: 'Adresse',
        component: ({ titreAnnonce, adressePrimaire, adresseSecondaire, codePostal, ville }) => (
        <div className="bien-info">
            <div className="fw-bold">{ titreAnnonce }</div>
            <div>{ adressePrimaire }</div>
            <div>{ adresseSecondaire }</div>
            <div>{ codePostal } { ville }</div>
        </div>)
    }, {
        name: 'prixAnnonce',
        label: 'Prix de vente FAI',
        component: ({ prixAnnonce, etat }) =>  etat !== 6 ? <Currency value={prixAnnonce ? prixAnnonce : 0.00} /> : '-',
        styles: {
            whiteSpace: 'nowrap'
        }
    }, {
        label: 'Prix Net (sans FAI)',
        isEmpty: `0.00`,
        component: ({ commercial }) => <Currency value={(commercial && commercial?.prixNet) ? commercial.prixNet : 0.00} />,
        styles: {
            whiteSpace: 'nowrap'
        }
    }, {
        name: 'commission',
        label: 'Commission',
        isEmpty: `0.00`,
        component: ({ commercial }) => <Currency value={(commercial && commercial?.montantCommission) ? commercial.montantCommission : 0.00} />,
        styles: {
            whiteSpace: 'nowrap'
        }
    }, {
        label: 'Provenance du lead / Négociateur',
        isEmpty: `0.00`,
        component: ({ mandatType, commercial, negociateurs }) => {
            return (
            <>
                { commercial && commercial?.provenance ? (
                    <div>
                        <span className="badge-provenance fw-bold">{ commercial?.provenance ? commercial?.provenance?.nom : null }</span>
                        <div className="flex">
                            <div className="mandat-type fw-bold">{ parseInt(mandatType) === 1 ? `Mandat simple` : `Mandat exclusif` }</div>
                        </div>
                    </div>
                ) : null }

                <div style={{display: 'flex', flexDirection: 'column', alignItems: 'flex-start'}}>
                    { negociateurs && negociateurs.map(negociateur => <div className="badge badge-light mb-2">{ negociateur.nom + ` ` + negociateur.prenom }</div> ) }
                </div>
            </>)
        }
    }, {
        name: 'status',
        label: 'Etat',
        component: ({ etat }) => {
            switch (parseInt(etat)) {
                case 2:
                    return <Badge color="primary">Promesse signée</Badge>;

                case 3:
                    return <Badge color="success">Acte définitif signé</Badge>;

                case 1:
                    return <Badge color="success">En vente</Badge>;

                case 4:
                    return <Badge color="success">Sous offre</Badge>;

                case 5:
                    return <Badge color="success">Archivé</Badge>;

                case 6:
                    return <Badge color="success">En location</Badge>;

                case 7:
                    return <Badge color="success">Loué</Badge>;

                default: 
                    return `-`;
            }
        }
    }, {
        label: 'Action',
        component: ({ id }) => <Link to={`/biens/update/${id}`}><FaRegEdit /></Link>
    }];

    return (
        <div className="bien-list">
            <MenuToolbar
                title={`Biens immobiliers`}
                action={() => <Link to="/biens/create" className="btn btn-primary btn-sm font-weight-bold mr-2">Créer un bien immobilier</Link>}>
                <ul className="breadcrumb breadcrumb-separatorless fw-bold fs-7 my-1">
                    <li className="breadcrumb-item text-muted">
                        <span className="text-muted text-hover-primary">{ total } trouvés</span>
                    </li>
                    <li className="breadcrumb-item">
                        <span className="bullet bg-gray-200 w-5px h-2px"></span>
                    </li>
                </ul>
                <span className="h-20px border-gray-200 border-start mx-4"></span>
                <div>
                    <b>Total montant commission:</b> <Currency value={convertToMontantHt(totalMontant)} /> HT - <Currency value={totalMontant} /> TTC 
                </div>
                <span className="h-20px border-gray-200 border-start mx-4"></span>
                <div className='ml-2'>
                    <b>Total Prix net (sans FAI) signé:</b> <Currency value={totalVente} /> HT - <Currency value={convertToMontantTtc(totalVente)} /> TTC
                </div>

            </MenuToolbar>


            <div className="relative">
                { (
                    <>

                        <Card
                            title={`Filtre et Recherche`}>
                            
                                <ListGroup>
                                    <ListGroup.Item>
                                        <div className="row p-2">
                                            <div className="col-md-2">
                                                <SearchInput
                                                    fixWidth={false}
                                                    label={`Code postal`}
                                                    defaultValue={sessionStorage.getItem("codePostal")}
                                                    onChange={(value) => {
                                                        params.updates({
                                                            codePostal: value,
                                                            page: 1
                                                        })

                                                        sessionStorage.setItem("codePostal", value);
                                                    }}/>
                                            </div>

                                            <div className="col-md-2">
                                                <SearchInput
                                                    fixWidth={false}
                                                    label={`Adresse`}
                                                    className="ms-2"
                                                    defaultValue={sessionStorage.getItem("adressePrimaire")}
                                                    onChange={(value) => {
                                                        params.updates({
                                                            adressePrimaire: value,
                                                            page: 1
                                                        })

                                                        sessionStorage.setItem("adressePrimaire", value);
                                                    }}/>
                                            </div>

                                            <div className="col-md-2">
                                                <SearchInput
                                                    fixWidth={false}
                                                    label={`Ville`}
                                                    className="ms-2"
                                                    withIcon={false}
                                                    defaultValue={sessionStorage.getItem("ville")}
                                                    onChange={(value) => {
                                                        params.updates({
                                                            ville: value,
                                                            page: 1
                                                        })

                                                        sessionStorage.setItem("ville", value);
                                                    }}/>
                                            </div>

                                            {
                                                roleSelected !== 4 ?

                                                    <>

                                                        <div className="col-md-2">
                                                            <FilterByAgence
                                                                onChange={(value) => {
                                                                    params.updates({
                                                                        agence: value,
                                                                        page: 1
                                                                    });

                                                                    sessionStorage.setItem("agence", value);

                                                                }}/>
                                                        </div>
                                                        
                                                        <div className="col-md-2">
                                                            <FilterByUser
                                                                onChange={handleUpdate}
                                                                dataUser={userId || getUser}
                                                                />
                                                        </div>
                                                    </>
                                                
                                                :

                                                null
                                            }
                                            
                                        </div>
                                    </ListGroup.Item>
                                </ListGroup>

                                <ListGroup style={{marginTop:'25px'}}>
                                    <ListGroup.Item>

                                        <div className="row p-2">

                                            <div className="col-md-2">
                                                <FilterByState
                                                    isBien="true"
                                                    params={params}/>
                                            </div>

                                            <div className="col-md-3">
                                                <div className='d-flex align-items-center position-relative my-1 ms-2'>
                                                    <span style={{flex: '0 0 auto', marginRight: '5px'}}>
                                                        Etat modifié entre le
                                                    </span>
                                                    <input 
                                                        type="date"
                                                        className="form-control form-control-sm"
                                                        disabled={!dataParam || dataParam < 2}
                                                        defaultValue={getDateDebut}
                                                        ref={dateDebut}/>
                                                </div>
                                            </div>
                                            
                                            <div className="col-md-2">
                                                <div className='d-flex align-items-center position-relative my-1 ms-2'>
                                                    <span style={{flex: '0 0 auto', marginRight: '5px'}}>
                                                        et le
                                                    </span>
                                                    <input 
                                                        type="date"
                                                        className="form-control form-control-sm"
                                                        disabled={!dataParam || dataParam < 2}
                                                        defaultValue={getDateFin}
                                                        ref={dateFin}/>
                                                </div>
                                            </div>

                                            <div className="col-md-2">
                                                <div className='d-flex align-items-center position-relative my-1 ms-2'>
                                                    <button
                                                        className="btn btn-success btn-sm font-weight-bold mr-2"
                                                        disabled={!dataParam || dataParam < 2}
                                                        onClick = {() => filterByDate()}>
                                                            Valider
                                                    </button>
                                                </div>
                                            </div>
                                            
                                        </div>

                                        </ListGroup.Item>
                                </ListGroup>
                                

                                <ListGroup style={{marginTop:'25px'}}>
                                    <ListGroup.Item>
                                        <div className="row">
                                    
                                            <div className="col-md-6 col-lg-6 col-xl-5 col-xxl-3">
                                                <label className="form-check" style={{fontSize: '16px', paddingTop: '10px', width: '350px'}}>
                                                    <span 
                                                        className="form-check-label fw-bold">
                                                        Que les biens avec bordereaux validés
                                                    </span>
                                                    <input
                                                        className="form-check-input"
                                                        style={{marginTop: '1px'}}
                                                        defaultChecked={bordereauValide === "true" ? true : false}
                                                        onChange={function(event) {
                                                            const checked = event.target.checked;

                                                            sessionStorage.setItem("bordereauValide", checked ? true : false);

                                                            params.updates({
                                                                status: checked ? true : false,
                                                            });
                                                        }}
                                                        type="checkbox"
                                                        />

                                                </label>
                                            </div>
                                            
                                            {
                                                roleSelected !== 4 ? (
                                                    <>
                                                    {
                                                        requestValidation ? (
                                                            <div className="col-md-6 col-lg-12 col-xl-4  col-xxl-2">
                                                                <label className="form-check" style={{fontSize: '16px', paddingTop: '10px', width: '350px'}}>
                                                                    <span 
                                                                        className="form-check-label fw-bold">
                                                                        Bordereau à valider
                                                                    </span>
                                                                    <input
                                                                        key='requestValidation1'
                                                                        className="form-check-input"
                                                                        style={{marginTop: '1px'}}
                                                                        disabled={true}
                                                                        defaultChecked={true}
                                                                        type="checkbox"
                                                                        />

                                                                </label>
                                                            </div>
                                                        ) : (

                                                            <>
                                                                <div className="col-md-6 col-lg-12 col-xl-4  col-xxl-2">
                                                                    <label className="form-check" style={{fontSize: '16px', paddingTop: '10px', width: '350px'}}>
                                                                        <span 
                                                                            className="form-check-label fw-bold">
                                                                            Bordereau à valider
                                                                        </span>
                                                                        <input
                                                                            key='requestValidation2'
                                                                            className="form-check-input"
                                                                            style={{marginTop: '1px'}}
                                                                            defaultChecked={query.includes('requestValidation=true') || (bordereauToValidate === "true" ? true : false)}
                                                                            onChange={function(event) {
                                                                                const checked = event.target.checked;

                                                                                sessionStorage.setItem("bordereauToValidate", checked ? true : false);

                                                                                params.updates({
                                                                                    requestValidation: checked ? true : false,
                                                                                });
                                                                            }}
                                                                            type="checkbox"
                                                                            />

                                                                    </label>
                                                                </div>

                                                                <div className="col-md-6 col-lg-12 col-xl-3  col-xxl-3">
                                                                    <label className="form-check" style={{fontSize: '16px', paddingTop: '10px', width: '350px'}}>
                                                                        <span 
                                                                            className="form-check-label fw-bold">
                                                                            Afficher les biens archivés
                                                                        </span>
                                                                        <input
                                                                            className="form-check-input"
                                                                            style={{marginTop: '1px'}}
                                                                            defaultChecked={isArchive === "true" ? true : false}
                                                                            onChange={function(event) {
                                                                                const checked = event.target.checked;

                                                                                sessionStorage.setItem("isArchive", checked ? true : false);

                                                                                params.updates({
                                                                                    archive: checked ? true : false,
                                                                                });
                                                                            }}
                                                                            type="checkbox"
                                                                            />

                                                                    </label>
                                                                </div>
                                                            </>
                                                        )
                                                    }

                                                    </>

                                                ) : null
                                            }

                                            <div className="col-md-6 col-lg-6 col-xl-12 col-xxl-4" style={{display: 'flex'}}>
                                                <span 
                                                    className="form-check-label fw-bold"
                                                    style={{fontSize: '16px', flex: 'none', padding: '10px 10px 0 0'}}>
                                                Trier par date de création par ordre:
                                                </span>

                                                <select
                                                    className="form-select"
                                                    style={{maxWidth: '200px'}}
                                                    defaultValue={isOrder}
                                                    onChange={function(event) {
                                                        const value = event.target.value;

                                                        sessionStorage.setItem("isOrder", value);
                                                        
                                                        params.updates({
                                                            order: value,
                                                        });
                                                    }}
                                                    >
                                                    <option value="asc">Croissant</option>
                                                    <option value="desc">Décroissant</option>
                                                </select>
                                            </div>

                                        </div>
                                    </ListGroup.Item>
                                </ListGroup> 

                        </Card>
                        

                        <Card
                            className={'mt-10'}
                            title={`Liste des biens immobiliers`}
                            titleIcon={<GiHouse />}
                            titleColor={`#00b894`}>

                            { loading ? (<LoadingAnimation className={`mb-4`}/>) : null}

                            <DataTable
                                className="bien-datatable"
                                classique={true}
                                columns={columns}
                                rows={rows}/>
                        </Card>

                        <div className="mt-4">
                            { getPagination() }
                        </div>
                    </>
                ) }
            </div>
        </div>
    )
}

const mapStateToProps = (state) => {
    const { user } = state.AuthReducer;
    const { roleSelected, privilegeSelected } = user;
    return {
        roleSelected,
        privilegeSelected
    }
}

export default connect(mapStateToProps)(BienList)
