import dateFormat from "dateformat"

const DateFormat = (dateString, format = "dd/mm/yyyy") => {
    const [year, month, day] = dateString.split("-")
    const date = new Date(year, month - 1, day);
    return dateFormat(date, format)
}

export {
    DateFormat
}