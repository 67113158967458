import './LoadingAnimation.scss';

const LoadingAnimation = ({ className, fullHeight = false }) => {
    return (
        <div className={`loading-animation ${className} ${fullHeight ? 'full-height' : ``}`}>
            <div className="loading-content">
                Chargement en cours <i className="las la-circle-notch spin-animation"></i>
            </div>
        </div>
    )
}

export default LoadingAnimation
