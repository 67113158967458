import {
    useParams
} from 'react-router-dom';
import BienAdd from './BienAdd.page';
import BienList from './BienList/BienList.page';
import BienUpdate from './BienUpdate.page';

const BienPage = () => {
    const params = useParams();

    const handleSubpage = () => {
        switch(params.action) {
            case 'liste':
                return <BienList />

            case 'create':
                return <BienAdd {...params}/>

            case 'update':
                return <BienUpdate {...params}/>

            default:
                return null;
        }
    }

    return (
        <div>
            { handleSubpage() }
        </div>
    )
}

export default BienPage
