const Card = ({ className, children, titleIcon, title, titleColor, footer}) => {
    return (
        <div className={`card ${className}`}>
            { title ? (
                <div
                    className='card-header border-0 cursor-pointer'>
                    <div className='card-title m-0'>
                    <div className='fw-bolder m-0' style={{color: titleColor, display: 'flex', alignItems: 'center'}}>
                        { titleIcon ? (<div className="me-2">{ titleIcon }</div>) : null }
                        <div>{ title }</div>
                    </div>
                    </div>
                </div>
            ) : null }
            

            <div className='card-body border-top p-9'>
                { children }
            </div>

            { footer ? (
                <div className='card-footer d-flex justify-content-end py-6 px-9'>
                    { footer }
                </div>
            ) : null }
        </div>
    )
}

export default Card