import React from 'react';
import { useGet } from '@/components/Utils';
import { FaCircleNotch } from 'react-icons/fa';

const PaiementTypeSelect = React.forwardRef((props, ref) => {

    const { data, loading } = useGet(`/paiement_types`);

    const paiement_types = data ?? [];

    if (loading) return (<div className="form-select text-muted">
            <FaCircleNotch className="spin-quickly"/> Chargement en cours
        </div>);

    return (
        <select
            className="form-select"
            aria-label="Moyen de paiement *"
            {...props}
            ref={ref}>
            <option value="">---Choisir---</option>
            { paiement_types && paiement_types.map((paiement_type, key) => 
                <option 
                    key={key}
                    value={paiement_type.id}>
                    { paiement_type.label }
                </option>)
            }
        </select>
    )
})

export default PaiementTypeSelect
