export const toAbsoluteUrl = (pathname: string) => process.env.PUBLIC_URL + pathname

export const assets = (pathname: string) => {
    if (process.env.NODE_ENV === 'development')
        return process.env.REACT_APP_DEV_URL + '/' + pathname
    else if (process.env.NODE_ENV === 'production') 
        return process.env.REACT_APP_PROD_URL + '/' + pathname

    return process.env.REACT_APP_DEV_URL + '/' + pathname
}

export const publicAssets = (pathname: string) => {
    if (process.env.NODE_ENV === 'development')
        return process.env.REACT_APP_DEV_ASSET + '/' + pathname
    else if (process.env.NODE_ENV === 'production') 
        return process.env.REACT_APP_PROD_ASSET + '/' + pathname

    return process.env.REACT_APP_DEV_ASSET + '/' + pathname
}
