import {
    useParams
} from 'react-router-dom';
import UserAdd from './UserAdd.page';
import UserList from './partials/UserList/UserList.page';
import UserUpdate from './UserUpdate.page';

const UserPage = () => {
    const params = useParams();

    const handleSubpage = () => {
        switch(params.action) {
            case 'liste':
                return <UserList />

            case 'create':
                return <UserAdd {...params}/>

            case 'update':
                return <UserUpdate {...params}/>

            default:
                return null;
        }
    }

    return (
        <div>
            { handleSubpage() }
        </div>
    )
}

export default UserPage
