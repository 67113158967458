import axios from '@/axios';
import history from '@/history';
import { roleGetAttempt } from '@/redux/Role/role.action';

export const AUTH_LOGIN_REQUEST = 'AUTH_LOGIN_REQUEST';
export const AUTH_LOGIN_ERROR = 'AUTH_LOGIN_ERROR';
export const AUTH_LOGIN_SUCCESS = 'AUTH_LOGIN_SUCCESS';
export const AUTH_LOGOUT_SUCCESS = 'AUTH_LOGOUT_SUCCESS';


export const authLoginRequest = () => ({
    type: AUTH_LOGIN_REQUEST
});

export const authLoginError = (error) => ({
    type: AUTH_LOGIN_ERROR,
    error
});

export const authLoginSuccess = (token, user) => ({
    type: AUTH_LOGIN_SUCCESS,
    token,
    user
});

export const authLogoutSuccess = () => ({
    type: AUTH_LOGOUT_SUCCESS
});

export const userLoginAttempt = (username, password) => {
    return (dispatch) => {
        dispatch(authLoginRequest());
        return axios.post('/auth/login', {
            username: username,
            password: password
        }).then(response => {
            if(response?.data?.error){
                dispatch((authLoginError(response?.data.error)))
            }else{
                if (response) {
                    const token = response.data.token;
                    const user = response.data.user ? response.data.user : null;

                    if (token) {
                        dispatch((authLoginSuccess(token, user)))
                        dispatch(roleGetAttempt());
                        const redirect_if = ['/login'];
                        if (redirect_if.includes(history.location.pathname)) {
                            history.push('/');
                        }
                    }
                } else {
                    const message = 'Utilisateur ou mot de passe incorrect';
    
                    dispatch((authLoginError(message)))
                }
            }

            
        }).catch(error => {
            let status = null;

            if (error.response) {
                if (error.response.status) {
                    status = error.response.status;
                }
            }
            
            let message = '';

            if (401 === status) {
                message = 'Utilisateur ou mot de passe incorrect';
            } else if (500 === status) {
                message = 'Une erreur est survenue. Réessayer à nouveau !';
            }
            
            dispatch((authLoginError(message)))
        })
    }
}

export const userSetToken = (token, user) => {
    
    return (dispatch) => {
        return dispatch((authLoginSuccess(token, user)))
    }
}

export const userLogoutAttempt = () => {
    return (dispatch) => {
        const promise = (dispatch) => new Promise((resolve, reject) => {
            window.localStorage.removeItem('jwtToken');
            window.localStorage.removeItem('user');
            dispatch(authLogoutSuccess());
    
            return resolve(true) 
        })
        
        return promise(dispatch);
    }
};

export const AUTH_REFRESH_USER = 'AUTH_REFRESH_USER';

export const authRefreshUser = (user) => {
    return {
        type: AUTH_REFRESH_USER,
        user
    }
}

export const AUTH_UPDATE_ROLE = 'AUTH_UPDATE_ROLE';

export const authUpdateRole = (roleId, privilegeId) => {
    return {
        type: AUTH_UPDATE_ROLE,
        roleId,
        privilegeId
    }
}