import { MultipleInput } from '@/components/UI';
import React from 'react';

const FilterByState = ({ isCommission, params, isBien }) => {

    const states = [{
        label: `Tous les états`,
        value: ''
    }, {
        label: `En vente`,
        value: 1
    }, {
        label: `Sous offre`,
        value: 4
    }, {
        label: `Sous promesse de vente`,
        value: 2
    }, {
        label: `Acte définitif signé`,
        value: 3
    }, {
        label: `Acte définitif signé (sans paiement du Notaire)`,
        value: 8
    }, {
        label: `Acte définitif signé (avec paiement du Notaire)`,
        value: 9
    }, {
        label: `En location`,
        value: 6
    }, {
        label: `Loué`,
        value: 7
    }, {
        label: `Archivé`,
        value: 5
    }];

    if (isCommission) {
        states.splice(1, 1);
        states.splice(4, 2);
    }

    return (
        <MultipleInput
            options={states}
            label={`Status`}
            withIcon={false}
            fixWidth={false}
            defaultValue={sessionStorage.getItem("state")}
            onChange={(value) => {
                        
                let appendFilters = {};

                switch (parseInt(value)) {
                    case 8:
                        appendFilters = {
                            etat: 3,
                            'commercial.paiementNotaire': -1
                        };
                        break;

                    case 9:
                        appendFilters = {
                            etat: 3,
                            'commercial.paiementNotaire': 1
                        };
                        break;

                    default:
                        appendFilters = {};
                        params.remove('commercial.paiementNotaire')
                        break;
                }

                params.updates({
                    state: value,
                    page: 1,
                    ...appendFilters
                });

                sessionStorage.setItem("state", value);
            }}/>
    )
}

export default FilterByState;