import React from 'react';
import ReactDropzone from 'react-dropzone';
import { useMutation } from '@/components/Utils';
import { Swal } from '@/components/UI';

// Icon
import { FaFileDownload, FaSpinner, FaTrashAlt } from 'react-icons/fa';
import { ImFileWord, ImFilePdf, ImFileEmpty } from 'react-icons/im';
import { publicAssets } from '@/_metronic/helpers';
import './Dropzone.styles.scss';


const Dropzone = ({
        labelItem = `le mandat scanné`,
        onUploaded, 
        folder = `/images/bien_immobiliers/mandats`,
        defaultValue,
        className,
        accpect
    }) => {
    const [loading, setLoading] = React.useState(false);
    const [value, setValue] = React.useState(defaultValue ?? null);

    const [saveUpload, error] = useMutation(`/upload/save`, {
        'Content-Type': 'multipart/form-data'
    });

    React.useEffect(()=>{
        if(error.error){
            setLoading(false);
        }
    },[error])

    const handleDelete = () => {
        Swal.fire({
            title: `<span style="color: grey">Supprimer le fichier?</span>`,
            icon: 'warning',
            html: `Etes vous sur de vouloir supprimer le fichier ?`,
            showCloseButton: false,
            showCancelButton: true,
            focusConfirm: false,
            confirmButtonText: 'Oui',
            confirmButtonColor: 'red',
            cancelButtonText: 'Annuler'
        }).then(result => {
            if (result.isConfirmed) {
                setValue(null);
                if (typeof onUploaded === 'function') onUploaded({});
            }
        })
    }

    const handleUpload = React.useCallback(file => {
        setLoading(true);
        const formData = new FormData();

        formData.append("file", file);
        formData.append("folder", folder);

        saveUpload(formData).then(response => {
            if (response.status === 201) {
                const uploadInfo = response.data;
                if (typeof onUploaded === 'function') {
                    onUploaded(uploadInfo);
                }

                setValue({
                    ...value,
                    ...uploadInfo
                })

                setLoading(false);
            }
        })
    }, [folder, onUploaded, saveUpload, value])

    const handleChange = (event) => {        
        const file = event.target.files[0];
        if (file) handleUpload(file);
    }

    const onDrop = React.useCallback(acceptedFiles => {
        // Do something with the files
        const file = acceptedFiles[0];
        if (file) handleUpload(file);
    }, [handleUpload])

    const handlePreview = (file) => {
        let fileIcon = null;
        switch (file.extension) {
            case 'docx':
                fileIcon = <ImFileWord />
                break;

            case 'pdf':
                fileIcon = <ImFilePdf />
                break;
            
            default:
                fileIcon = <ImFileEmpty />
                break;
        }

        return (
            <div className={`dropzone fs-4 text-primary flex items-center justify-center ${className}`} style={{width: '100%'}}>
                <div style={{
                    fontSize: '16px',
                    whiteSpace: 'nowrap',
                    maxWidth: '80%',
                    textOverflow: 'ellipsis',
                    overflow: 'hidden',
                }}>
                    { fileIcon } &nbsp; 
                    { file?.fileName }
                </div>
                <a href={publicAssets(value.filePath)} target="_blank" rel="noreferrer">
                    <div className="dropzone-action ms-4" style={{color: 'blue', cursor: 'pointer', fontSize: '12px'}}><FaFileDownload/> Télécharger</div> 
                </a>
                <div className="dropzone-action ms-4" style={{color: 'red', cursor: 'pointer', fontSize: '12px'}} onClick={handleDelete}>
                    Supprimer <FaTrashAlt />
                </div>
            </div>);
    }

    return (
        <ReactDropzone onDrop={onDrop}>
            {({ getRootProps, isDragActive }) => (
                <>
                    <div className={`notice d-flex bg-light-primary rounded border-primary border border-dashed p-6 ${className}`} {...getRootProps()}>
                        { !loading && !value && 
                        (<label className="dropzone fs-4 text-muted text-center" style={{width: '100%'}}>
                            { isDragActive ? 
                                (<span style={{color: 'purple'}}>Relachez maintenant votre souris pour envoyer { labelItem }</span>) : 
                                (<span>Glissez ou cliquez ici pour envoyer { labelItem }</span>) 
                            }
                            <input
                                type="file"
                                style={{display: 'none'}}
                                name="test"
                                accept={accpect}
                                onChange={handleChange}/>
                        </label>) }
                        
                        { loading && !error.error && <div className="dropzone fs-4 text-primary flex items-center justify-center" style={{width: '100%'}}><FaSpinner className="spin-animation me-2"/> Upload en cours</div>}
                        { value && handlePreview(value) }
                    </div>
                    {error.error && <div className="fs-4 flex items-center justify-center text-red" style={{width: '100%'}}>La taille du fichier doit être inférieur à 3Mo.</div>}
                </>
                
            )}
            
        </ReactDropzone>
    )
}

export default Dropzone
