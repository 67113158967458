import { MenuToolbar } from '@/routes';
import { Link } from 'react-router-dom';
import UserBox from '../UserBox/UserBox';
import { useGet, useFilter } from '@/components/Utils';
import { SearchInput, MultipleInput, LoadingAnimation } from '@/components/UI';
import './UserList.styles.scss';
import { connect } from 'react-redux';
import React from 'react';
import FilterByAgence from '../../../../components/Filter/FilterByAgence/FilterByAgence.component';

const UserList = ({roleSelected, privilegeSelected}) => {
    let [params, query] = useFilter();
    
    const { data, loading, refetch } = useGet(`/users/list${query}`, {
        headers: {
            'Accept': 'application/json'
        }
    });

    const roles = [{
            label: `Tous les rôles`,
            value: ``
        },{
            label: `Que les administrateurs`,
            value: `ROLE_ADMIN`
        }, {
            label: `Que les directeurs de société`,
            value: `ROLE_DIRECTEUR_SOCIETE`
        }, {
            label: `Que les directeurs d'agences`,
            value: `ROLE_DIRECTEUR_AGENCE`
        }, {
            label: `Que les négociateurs`,
            value: `ROLE_NEGOCIATEUR`
        }];

        switch (roleSelected) {
            case 2:
                delete roles[1];
                delete roles[2];
                break;

            case 3:
                delete roles[1];
                delete roles[2];
                delete roles[3];
                break;

            case 4:
                delete roles[1];
                delete roles[2];
                delete roles[3];
                delete roles[4];
                break;
        
            default:
                break;
        }

    React.useEffect(() => {
        
        refetch();
    
        return () => {}
    }, [roleSelected, privilegeSelected])

    const rows = data?.users ?? [];

    return (
        <div className="user-list">
            <MenuToolbar
                title={`Liste des utilisateurs`}
                action={() => <Link to="/users/create" className="btn btn-primary btn-sm font-weight-bold mr-2">Créer un utilisateur</Link>}>
                
                
                <ul className="breadcrumb breadcrumb-separatorless fw-bold fs-7 my-1">
                    <li className="breadcrumb-item text-muted">
                        <span className="text-muted text-hover-primary">{ rows.length } trouvés</span>
                    </li>
                    <li className="breadcrumb-item">
                        <span className="bullet bg-gray-200 w-5px h-2px"></span>
                    </li>
                </ul>
                
                <div className="toolbar-search">
                    <SearchInput
                        label={`Nom`}
                        onChange={(value) => params.updates({
                            nom: value,
                            page: 1
                        })}/>
                    
                    <SearchInput
                        label={`Prénom`}
                        className="ms-2"
                        withIcon={false}
                        onChange={(value) => params.updates({
                            prenom: value,
                            page: 1
                        })}/>

                    <MultipleInput
                        options={roles}
                        label={`Rôle`}
                        className="ms-2"
                        withIcon={false}
                        onChange={(value) => params.updates({
                            roles: value,
                            page: 1
                        })}/>

                    <MultipleInput
                        options={[{
                            label: `Tout`,
                            value: ''
                        }, {
                            label: `Que les actifs`,
                            value: 1
                        }, {
                            label: `Que les inactifs`,
                            value: -1
                        }]}
                        label={`Status`}
                        className="ms-2"
                        withIcon={false}
                        onChange={(value) => params.updates({
                            status: value,
                            page: 1
                        })}/>

                    <FilterByAgence
                        onChange={(value) => params.updates({
                            agence: value,
                            page: 1
                        })}/>
                </div>
            </MenuToolbar>


            <div className="relative">
                { loading ? (<LoadingAnimation className={`mb-4`}/>) : null }
                
                {
                    rows && 

                    (
                        rows.length > 0 ? (rows.map((user, key) => {
                        return <UserBox
                                    className="mb-4"
                                    key={key}
                                    user={user} />
                        })) : (!loading && <h2 className="text-center text-warning">Aucun utilisateur trouvé par rapport à votre rôle et filtres</h2>)
                    )
                    
                }
            </div>
        </div>
    )
}

const mapStateToProps = (state) => {
    const { user } = state.AuthReducer;
    const { roleSelected, privilegeSelected } = user;
    return {
        roleSelected,
        privilegeSelected
    }
}

export default connect(mapStateToProps)(UserList);
