import React from 'react';
import './App.scss';
import 'sweetalert2/src/sweetalert2.scss'

import {
  useLocation
} from "react-router-dom";
import { connect } from 'react-redux';

// Authentication page
import Auth from './pages/Auth/Auth.page';

// Metronic initialization
import { MenuComponent } from '@/_metronic/assets/ts/components'
import { LayoutProvider } from './_metronic/layout/core';
import { MasterLayout } from './_metronic/layout/MasterLayout';
import { MasterInit } from '@/_metronic/layout/MasterInit'

import { PrivateRoutes } from './routes';


function App({ isAuthenticated }) {
  const location = useLocation();

  React.useEffect(() => {
    setTimeout(() => {
      MenuComponent.reinitialization()
    }, 500)
  }, [])

  React.useEffect(() => {
    setTimeout(() => {
      MenuComponent.reinitialization()
    }, 500)
  }, [location.key])

  return (
    <>
      <LayoutProvider>
        <MasterLayout>
          { isAuthenticated ? <PrivateRoutes /> : <Auth /> }
        </MasterLayout>
      </LayoutProvider>

      <MasterInit />
    </>
  );
}

const mapStateToProps = (state) => {
  return {
      ...state.AuthReducer
  }
}

export default connect(mapStateToProps)(App);
