import BienForm from './partials/BienForm/BienForm.partial';
import { useGet } from '@/components/Utils';
import { LoadingAnimation } from '@/components/UI';

const BienUpdate = ({ bienId, action, tab }) => {
    const { data, loading, refetch, error } = useGet(`/bien_immobiliers/${bienId}`);

    if (loading) return <LoadingAnimation />

    if (error === "") {
        const params = {
            bienDetail: data ?? {},
            action,
            tab,
            refetch
        }
    
        return <BienForm loading={loading} {...params}/>
    } else {
        return (<h2 className="text-center text-warning">Vous ne pouvez pas accéder à ce bien</h2>)
    }


};

export default BienUpdate
