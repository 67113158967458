import React from 'react';
import { useGet } from '@/components/Utils';
import { FaCircleNotch } from 'react-icons/fa';

const FraisCategorieSelect = React.forwardRef((props, ref) => {

    const { data, loading } = useGet(`/frais_categories`);

    const frais_categories = data ?? [];

    if (loading) return (<div className="form-select text-muted">
            <FaCircleNotch className="spin-quickly"/> Chargement en cours
        </div>);

    return (
        <select
            className="form-select"
            aria-label="Pris en charge financier du frais *"
            {...props}
            ref={ref}>
            <option value="">---Choisir---</option>
            { frais_categories && frais_categories.map((frais_categorie, key) => 
                <option 
                    key={key}
                    value={frais_categorie.id}>
                    { frais_categorie.name }
                </option>)
            }
        </select>
    )
})

export default FraisCategorieSelect
