// Fonction qui vérifie si le champ en question est un champ imbriqué (Ex: adresse.ville)
const handleSubfield = (errors, field) => {
    let fieldName = "";
    if (typeof field === "string") {
        fieldName = field;
    } else {
        fieldName = field?.name;
    }

    if (Object.keys(errors).length > 0) {
        const fieldSplitted = String(fieldName).split(".");

        if (fieldSplitted.length === 1) return errors[fieldSplitted[0]];

        if (errors) {
            const reducer = (previousValue, currentValue) => previousValue && previousValue.hasOwnProperty(currentValue) ? previousValue[currentValue] : null;

            const value = fieldSplitted.reduce(reducer, errors);

            return value;
        }
        
        return null;
    }
}

// Renvoie l'erreur pour chaque type d'erreur : required / etc.
const errorMessage = (errors, field, message = '') => {
    const error = handleSubfield(errors, field);

    if (!error)
        return null;

    if (message)
        return <div className="field-error">{ message }</div>

    switch (error.type) {
        case 'required':
            return (<div className="field-error">Ce champ est obligatoire</div>);

        default:
            return (<></>)
    }
}

// 
const checkError = (field, errors, message = null) => {
    if (typeof field === 'string') {
        return errorMessage(errors, field, message);
    }

    if (field.hasOwnProperty('name') && field.hasOwnProperty('params')) {
        if (field.params.hasOwnProperty('required') && field.params?.required === true) {
            return errorMessage(errors, field, message);
        }
    }
}

export default checkError;
