import { Card } from '@/components/UI';
import { AiOutlinePhone, AiOutlineMail } from 'react-icons/ai';
import { BsPiggyBank }  from 'react-icons/bs';
import { RiShieldUserLine }  from 'react-icons/ri';
import { Link } from 'react-router-dom';
import { publicAssets, toAbsoluteUrl } from '@/_metronic/helpers';
import './UserBox.styles.scss';
import { useGet, useHydra, useFilter } from '@/components/Utils';
import { Currency } from '../../../../components/UI';

const UserBox = ({ className, user }) => {
    let [params] = useFilter();
    const { data } = useGet(`/user/biens?userId=`+ user.id);

    const avatarURL = user?.avatar ? publicAssets(user.avatar) : toAbsoluteUrl('/media/empty-user.jpg');

    return (
        <Card className={`user-box ${className}`}>
            <div className="user-information">
                <div className="user-profil" style={{backgroundImage: `url('${avatarURL}')`}}></div>
                
                <div className="user-detail">
                    <div className="user-name">{ user.nom } { user.prenom }</div>
                    <div className="user-contact">
                        <div><AiOutlineMail/> { user.email }</div> 
                        <div><AiOutlinePhone /> { user.telephone }</div>
                        <div><RiShieldUserLine /> { user.roleText }</div> 
                    </div>
                </div>

                <div className="user-action">
                    <Link to={`/users/update/${user.id}`}>
                        <button className="btn btn-light">Editer</button>
                    </Link>
                </div>
            </div>

            
            <div className="user-stats">
                <div className="stat-wrapper">
                    <BsPiggyBank />
                    <div>
                        <div className="stat-title ca-annuel">CA Annuel</div>
                        <div className="stat-value">
                            <Currency value={ user.chiffreAffaire ?? 0 }/> HT
                        </div>
                    </div>
                </div>

                <div className="stat-wrapper">
                    <div  className='bloc-info'>
                        <div className="stat-title">Commission</div>
                        <div className="stat-value"><Currency value={ user.totalCommission ?? 0 }/> HT</div>
                    </div>
                </div>

                <div className="stat-wrapper">
                    <div className='bloc-info'>
                        <div className="stat-title">Nombre de vente</div>
                        <div className="stat-value">{ user.nombreVente ?? 0 }</div>
                    </div>
                </div>

                <div className="stat-wrapper">
                    <div className='bloc-info'>
                        <div className="stat-title">Nombre de bien</div>
                        <div className="stat-value">
                        <Link
                            to={`/biens/liste?user=${user.id}`}
                            className={`nav-link text-active-primary pb-4`}>{data}</Link>
                        </div>
                    </div>
                </div>
            </div>
        </Card>
    )
}

export default UserBox
