import React, { useCallback, useMemo, useRef } from 'react';
import { MenuToolbar } from '@/routes';
import { publicAssets } from '@/_metronic/helpers';
import { useGet, useFilter, DateFormat } from '@/components/Utils';
import { Card, DataTable, MultipleInput, Currency, Swal, LoadingAnimation } from '@/components/UI';
import { ListGroup, Modal } from 'react-bootstrap-v5';
import {  FaRegEdit, FaRegTimesCircle  } from 'react-icons/fa';
import FraisAdd from '../../../Bien/partials/BienForm/BienFrais/FraisAdd.form';
import axios from '@/axios';
import { connect } from 'react-redux';
import { filters, reformuleData, getFilterSociete, getFilterAgence, getFilterTypeFrais } from '../../utils/utils';
import './FraisListe.styles.scss';



const FraisListe = ({ roleSelected, privilegeSelected }) => {

    const [show, toggleShow] = React.useState(false);
    const [bienFrais, setBienFrais] = React.useState(null);

    let [params, query] = useFilter();

    const { data: listTypeFrais } = useGet(`/frais_types`, {
        headers: {
            'Accept': 'application/json'
        }
    });

    const { data: listSociete } = useGet(`/societes`, {
        headers: {
            'Accept': 'application/json'
        }
    });

    const { data: listAgence } = useGet(`/agences`, {
        headers: {
            'Accept': 'application/json'
        }
    });
    
    const { data: fraisList, loading, refetch } = useGet(`/frais/list${query}`, {
        headers: {
            'Accept': 'application/ld+json'
        }
    });
    
    const rows = reformuleData(fraisList?.frais) ?? [];

    const total = useMemo(() => fraisList?.total, [fraisList]);

    const dateDebut = useRef(null);
    const dateFin = useRef(null);

    const filterByDate = useCallback(
        () => {
            if (dateDebut.current.value !== "" && dateFin.current.value !== "") {                
                params.updates({
                    dateDebut: dateDebut.current.value,
                    dateFin: dateFin.current.value,
                });
            }
        },
        [params],
    );

    React.useEffect(() => {
        refetch();
    
        return () => {}
    }, [roleSelected, privilegeSelected])

    const columns = [{
        name: 'socityAgence',
        label: 'Société / Agence',
        component: (data) =>  data.societeNom+' / '+data.agenceNom
    },
    {
        name: 'icone',
        label: 'Type',
        component: ({ frais }) => 
        <div className="frais-column">
            <div>
                <img src={publicAssets(frais.icone)} alt={frais.icone} width={30} height={30}/>
            </div>
        </div>
    }, {
        name: 'reglerVendeur',
        label: 'Etat',
        component: ({ reglerVendeur }) => reglerVendeur === 1 ? 'A la charge du propriétaire' : 'A la charge de Moriss'
    }, {
        name: 'montantHt',
        label: 'Montant € HT',
        component: ({ montantHt }) => <Currency value={montantHt}/>
    }, {
        name: 'dateReglement',
        label: 'Date règlement',
        component: ({ dateReglement }) => DateFormat(dateReglement)
    },
    {
        name: 'facture',
        label: 'Facture',
        component: ({ facture }) => facture && facture?.filePath ? <a href={publicAssets(facture?.filePath)} target="_blank" rel="noreferrer">Télécharger</a> : `N/A`
    }, 
    {
        label: 'Action',
        component: (rows) => {
            return <div style={{fontSize: '18px'}}>
                <FaRegEdit
                    className="ms-4 text-primary"
                    style={{cursor: 'pointer'}}
                    onClick={() => handleSelect(rows)}/>
                
                <FaRegTimesCircle
                    className="ms-4 text-danger"
                    style={{cursor: 'pointer'}}
                    onClick={() => handleRemove(rows)}/>
            </div>
        }
    }];

    
    const handleSelect = (value) => {
        setBienFrais(value);
        toggleShow(true);
    }

    const handleRemove = ({ id, frais }) => {
        Swal.fire({
            title: `<span style="color: red">Supprimer le frais de ${frais?.nom}?</span>`,
            icon: 'warning',
            html: `Voulez-vous réellement supprimer le frais de ${frais?.nom}?`,
            showCloseButton: false,
            showCancelButton: true,
            focusConfirm: false,
            confirmButtonText: 'Oui',
            confirmButtonColor: 'red',
            cancelButtonText: 'Annuler'
        }).then(result => {
            if (result.isConfirmed) {
                axios.patch(`/bien_frais/${id}`, {
                    isDeleted: 1
                }, {
                    headers: {
                        "Accept": "application/json",
                        "Content-Type": "application/merge-patch+json"
                    }
                }).then(() => refetch())
            }
        })
    }

    return (
        <>
            <div className="bien-frais">
                <Modal
                    show={show}
                    centered
                    onHide={() => {
                        toggleShow(false);
                        setBienFrais(null);
                    }}
                    dialogClassName="mw-900px">
                    <Modal.Header>
                        <Modal.Title id="contained-modal-title-vcenter">
                            <span className={bienFrais ? `text-warning` : `text-primary`}>{ bienFrais ? `Edition du frais de ${bienFrais?.frais?.nom}` : `Ajout d'un frais au bien immobilier` }</span>
                        </Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <FraisAdd
                            bienFrais={bienFrais}
                            withBien={false}
                            onSuccess={() => {
                                toggleShow(false);
                                refetch();
                            }}
                            bienId= {bienFrais?.bien.id}/>
                    </Modal.Body>
                </Modal>
            </div>
            <MenuToolbar
                title={`Frais`}>
                <ul className="breadcrumb breadcrumb-separatorless fw-bold fs-7 my-1">
                    <li className="breadcrumb-item text-muted">
                        <span className="text-muted text-hover-primary">{ rows?.length } trouvées</span>
                    </li>
                    <li className="breadcrumb-item">
                        <span className="bullet bg-gray-200 w-5px h-2px"></span>
                    </li>
                </ul>

                {filters &&  filters.map((filter)=>
                    <MultipleInput
                        options={filter.data}
                        label={filter.name}
                        className="ms-2"
                        withIcon={false}
                        onChange={(value) =>params.updates({
                            [filter.name]: value,
                            page: 1
                    })}/>
                )}

                <MultipleInput
                    options={getFilterSociete(listSociete)}
                    label={`Société`}
                    className="ms-2"
                    withIcon={false}
                    onChange={(value) => params.updates({
                        societe: value,
                        page: 1
                    })}
                />

                <MultipleInput
                    options={getFilterAgence(listAgence)}
                    label={`Agence`}
                    className="ms-2"
                    withIcon={false}
                    onChange={(value) => params.updates({
                        agence: value,
                        page: 1
                    })}
                />

                <MultipleInput
                    options={getFilterTypeFrais(listTypeFrais)}
                    label={`Type de frais`}
                    className="ms-2"
                    withIcon={false}
                    onChange={(value) => params.updates({
                        frais: value,
                        page: 1
                    })}
                />

            </MenuToolbar>

            <div className="relative">
                <Card title={`Filtre et Recherche`}>

                    <ListGroup>
                        <ListGroup.Item>

                            <div className="previous-search d-flex align-items-center position-relative my-1 me-auto p-2 bd-highlight"
                                style={{paddingTop:'3px', paddingBottom: '3px'}}
                                >
                                <div className="search-date">
                                    <div className="search-text" style={{'width': '200%'}}>Date de création des biens du</div> 
                                    <input 
                                        type="date"
                                        className="form-control form-control-sm"
                                        ref={dateDebut}
                                    />
                                
                                    <div className="search-text" style={{marginLeft: '5px'}}>au</div> 
                                    <input
                                        type="date"
                                        className="form-control form-control-sm"
                                        ref={dateFin}
                                    />
                                </div>
                                <div className="previous-button">
                                    <button
                                        className="btn btn-success btn-sm font-weight-bold mr-2"
                                        onClick = {() => filterByDate()}
                                    >
                                        Valider
                                    </button>
                                </div>
                            </div>

                        </ListGroup.Item>
                    </ListGroup>

                    <br></br>

                    <ListGroup>
                        <ListGroup.Item>
                        <div>
                            <b>Total des frais:</b> <Currency value={total} /> HT 
                        </div>
                        </ListGroup.Item>
                    </ListGroup>

                </Card>

                { loading ? <LoadingAnimation /> : (
                    <>
                        <Card className="relative societe-list mb-4">
                            <DataTable
                                className="societe-datatable"
                                classique={true}
                                columns={columns}
                                rows={rows}/>
                        </Card>

                        {/* { getPagination() } */}
                    </>
                ) }
            </div>
        </>
    )
}

const mapStateToProps = (state) => {
    const { user } = state.AuthReducer;
    const { roleSelected, privilegeSelected } = user;
    return {
        roleSelected,
        privilegeSelected
    }
}

export default connect(mapStateToProps)(FraisListe)

